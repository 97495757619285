
import Header from "../../component/header/Header";
import ProjectRoutes from "../../route-lists/ProjectRoutes";
import React from "react";
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import routes from "../../route-lists/notes";
import NotesSidebar from "../../component/sidebar/NotesSidebar";
import {Routes} from "react-router";


let NotesLayout=(props)=>{
    const {...rest} = props;
    const History = useHistory();
    const currentUser = useSelector(state => state.currentUser);

    const sidebarToggle = useSelector((state => state.changeTheSidebar));

    const getActiveRoute = (routes) => {
        let activeRoute = "Zebra Pro";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].items);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (routes[i].category) {
                let categoryActiveRoute = getActiveRoute(routes[i].items);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };

    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbar(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].items);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].secondaryNavbar;
                }
            }
        }
        return activeNavbar;
    };

    const getRoutes = (routes) => {
        let layouts = [,];
        return routes.map((prop, key) => {
            if (prop.layout === "/notes") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            }

            if (prop.collapse) {
                return getRoutes(prop.items);
            }
            if (prop.category) {
                return getRoutes(prop.items);
            } else {
                return null;
            }
        });
    };


    return(
        <>

            <div className="d-flex vh-100">
                <NotesSidebar/>
                <div style={{
                    width: sidebarToggle ? "calc(100% - 230px)" : "calc(100% - 85px)",
                    transition: 'width 0.2s ease 0s'
                }}>

                    <div className='w-100' style={{overflow: "hidden", height: "calc(100vh -100px)"}}>
                        {/*    <Header/>*/}
                        <Switch>
                            {getRoutes(routes)}
                            <Redirect from="/projects/notes" to={"/projects/notes"}/>
                        </Switch>
                    </div>
                </div>

            </div>
    </>
    )
}
export default NotesLayout;