import React, {useEffect, useRef, useState} from "react";
import {deleteNotebookPage, getNotebookNotes, storeNotesContent, updateNotesProps} from "../../actions/Notebooks";
import notebookAction from "../../Redux/Action/notebook";
import {NavLink} from "react-router-dom";
import {toast} from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import {MdOutlineDelete} from "react-icons/md";
import {GrClose} from "react-icons/gr";

const NotebookPageMap = (props) => {
    const dispatch = useDispatch();
    const notebook = useSelector(state => state.notebook);
    const notebookId = props.data;
    const currentCompany = props.currentCompany;
    const refreshNotebook = props.refreshNotebook;
    const [pageList, setPageList] = useState([]);
    const [isActive, setIsActive] = useState();
    const [editValue, setEditValue] = useState();
    const [deleteValue, setDeleteValue] = useState({});
    const [editId, setEditId] = useState();
    const [toggle, setToggle] = useState(false);
    const token = localStorage.getItem('token');
    const alert = toast;
    const pageName = useRef();
    const inputPageEdit = useRef();

    const fetchAllNotebooksNote = async (notebookId) => {
        let response = await getNotebookNotes(notebookId, token);

        if (response.data.data && response.data.data.length > 0) {
            let notesData = response.data.data.map((report, index) => {
                report.key = index + 1;
                report.action = null;
                return report;
            });

            setPageList(notesData);
        } else {
            setPageList([]);
            // alert.error("No data found!");
        }
    };

    const handleCreateNotebookPage = async () => {
        let body = {};

        if (pageName.current.value && pageName.current.value != "") {
            let response = await storeNotesContent(notebookId, {name: pageName.current.value}, token);
            if (response.data.success) {
                alert.success(response.data.message);
                pageName.current.value = '';
                setToggle(!toggle);
                document.getElementById('closeNotebookModal').click();
            } else {
                alert.error(response.data.errors);
            }
        } else {
            alert.error("Please Enter Name");
        }

    };

    const changePageName = async () => {
        let response = await updateNotesProps(editId, {name: editValue}, token);
        if (response.data.success) {
            alert.success(response.data.message);
            document.getElementById('closeEditPageModal').click();
            setToggle(!toggle);
        } else {
            alert.error(response.data.message);
        }
    }

    const handleOnDeletePage = async () => {
        let response = await deleteNotebookPage(deleteValue.value, token);
        if (response.data.success) {
            alert.success(response.data.message);
            document.getElementById('closeDeleteNotePage').click();
            setToggle(!toggle);
        } else {
            alert.error(response.data.message);
        }
    }

    useEffect(() => {
        fetchAllNotebooksNote(notebookId);
    }, [toggle])

    return (
        <>
        <div className='px-2'>
            <input className='border-0 rounded p-1 w-100' ref={pageName}
                   style={{fontSize: '14px', backgroundColor: '#f5f5f5'}} type='text'
                   placeholder='+ Create New Page'
                   onChange={(e) => {

                   }}
                   onKeyDown={async (e) => {
                       if (e.key === 'Enter') {
                           await handleCreateNotebookPage();
                       }
                   }}/>

        </div>
        {pageList && pageList != null ?
            pageList.map((item, i) => {
                return (
                    <li key={i}
                        className={` ${isActive == item.id && item.id === notebook.data.page ? 'text-primary' : 'text-dark'} d-flex px-2 py-1 cursor-pointer justify-content-between`}
                        style={{
                            listStyle: 'none',
                            fontSize: '14px',
                            backgroundColor: `${isActive == item.id && item.id === notebook.data.page ? '#ffedd2' : ''}`
                        }}
                        onClick={() => {
                            setIsActive(item.id);
                            dispatch(notebookAction({book: notebookId, page: item.id}))
                        }}>
                        <span>
                        <i className={`ri-pages-line`}></i>
                        <text style={{textOverflow: 'ellipsis'}}>&nbsp;{item.name}</text>
                        </span>
                        {isActive == item.id && item.id === notebook.data.page ?
                            <span>
                            <i className="ri-pencil-line ps-2 align-text-top"
                               data-bs-toggle='modal' data-bs-target='#editPageName'
                               onClick={() => {
                                   setEditId(item.id)
                                   setEditValue(item.name);
                               }}></i>

                            <span className='ps-2 '
                                  data-bs-toggle='modal' data-bs-target='#deleteNotePage'
                                  onClick={() => {
                                      setDeleteValue({value: item.id, name: item.name})
                                  }}><MdOutlineDelete/></span>
                        </span> : ''}


                    </li>
                )
            }) : ''}

            {/*Edit Checklist Name Modal*/}
            <div className="modal fade" id="editPageName" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <text className='fw-semibold'>Edit Check-list Name</text>
                                <div className='outline none' data-bs-dismiss="modal" type='button'
                                     id='closeEditPageModal'
                                     onClick={() => {
                                         setEditValue(null);
                                         setEditId(null);
                                     }}><GrClose/></div>
                            </div>

                            <div className='p-2'>
                                <input type='text' className=' w-100 outline-none rounded-2'
                                       value={editValue}
                                       ref={inputPageEdit}
                                       onChange={(e) => {
                                           setEditValue(e.target.value);
                                       }}
                                       style={{
                                           border: '0.5px solid #ebebeb',
                                           backgroundColor: '#fafafa',
                                           height: "3rem"
                                       }}></input>
                            </div>
                            <div className='w-100 d-flex justify-content-center p-2'>

                                <button className='btn buttonOrangeFill'
                                        style={{width: '8.5rem'}}
                                        onClick={() => {
                                            changePageName();
                                        }}> Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/*Delete Single Quetion Modal */}
            <div className="modal" id='deleteNotePage' tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Page ?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close" id='closeDeleteNotePage'
                                    onClick={() => {
                                        setDeleteValue({value: null, name: null});
                                    }}></button>
                        </div>
                        <div className="modal-body d-flex">
                            <img className='m-2' style={{width: "4rem"}}
                                 src='./assets/icons/84228-delete-animation.gif'/>
                            <div className='d-flex align-items-center flex-column w-100 '>
                                <p className='justify-content-center d-flex fw-semibold mb-0'
                                >Are you sure ?&nbsp;</p>

                                <b>{deleteValue.name}</b>
                                <span className='fw-normal'>Page will be deleted permanently.</span>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                    onClick={() => {
                                        setDeleteValue({value: null, name: null});
                                    }}>Close
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={async () => {
                                        await handleOnDeletePage();
                                    }}
                            >Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default NotebookPageMap;