const copyText = (text) => {
    return {
        type: "COPY_TEXT",
        payload: text
    }
};

const copyImage = (image) => {
    return {
        type: "COPY_IMAGE",
        payload: image
    }
};

const tabChange = (tab) => {
    return {
        type: "TAB_CHANGE",
        payload: tab
    }
};

const headerChange =(projectId)=>{
    return{
        type:"HEADER_CHANGE",
        payload:projectId
    }
};

export default {
    tabChange,
    copyImage,
    copyText,
    headerChange
}