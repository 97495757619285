import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
// import Editor from '@ckeditor/';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

// import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import './ckstyle.css'
import {useSelector} from "react-redux";
import {getNotesContentsPdf, getNotesHtmlContent, storeNotesHTMLContent} from "../../../actions/Notes/Notes";
import {deleteCheckList} from "../../../actions/CheckList";
import {Config} from "../../../config/Config";
import {getCurrentCompany} from "../../../utills/Common";
import {FaFilePdf, FaSave} from "react-icons/fa";


const CkEditorNotes = forwardRef((props, ref) => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const [ckEditor, setCkEditor] = useState();
    const [content, setContent] = useState();
    const documentId = props.documentId;
    const currentUser = useSelector(state => state.currentUser);
    const currentNotes = useSelector(state => state.notes);
    const [isSavingPdf, toggleSavingPdfStatusState] = useState(false);
    const [isSavingContent, toggleSavingContentState] = useState(false);
    const [currentProject, setCurrentProjectDetails] = React.useState({});

    useEffect( () => {
         getCurrentProjectDetails();
        if (ckEditor) {
             getContent(ckEditor);
        }
    }, [documentId]);
 // useEffect(async () => {
 //        await getCurrentProjectDetails();
 //        if (ckEditor) {
 //            await getContent(ckEditor);
 //        }
 //    }, [documentId]);

    useImperativeHandle(ref, () => ({
        copiedText(text) {
            console.log("ckedit copy text",text)
            // if (currentNotes.tab === 'notes') {
                if (ckEditor) {
                    ckEditor.model.change(writer => {
                        const docFrag = ckEditor.model.change(writer => {
                            const br1 = writer.createElement('paragraph');
                            const p1 = writer.createElement('paragraph');
                            const docFrag = writer.createDocumentFragment();
                            writer.append(br1, docFrag);
                            writer.append(p1, docFrag);
                            writer.insertText(text, p1);

                            return docFrag;
                        });

                        ckEditor.model.insertContent(docFrag);

                    });

                // }
            }
        },

    }));

    const getCurrentProjectDetails = async () => {
        let companyDetails = await getCurrentCompany();
        setCurrentProjectDetails(companyDetails);

    };

    const saveContent = async () => {
        try {
            let response = await storeNotesHTMLContent(documentId, {
                    html: encodeURI(content)
            }, token, {'Content-Type': 'application/x-www-form-urlencoded'});
            if (response.success) {
                console.log(response.data)
            } else {
                console.log(response)
            }

        } catch (e) {
            console.log(e);
        }
    };
    const delay = ms => new Promise(res => setTimeout(res, ms));

    const getContent = async (editor) => {
        toggleSavingContentState(true);
        try {
            if (editor) {
                let response = await getNotesHtmlContent(documentId, token);
                console.clear();
                // console.log('clear', response.data.success);
                console.log('ckd', editor);
                if (response.data.success && editor) {
                    console.log('html', response.data.data.html);
                    // setContent(response.data.data.html);
                    editor.data.set(response.data.data.html);
                } else {
                    console.log(response)
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            toggleSavingContentState(false);
        }
    };

    const handleSavePdf = async () => {
        toggleSavingPdfStatusState(true);
        try {
            await getNotesContentsPdf(documentId, token).then((response) => {
                if (response) {
                    let url = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
                    let link = document.createElement('a');

                    link.href = url;
                    link.setAttribute('download', (new Date()).getTime() + ".pdf");

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                } else {
                    alert.error("Something went wrong with file download")
                }
            })
        } catch (e) {
            console.log(e);
            alert.error("Some error occurred")
        } finally {
            delay(2000);
            toggleSavingPdfStatusState(false);
        }
    };

    function uploadAdapter(loader) {
        return {
            upload: async () => {
                let projectDetails = await getCurrentCompany();
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file) => {
                        body.append("image", file);
                        // let headers = new Headers();
                        // headers.append("Origin", "http://localhost:3000");
                        fetch(Config.API_URL + 'images/ck-upload/' + projectDetails.id, {
                            method: "post",
                            body: body,
                            headers: {
                                Authorization: "Bearer " + token
                            }
                            // mode: "no-cors"
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                resolve({
                                    default: res.url
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            }
        };
    }

    const getProjectId = () => currentProject.id;

    function uploadPlugin(editor) {
        if (currentProject) {
            editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
                return uploadAdapter(loader, currentProject.id);
            };
        }
    }

    return (
        <div>
            <div className='text-end'>
                <button className='btn btn-outline-primary p-1 m-1'><FaSave/>&nbsp;Save</button>
                <button className='btn btn-primary p-1 m-1' isLoading={isSavingPdf} onClick={handleSavePdf}
                ><FaFilePdf/>&nbsp;Download
                </button>
            </div>

            <CKEditor
                p={0}
                editor={Editor}
                config={{
                    extraPlugins: [uploadPlugin],
                }}

                data="<p></p>"
                autosave={(editor) => {
                    console.log("autosave", editor.getData())
                }}
                onReady={editor => {

                    setCkEditor(editor);

                    console.log('cked', ckEditor);
                    getContent(editor);

                    // You can store the "editor" and use when it is needed.
                    // console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                    // const data = editor.getData();
                    // console.log({event, editor, data});
                    setContent(editor.getData());
                }}
                onBlur={async (event, editor) => {
                    // console.log('Blur.', editor);
                    if (!isSavingContent) {
                        delay(1000);
                        console.log('cked', ckEditor);
                        await saveContent();
                    }
                }}
                onFocus={(event, editor) => {
                    // console.log('Focus.', editor);
                }}
            />
        </div>
    );

})

export default CkEditorNotes;