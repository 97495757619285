import React, {useEffect, useState} from "react";
import "./signin.css";
import {useHistory} from "react-router-dom";

import {isEmptyObject} from "../../../utills/Common";
import {Config} from "../../../config/Config";
import {useDispatch, useSelector} from "react-redux";
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import {loginUser, verifyUserToken} from "../../../actions/Auth/index";
import {setUserSession} from "../../../utills/Common";

import {useFormik} from 'formik';
import * as Yup from 'yup';
import currentUserAction from "../../../Redux/Action/currentUser";
import {toast} from "react-hot-toast";



let SignIn = () => {

    // const currentUser = useSelector(state => state.currentUser);
    const alert = toast;
    const authenticationMiddleware = () => {
        if (isEmptyObject(localStorage.getItem('token')) === false) {
            History.push(Config.DASHBOARD_ROUTE);

        }
    };

    const [firebaseApp, setFirebaseApp] = useState();
    const [firebaseAuth, setFirebaseAuth] = useState();


    const dispatch = useDispatch();
    const History = useHistory();
    //const alert = useAlert();

    useEffect(() => {
        authenticationMiddleware();
        const app = initializeApp(Config.firebase_config);
        setFirebaseApp(app);
        const auth = getAuth();
        setFirebaseAuth(auth);
        const analytics = getAnalytics(app);
    }, []);


    let formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required('Email address is required'),
            password: Yup.string().required('Password is required'),
        }),
        onSubmit: async (values) => {
            signInWithEmailAndPassword(firebaseAuth, values.username, values.password).then((userCredentials) => {
                // get firebase user
                const user = userCredentials.user;
                // set password as UID
                values.password = user.uid;
                // Login with server
                loginUser(values).then((response) => {
                    if (response.data && response.data.access_token) {
                        console.log(response.data.access_token)
                        verifyUserToken(response.data.access_token).then((userDetailsResponse) => {
                            if (userDetailsResponse.data && userDetailsResponse.data.success) {

                                let userDetails = {
                                    user: userDetailsResponse.data.data,
                                    token: response.data.access_token
                                };

                                // Store in session
                                setUserSession(response.data.access_token, userDetailsResponse.data.data);


                                // Store in redux
                                dispatch(currentUserAction.setUser(userDetails));

                                History.push(Config.DASHBOARD_ROUTE);
                                // alert.success("Access Granted");
                            } else {
                                alert.error("Something went wrong with token verification");
                            }
                        }).catch((err) => {
                            alert.error("Something went wrong with token verification");
                        });


                    } else {
                        alert.error("Please enter valid username and password");
                    }
                });


            }).catch((err) => {
                alert.error("Please enter valid username and password");
            })

        },
    });

  let btnLoader=()=>{
      return(
          <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
          </div>
      );
  }
   return (
        <>
            <div className='row text-dark vh-100 border w-100 bgImage'>
                {/* left side */}
                <div
                    className='col-md-5 vh-100 align-items-center d-flex justify-content-md-center justify-content-sm-center left-side'>

                    <div className='card border-0' style={{
                        borderRadius: "1rem",
                        height: "fit-content",
                        background: "rgba(255,255,255,0.3)",
                        backdropFilter: "blur(3px)"
                    }}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='card-body p-4  min-vh-50 shadow' style={{color: "black"}}>
                                <h5 className='card-title fw-bolder max-width-100 text-4xl'>Sign In</h5>
                                <p className='fw-semibold m-2' style={{fontSize: "small"}}>Enter your email and password
                                    for
                                    sign in.</p>
                                <p className='text-normal mb-3 m-1' style={{color: "gray", fontSize: "small"}}>Dont have
                                    an account?
                                    <a href="https://app.zebrapro.in/sign-up" className="fw-bold text-dark"
                                       style={{textDecoration: "none"}}> Sign
                                        Up</a></p>
                                <div className='form-group mb-3'>
                                    <label htmlFor="email-login" className="fw-bold mb-3"
                                           style={{fontSize: "small", color: "grey"}}>
                                        Email
                                    </label>
                                    <input
                                        style={{
                                            width: "100%",
                                            border: "1px solid",
                                            borderColor: "#b6adad",
                                            borderRadius: "0.5rem",
                                            height: "3rem"
                                        }}
                                        id="username"
                                        name="username"
                                        onChange={formik.handleChange}
                                        value={formik.values.username}

                                        className="p-2" type="email" required="required"
                                        placeholder="your-email@example.com"/>

                                </div>
                                <div className="form-group mb-3">
                                    <p htmlFor="password-login" className="fw-bold "
                                           style={{fontSize: "small", color: "grey"}}>
                                        Password
                                    </p>
                                    <input pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                           style={{
                                               width: "100%",
                                               border: "1px solid",
                                               borderColor: "#b6adad",
                                               borderRadius: "0.5rem",
                                               height: "3rem"
                                           }}
                                           title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
                                           id="password"
                                           name="password"
                                           onChange={formik.handleChange}
                                           value={formik.values.password}

                                           className="p-2" required="required" type="password"
                                           placeholder="Enter your password"/>
                                </div>
                                <a href="/password_reset"><p className="font-weight-normal align-items-end mb-2"
                                                             style={{textAlign: "right", fontSize: "small"}}>Forgot
                                    Password?</p></a>

                                <button id="loginSubmit" className="width-100 btn btn-dark mb-3 " type='submit'
                                        style={{width: "100%", color: "white", backgroundColor: "black"}}
                                        onClick={() => {
                                            formik.handleSubmit();
                                        }}
                                >SIGN IN</button>
                            </div>

                        </form>
                    </div>

                </div>

                {/*right side*/}
                <div className="col-md-7  justify-content-start  ps-5 align-items-center d-flex   glass"
                     style={{paddingLeft: "7rem"}} id="logo_and_text">
                    <div className="d-flex flex-column align-items-center"
                         style={{fontfamily: "work sans"}}>
                        <img className="align-self-center center pb-3" style={{width: "70px"}}
                             src="./assets/images/Zebralogo.png"/>
                        <h1 className="fw-bolder " style={{color: "black", fontWeight: "bold"}}>Welcome to
                            ZebraPro</h1>
                        <p className="align-self-center text-center">Research Station For Financial Analysts</p>
                    </div>
                </div>

            </div>
        </>
    );
}

export default SignIn;