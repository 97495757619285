const notes = (state = {}, action) => {
    switch (action.type) {
        case "COPY_IMAGE":
            return {
                ...state,
                image: action.payload,
            };
        case "COPY_TEXT":
            return {
                ...state,
                text: action.payload,
            };
        case "TAB_CHANGE":
            return {
                ...state,
                tab: action.payload,
            };

        case "HEADER_CHANGE":
            return {
                ...state,
                projectId: action.payload,
            };
        default:
            return state
    }
};

export default notes;