import React from "react";
import Header from "../component/header/Header";
import {Route, Switch} from 'react-router-dom';
import AdminRoutes from "../route-lists/AdminRoutes";
import Sidebar from "../component/sidebar/Sidebar";
import {useSelector} from "react-redux";


let Admin = () => {

    const sidebarToggle = useSelector((state => state.changeTheSidebar));


    return (
        <>
            <div className="d-flex vh-100">
                <Sidebar/>
                <div style={{width: sidebarToggle ? "calc(100% - 230px)" : "calc(100% - 85px)", transition: 'width 0.2s ease 0s'}}>
                    <Header/>
                    <div className='w-100' style={{ overflow: "auto", height: "calc(100vh - 100px)"}}>
                        <Switch>
                            {AdminRoutes.admin.map((item, index) => {
                                return <Route key={index} path={`${item.layout}${item.path}`}
                                              component={item.component}/>
                            })}
                        </Switch>
                    </div>
                </div>


            </div>

        </>
    );
}

export default Admin;