//import {StatsIcon} from "../components/Icons/Icons";
// Check Lists

import React from "react";
import NotesView from "../views/notes/NotesView";

const routes = [
    {
        name: "Notes Document",
        path: "/view/:id",
        icon: '',
        authIcon: '',
        component: NotesView,
        layout: "/notes",
        collapse: false,
    },

];

export default routes;