import React from "react";

const YesNoSelect = React.forwardRef((props, ref) => {
    const question = props.question;
    const questionIdx = props.questionIdx;
    const sectionIdx = props.sectionIdx;
    const onChangeContent = props.onChangeContent;


    return (
        <div className='w-100 p-1'>
            <select
                style={{fontSize: '14px'}}
                className='w-50 outline-none border p-2 bg-white rounded form-select'
                value={question.answer.value}
                placeholder='Select option'
                onChange={(e) => {
                    question.answer.value = e.target.value;
                    onChangeContent(questionIdx, sectionIdx, question);
                }
                }>
                <option selected={(question.value === 'yes')} value={'yes'}>YES</option>
                <option selected={(question.value === 'no')} value={'no'}>NO</option>
            </select>
        </div>
    )
});

export default YesNoSelect;