import React, {useState} from "react";
import {getCurrentCompany} from "../../utills/Common";
import {getQuarterlyAndOtherReports} from "../../actions/Reports";
import {useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {uploadNotesDocumentLink} from "../../actions/Notes/Notes";
import {GrClose} from "react-icons/gr";
import {toast} from "react-hot-toast";
import {CgChevronDown} from "react-icons/cg";

let QuarterlyReport = () => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const [quarterlyReports, setQuarterlyReports] = useState([]);
    const [currentCompany, setCurrentCompany] = useState({});
    const currentUser = useSelector(state => state.currentUser);
    const [selectedYearRange, setDateRange] = useState(1);
    const [uploadingState, toggleUploadingState] = useState(false);
    const [reportName, setReportName] = useState(null);
    const [attachmentName, setAttachmentName] = useState();
    let history = useHistory();
    const alert = toast;
    let years = [];
    let counter = 1;
    for (let i = (new Date()).getFullYear(); i >= 2000; i--) {
        years.push({
            id: counter++,
            start: `${i}0701`,
            end: `${i + 1}0629`,
            text: `${i} - ${i + 1}`
        })
    }


    const getCurrentCompanyDetails = async () => {
        let companyDetails = await getCurrentCompany();
        setCurrentCompany(companyDetails);
    };

    const getAllQuarterlyReports = async (strCat, strPrevDate, strToDate, strScrip, strType) => {
        let response = await getQuarterlyAndOtherReports(strCat, strPrevDate, strToDate, strScrip, strType);

        if (response.data.Table && response.data.Table.length > 0) {
            let quarterlyReportData = response.data.Table.filter(report => {
                let text = `/(Quarter)|(Quarterly)|(Financial Result)|(Financial Results)|(Financial)/`
                let result = report.HEADLINE.match(text);

                if (result && result.length > 0) {
                    return report;
                }
            });

            setQuarterlyReports(quarterlyReportData);
        } else {
            setQuarterlyReports([]);
            // alert.error("No data found!");
        }
    };

    const uploadDocument = async (link, name) => {
        if (link && name) {
            toggleUploadingState(true);
            let formData = new FormData();
            formData.append("link", link);
            formData.append("name", name);
            console.log(token);
            let response = await uploadNotesDocumentLink(currentCompany.id, formData, token);
            if (response.data.success) {
                toggleUploadingState(false);
                history.push("/notes/view/" + response.data.data.id);
                document.getElementById('modalClose').click();
                alert.success(response.data.message);
            } else {
                toggleUploadingState(false);
                alert.error(response.data.message);
            }
        }

    }

    React.useEffect(() => {
        getCurrentCompanyDetails();
    }, []);

    const onSearch = async () => {
        console.log(selectedYearRange)
        let year = getDateById(selectedYearRange);
        if (year.length > 0) {
            let fromDate = year[0].start;
            let toDate = year[0].end;
            await getAllQuarterlyReports('Result', fromDate, toDate, currentCompany.company.company_id, 'C');
        }
    };

    function getDateById(id) {
        return years.filter(
            function (data) {
                return data.id == id
            }
        );
    }


    return (
        <>

            <div className='m-4 bg-white rounded-4 shadow-sm fontstyle'>
                <div className='d-flex w-100 justify-content-between mb-2'>
                    <div className='align-self-center form-group flex-column'>
                        <p className='fw-semibold' style={{paddingLeft: "1.8rem"}}>Quarterly Report</p>
                    </div>


                    <div className='align-self-center m-2 '>
                        <label htmlFor='selectInputYear' className=' flex-column fw-semibold'>Finacial Year</label>
                        <div className='d-flex'>
                            <div className='border rounded py-2 d-flex me-2' style={{width: '18rem'}}>
                                <img className='px-3' type='button' src='./assets/icons/search glass icon.svg'/>
                                <select className='form-control border-0 outline-none py-1 w-100' id='selectInputYear'
                                        onChange={
                                            (e) => {
                                                setDateRange(e.target.value)
                                            }}>

                                    {years.map(year => <option key={year.id}
                                                               value={year.id}>{year.text}</option>)}
                                </select>
                                <CgChevronDown className='m-2' style={{width: '20px'}}/>
                            </div>
                            <button type='submit' className='btn btn-primary me-4' onClick={onSearch}>search</button>
                        </div>
                    </div>
                </div>


                <div className='row m-4 mt-0 gx-3 pb-4'>


                    {
                        (quarterlyReports && quarterlyReports.length > 0) ?
                            (quarterlyReports.map((row, index) =>
                                <div className='col-3 p-2' key={index}
                                     data-bs-toggle="modal"
                                     data-bs-target="#renameQReport"
                                     onClick={() => {
                                         setAttachmentName(row.ATTACHMENTNAME);
                                     }}>

                                    <div className='shadow-sm rounded-4 p-0'
                                         style={{backgroundColor: "#f6f6f6", border: "1px solid #eeeeee"}}>
                                        <img className='w-100' style={{height: "6.3rem"}}
                                             src="./assets/images/pdfPreview.png"/>
                                        <div className='d-flex  rounded-1 align-items-center'
                                             style={{backgroundColor: "#f6f6f6"}}>

                                            <img src="./assets/images/ageisCompany.png" className="rounded-circle m-1"
                                                 style={{width: "2.5rem"}}
                                                 alt="Avatar"/>
                                            <div className='d-flex justify-content-between w-100'>
                                                <div className='align-self-center pt-2 d-flex flex-column flex-wrap'>
                                            <span>
                                                <span className='d-block p-1'
                                                      style={{fontSize: "10px", fontWeight: "600"}}> {row.NEWSSUB}
                                                </span>
                                            </span>
                                                    <span className='ps-1' style={{fontSize: "10px"}}>PDF - Uploaded 1
                                                        month
                                                        ago
                                                    </span>
                                                </div>

                                                <div className='align-self-center p-2'>
                                                    <img src="./assets/icons/threeDots.svg"/>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            )) : <> <span className='p-1'>no data found...</span> ( try to search report by selecting
                                perticular year !)</>
                    }


                </div>

            </div>

            {/*Rename Report name Modal*/}
            <div className="modal fade" id="renameQReport" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <p className='fw-semibold'>Enter Report Name</p>
                                <div className='outline none' data-bs-dismiss="modal" type='button' id='modalClose'
                                     onClick={() => {
                                         setReportName(null);
                                         setAttachmentName(null);
                                     }}><GrClose/></div>
                            </div>

                            <div className='p-2'>
                                <input type='text' className=' w-100 outline-none rounded-2 '
                                       onChange={(event => setReportName(event.target.value))}
                                       style={{
                                           border: '0.5px solid #ebebeb',
                                           backgroundColor: '#fafafa',
                                           height: '3rem',
                                       }}/>
                            </div>
                            <div className='w-100 d-flex justify-content-end'>
                                <button className='p-2 m-2 mb-0 btn'
                                        style={{backgroundColor: '#f3f3f3'}} data-bs-dismiss="modal"
                                        onClick={() => {
                                            setReportName(null);
                                            setAttachmentName(null);
                                        }}>close
                                </button>
                                <button className='btn buttonOrangeFill  m-2 mb-0'
                                        style={{width: '8.5rem'}}
                                        onClick={() => {
                                            uploadDocument("https://www.bseindia.com/xml-data/corpfiling/AttachLive/" + attachmentName, reportName);
                                        }}> Save & open
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default QuarterlyReport;