import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";

import {createEmptyMatrix} from "react-spreadsheet";

import {getCurrentCompany} from "../../../utills/Common";
import {getNotesComments, postNotesComments} from "../../../actions/Notes/Notes";
import moment from "moment";
import MD5 from "crypto-js/md5";



const CommentsView = (props) => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const currentUser = useSelector(state => state.currentUser);
    const [data, setData] = useState([]);
    const sheetRef = useRef();
    let documentId = props.documentId;
    const [currentCompany, setCurrentCompany] = useState({});
    const [isSaving, toggleSaving] = useState(false);
    //const alert = useAlert();
    const textInputRef = useRef();
    const [commentState, toggleCommentState] = useState(false);
    const [commentSendLoading, toggleCommentSendLoading] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        getCurrentProjectDetails();
        fetchComments();
    }, [documentId, commentState]);

    const getCurrentProjectDetails = async () => {
        let companyDetails = await getCurrentCompany();
        setCurrentCompany(companyDetails);
        setUserId(user.id);
    };

    const fetchComments = async () => {
        try {
            let response = await getNotesComments(documentId, token);
            if (response.data.success) {
                setData(response.data.data)
            }
        } catch (e) {
            console.log(e)
        }
    };

    const sendComment = async (text, commentId) => {
        try {
            let commentData = {
                comment: text,
            };
            if (commentId) {
                commentData.parent_comment_id = commentId;
            }
            let response = await postNotesComments(documentId, commentData, token);
            if (response.data.success) {
            }
        } catch (e) {
            console.log(e)
        }
    };

    const handleSendButtonClick = async () => {
        toggleCommentSendLoading(true);
        // console.log(textInputRef.current.value)
        await sendComment(textInputRef.current.value, null);
        textInputRef.current.value = "";
        toggleCommentState(!commentState);
        toggleCommentSendLoading(false);
    };

    let mComments = data.length > 0 ? (data.map((item) => {
        return (
            <div key={item.id} className='d-flex  p-2'>
                <div className=''>
                    <img
                        src={`https://www.gravatar.com/avatar/${MD5(item.user.username + "@zebrapro.in").toString()}?d=identicon`}
                        className="rounded-circle my-auto align-self-center m-1 "
                        style={{width: '20px'}}
                        alt="Avatar"/>
                </div>
                <div className='w-100 rounded' style={{backgroundColor: '#f5f5f5'}}>
                    <div className='d-flex align-items-center'>
                        <text className='p-2 pb-0 fw-semibold' style={{fontSize: '13px'}}>{item.user.name}</text>
                    </div>
                    <div className='ps-2'>
                        <text style={{color: 'grey', fontSize: '12px'}}>{item.comment}</text>
                    </div>
                    <div className='pe-2 text-end'>
                        <text style={{
                            color: 'grey',
                            fontSize: '12px'
                        }}>{moment(new Date(item.createdAt)).fromNow()}</text>
                    </div>
                </div>
            </div>
        );
    })) : ''


    return (
        <>
            <div>
                <div className='sticky-top bg-white shadow'>
                    <text className='p-2' style={{fontSize: '20px', fontWeight: '500'}}>Comments</text>
                    <div className=''>
                    <textarea
                        className='m-2 p-1 w-100 rounded outline-none border-0'
                        placeholder='Enter your comment here...'
                        ref={textInputRef}
                        style={{backgroundColor: '#f6f6f6'}}></textarea>
                        <button className='m-2 px-3 mt-0 mb-4 btn btn-primary'
                                isLoading={commentSendLoading}
                                onClick={handleSendButtonClick}>Send &nbsp;<img style={{width: '15px'}}
                                                                                src='/assets/icons/workAreaIcon/sendIconSVG.svg'/>
                        </button>
                    </div>
                </div>
                <div className=''>
                    {mComments}
                </div>
            </div>
        </>
    )
};

export default CommentsView;