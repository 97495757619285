import React, {useEffect, useState} from "react";
//import {useAlert} from "react-alert";
import {useSelector} from "react-redux";
import {getAnnualReports} from "../../actions/Reports";
import {getCurrentCompany} from "../../utills/Common";
import {Link, useHistory} from "react-router-dom";
//import moment from 'moment';
import {uploadNotesDocumentLink} from "../../actions/Notes/Notes";
import {getLocalToken, getLocalUser} from "../../utils/storage";

let AnnualReports = () => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const [annualReports, setAnnualReports] = useState([]);
    const [currentCompany, setCurrentCompany] = useState({});
    const currentUser = useSelector(state => state.currentUser);
    const [uploadingState, toggleUploadingState] = useState(false);
    let history = useHistory();
    //let alert = useAlert();

    let currentUrl = window.location.href;

    let result;

    const getAllAnnualReports = async (company_id) => {
        let response = await getAnnualReports(company_id);

        if (response.data.Table && response.data.Table.length > 0) {
            //console.log(response.data.Table[0]);
            setAnnualReports(response.data.Table);
        }
    };

    const getCurrentCompanyDetails = async () => {
        let companyDetails = await getCurrentCompany();
        setCurrentCompany(companyDetails);
        //console.log(companyDetails);
        await getAllAnnualReports(companyDetails.company.company_id);
    };

    const uploadDocument = async (link, name) => {
        if (link && name) {
            toggleUploadingState(true);
            let formData = new FormData();
            formData.append("link", link);
            formData.append("name", name);
            let response = await uploadNotesDocumentLink(currentCompany.id, formData, token);
            console.log(response);
            if (response.data.success) {
                toggleUploadingState(false);
                history.push("/notes/view/" + response.data.data.id);

                alert.success(response.data.message);
            } else {
                toggleUploadingState(false);
                alert.error(response.data.message);
            }
        }

    }

    function gotoAnnualReport(){
        history.push('/project/annual-report')
    }

    {/* card content outside as function*/}
    function cardLoop(row, index){
        return(
            <div className='col-3 p-2' key={index}
                 onClick={()=>{uploadDocument(row.PDFDownload, `Annual Report (${row.Year})`)}}>
                <div className='shadow-sm rounded-4 p-0'
                     style={{backgroundColor: "#f6f6f6", border: "1px solid #eeeeee"}}>
                    <img className='w-100' style={{height: "6.3rem"}}
                         src="./assets/images/pdfPreview.png"/>
                    <div className='d-flex  rounded-1 align-items-center'
                         style={{backgroundColor: "#f6f6f6"}}>

                        <img src="./assets/images/ageisCompany.png" className="rounded-circle m-1"
                             style={{width: "2.5rem"}}
                             alt="Avatar"/>
                        <div className='d-flex justify-content-between w-100'>
                            <div className='d-flex flex-column'
                                 style={{whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                                <span className='fs-7 fw-semibold text-capitalize' style={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                }}>Annual Report ({row.Year})</span>
                                <span
                                    className='fs-7 text-capitalize'>PDF - Uploaded 1 month ago</span>
                            </div>
                            <div className='align-self-center p-2'>
                                <img src="./assets/icons/threeDots.svg"/>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )
    }


    useEffect(() => {
        getCurrentCompanyDetails();
    }, []);


    return (
        <>
            <div className='m-4 px-4 py-2 bg-white rounded-4 shadow-sm fontstyle '>
                <div className='d-flex pb-2'>

                    <div className=' w-100 d-flex flex-row justify-content-between '>
                        <span className='fw-semibold p-2 text-center ms-1' style={{paddingLeft: "1.8rem"}}>Annual Report</span>
                        {(currentUrl.includes('annual-report')) ?
                            <></>:  <span className="align-self-center">
                                <Link to='#' onClick={gotoAnnualReport} style={{textDecoration:'none'}}>
                                    <span className='text-primary' style={{ fontSize:'13px'}}>see more</span>
                                </Link>
                        </span>
                        }
                    </div>
                </div>

                <div className='row mt-0  gx-3'>

                    {
                        (annualReports && annualReports.length > 0)?
                        ( currentUrl.includes('annual-report'))
                        ? ( annualReports.map((row, index) =>cardLoop(row,index)

                        )) : ( annualReports.slice(0,8).map((row, index) =>cardLoop(row , index)
                            )):<>no data found</>
                    }

                </div>
            </div>
        </>
    );
}

export default AnnualReports;