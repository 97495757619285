import React, {useCallback, useEffect, useState} from "react";
import {Switch} from "react-router-dom";
import NotebookSidebar from "../component/sidebar/NotebookSidebar";
import {getCurrentCompany} from "../utills/Common";
import {getAllNotebooks, getNotebookNotes} from "../actions/Notebooks";
import {useSelector} from "react-redux";
import CkEditorNotes from "../views/notes/Notes/CkEditorNotes";
import {MdOutlineDelete} from "react-icons/md";


const Notebook = () => {

    const notebook = useSelector(state => state.notebook);
    const token = localStorage.getItem('token');
    const [currentCompany, setCurrentCompany] = useState({});
    const [notebooksList, setNotebookList] = useState([]);
    const [toggleNotebook, setToggleNotebook] = useState(false);
    const [pageData, setPageData] = useState({});
    const [bookData, setbookData] = useState({});


    const loadData = async () => {
        let projectDetails = await getCurrentCompany();
        setCurrentCompany(projectDetails);
        await fetchAllNotebooks(projectDetails.id);
    };


    const fetchAllNotebooks = async (projectId) => {
        let response = await getAllNotebooks(projectId, token);

        if (response.data.data && response.data.data.length > 0) {
            let checkListData = response.data.data.map((report, index) => {
                report.key = index + 1;
                report.action = null;
                return report;
            });

            setNotebookList(checkListData);
        } else {
            setNotebookList([]);
            // alert.error("class="btn btn-primary"");
        }
    };

    const nameByID = useCallback(() => {
        let bookDetail = notebooksList.find(obj => obj.id === notebook.data.book);

        return bookDetail ? bookDetail.name : '';
    }, [notebook]);

    const pageByID = useCallback(() => {
        console.log(notebooksList);
        if (notebook != null && notebooksList.length > 0) {
            let pageDetail = notebooksList.find(obj => obj.id === notebook.data.book).pages.find(obj => obj.id === notebook.data.page);
            return pageDetail ? pageDetail.name : '';
        } else {
            return '';
        }
    }, [notebook]);

    const refreshNotebook = () => {
        return (
            setToggleNotebook(!toggleNotebook)
        )
    }


    useEffect(() => {
        loadData();
    }, [toggleNotebook]);

    return (
        <>
            <div className="d-flex bg-white h-auto">
                {/*  <Sidebar/>*/}
                <NotebookSidebar notebookList={notebooksList} refreshNotebook={refreshNotebook}
                                 currentCompany={currentCompany}/>


                <div style={{width: "calc(100% - 300px)", transition: 'width 0.2s ease 0s'}}>
                    {/* <Header/>*/}
                    <div className='d-flex justify-content-between p-2 align-items-center border-bottom'
                         style={{height: '70px'}}>
                        <div className='d-flex align-items-center'>

                            <i className="ri-booklet-line fs-3 me-2"/>
                            <div>
                                <h1 className='fs-6 fw-semibold mb-1'>{nameByID()}</h1>

                                <span>Page : {pageByID()}
                                    <span>
                                        <i className="ri-pencil-line ps-2 align-text-top"></i>
                                        <span className='ps-2 '><MdOutlineDelete/></span>
                                  </span>
                                </span>
                            </div>
                        </div>

                        {/*    <div className='border rounded py-2 d-flex' style={{width: "375px"}}>
                            <img className='px-3' type='button' src='./assets/icons/search glass icon.svg'/>
                            <input className='border-0 outline-none py-1 w-100' placeholder='Search Project Name'
                                   id='myInput'
                                 //onChange={handleChange}
                            />
                            <i className="ri-delete-back-2-line align-self-center pe-2" type='reset' value='Reset'
                                 onClick={() => {
                                     //document.getElementById('myInput').value = '';
                                    // disp <i className="ri-pencil-line ps-2 align-text-top"></i>
                            <span className='ps-2 '><MdOutlineDelete/></span>atch(search.setSearch(''));
                                 }}s
                            />
                        </div>*/}
                    </div>

                    {/*content Space*/}
                    <div className='w-100 h-auto' style={{overflow: "auto", overflowX: 'hidden'}}>
                        <div>
                        </div>
                        <CkEditorNotes documentId={notebook.data.page?notebook.data.page:null}/>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Notebook;