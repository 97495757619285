import React from "react";
import { postRequest, getRequest, patchRequest } from '../../services/httpService';

/**
 * @description Get My All Projects
 * @param  {} body
 */
export const getAllCompanies = async(status = "Active", segment = "Equity", name = '') => {
    try {
        let response = await getRequest(`companies/select-list?status=${status}&segment=${segment}&name=${name}`);
        return response;
    } catch (error) {
        return error;
    }
};

/**
 * @description Get My All Projects
 * @param  {} body
 */
export const getAllUserProjects = async(token, searchedValue = '') => {
    try {
        let response = await getRequest('projects?name=' + searchedValue, token);
        return response;
    } catch (error) {
        return error;
    }
};

/**
 * @description Get project by id
 * @param  {} body
 */
export const getProjectById = async(projectId, token) => {
    try {
        let response = await getRequest(`projects/get/${projectId}`, token);
        return response;
    } catch (error) {
        return error;
    }
};

/**
 * @description Get My All Projects
 * @param  {} body
 */
export const createProject = async(body, token) => {
    try {
        let response = await postRequest('projects', body, token);
        return response;
    } catch (error) {
        return error;

    }
};

//from here edited
export const getAllPublicProjects = async (token, searchedValue='')=>{
    try{
        let response = await getRequest('projects/public?name='+searchedValue, token);
        return response;
    }
    catch(error){
        return error;
    }
};





export const getAllCompanyProjects = async(token, searchedValue = '') => {
    try {
        let response = await getRequest('projects/company?name=' + searchedValue, token);
        return response;
    } catch (error) {
        return error;
    }
}

export const updateProjectName = async(projectId, body, token) => {
    try {
        let response = await patchRequest(`projects/name/${projectId}`, body, token);
        return response;
    } catch(error){
        return error;
    }
};

export const updateProjectPrivacy = async(projectId, body, token) => {
    try {
        let response = await patchRequest(`projects/visibility/${projectId}`, body, token);
        return response;
    }catch(error){
        return error;
    }
}


export const updateProjectDescription = async(projectId, body, token) => {
    try {
        let response = await patchRequest(`projects/description/${projectId}`, body, token);
        return response;
    }catch(error){
        return error;
    }
}