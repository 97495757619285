import {deleteRequest, getRequest, patchRequest, postRequest} from '../../services/httpService';

/**
 * Create Checklist Topic
 * @param projectId
 * @param body
 * @param token
 * @returns {Promise<*>}
 */
export const createNewCheckListTopic = async(projectId, body, token) => {
    console.log(projectId,body,token);
    try {
        return await postRequest(`checklist/topics/${projectId}`, body, token);
        console.log(projectId,body,token);
    } catch (error) {
        return error;
    }
};

/**
 * Get Checklist Topic List
 * @param projectId
 * @param token
 * @param nameSearch
 * @returns {Promise<*>}
 */
export const getChecklistTopics = async (projectId, token, nameSearch) => {
    try {
        return await getRequest(`checklist/topics/${projectId}?name=${nameSearch}`, token);
    } catch (error) {
        return error;
    }
};

/**
 * Get Checklist Tab
 * @param topicId
 * @param token
 * @returns {Promise<*>}
 */
export const getChecklists = async(topicId, token) => {
    try {
        return await getRequest(`checklist/${topicId}`, token);
    } catch (error) {
        return error;
    }
};

/**
 * Create Checklist
 * @param topicId
 * @param body
 * @param token
 * @returns {Promise<*>}
 */
export const createNewCheckList = async(topicId, body, token) => {
    try {
        return await postRequest(`checklist/${topicId}`, body, token);
    } catch (error) {
        return error;
    }
};


/**
 * change Checklist name
 * @param topicId
 * @param body
 * @param token
 * @returns {Promise<*>}
 */
export const updateChecklistName = async (topicId, body, token) => {

    try {
        return await patchRequest(`checklist/topics/${topicId}`, body, token);
    } catch (error) {
        return error;
    }
};

/**
 * Delete Checklist
 * @param id
 * @param token
 * @returns {Promise<*>}
 */

export const deleteCheckList = async(id, token) => {
    try {
        return await deleteRequest(`checklist/topics/${id}`, token);
    } catch (error) {
        return error;
    }
};

/**
 * Store Checklist Questions
 * @param checklistId
 * @param body
 * @param token
 * @returns {Promise<*>}
 */
export const saveChecklistQuestions = async(checklistId, body, token) => {
    try {
        return await postRequest(`checklist/questions/${checklistId}`, body, token);
    } catch (error) {
        return error;
    }
};
export const addCheckListQuestion = async(id, token) => {
    try {
        ///checklist/questions/add/:checklist_id
        return await patchRequest(`checklist/questions/add/${id}`, token);
    } catch (error) {
        return error;
    }
};
export const updateCheckListQuestion = async(id, token,body) => {
    try {
        ///checklist/questions/add/:checklist_id
        console.log(id)
        return await patchRequest(`checklist/sections/questions/${id}`,body, token);
    } catch (error) {
        return error;
    }
};

/**
 * Delete Checklist Questions
 * @param questionId
 * @param token
 * @returns {Promise<*>}
 */
export const deleteChecklistQuestion = async (questionId, token) => {
    try {
        return await deleteRequest(`checklist/questions/${questionId}`, token);
    } catch (error) {
        return error;
    }
};

/**
 * Get Checklist By Topic ID
 * @param topicId
 * @param token
 * @returns {Promise<*>}
 */
export const getTopicById = async(topicId, token) => {
    try {
        return await getRequest(`checklist/topics/get/${topicId}`, token);
    } catch (error) {
        return error;
    }
};

/**
 * Get Checklist Questions
 * @param checklistId
 * @param token
 * @returns {Promise<*>}
 */
export const getChecklistQuestions = async(checklistId, token) => {
    try {
        return await getRequest(`checklist/questions/${checklistId}`, token);
    } catch (error) {
        return error;
    }
};

/**
 * Get Checklist Questions
 * @param checklistId
 * @param token
 * @returns {Promise<*>}
 */
export const getChecklistSections = async (checklistId, token) => {
    try {
        return await getRequest(`checklist/sections/get/${checklistId}`, token);
    } catch (error) {
        return error;
    }
};

/**
 * Save Checklist Section
 * @param checklistId
 * @param body
 * @param token
 * @returns {Promise<*>}
 */
export const saveChecklistSections = async (checklistId, body, token) => {
    try {
        return await postRequest(`checklist/sections/${checklistId}`, body, token);
    } catch (error) {
        return error;
    }
};

/**
 * Save Checklist Section
 * @param sectionId
 * @param body
 * @param token
 * @returns {Promise<*>}
 */
export const updateChecklistSections = async (sectionId, body, token) => {
    try {
        return await postRequest(`checklist/sections/${sectionId}`, body, token);
    } catch (error) {
        return error;
    }
};

/**
 * Save Checklist Section
 * @param sectionId
 * @param token
 * @returns {Promise<*>}
 */
export const deleteChecklistSections = async (sectionId, token) => {
    try {
        return await deleteRequest(`checklist/sections/${sectionId}`, token);
    } catch (error) {
        return error;
    }
};