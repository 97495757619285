import React from "react";
import {Redirect, Route} from 'react-router-dom';


let ProtectedRoute = (props) => {
    const Component = props.component;
    const Path = props.path;
    const isAuthenticated = localStorage.getItem('token');
    return isAuthenticated ? (<Route path={Path} component={Component}/>) : (<Redirect to={{pathname: '/login'}}/>)
}

export default ProtectedRoute;