import React, {useCallback, useEffect, useState} from "react";

import _debounce from 'lodash/debounce';

const ChecklistTextBox = React.forwardRef((props, ref) => {

    const question = props.question;
    const questionIdx = props.questionIdx;
    const sectionIdx = props.sectionIdx;
    const onChangeContent = props.onChangeContent;
    const [inputValue, setInputValue] = useState(question.answer.value);
    const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

    function handleDebounceFn() {
        console.log("api call", inputValue);
        onChangeContent(questionIdx, sectionIdx, question);
    }

    return (
        <div className='w-100 p-1'>
            <input className='w-100 bg-none border-0 outline-none' style={{fontSize: '14px'}}
                   name={question.answer.name}
                   value={inputValue}
                   onChange={(e) => {
                       setInputValue(e.target.value);
                       question.answer.value = e.target.value;
                       onChangeContent(questionIdx, sectionIdx, question);

                       //debounceFn();
                   }}
            />
        </div>
    )
});

export default ChecklistTextBox;