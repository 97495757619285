import React, {useEffect, useRef, useState} from "react";
import 'remixicon/fonts/remixicon.css';
import {useSelector} from "react-redux";
import {getCurrentCompany} from "../../utills/Common";
import {getAllResourceLinks, createResourceImportLinks} from "../../actions/Reports/importLinks";

import truncateString from "../../utills/reusableFunction";
import {Link, useHistory} from "react-router-dom";
import {GrClose} from "react-icons/gr";
import {toast} from "react-hot-toast";


let ImportLink = () => {
    const alert = toast;
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const [importLinks, setImportLinks] = useState([]);
    const [currentCompany, setCurrentCompany] = useState({});
    const [createImportLinks, setCreateImportLinks] = useState({});
    const [searchedText, setSearchedText] = useState("");
    const [showError, setShowError] = useState({name: '', link: '', proper: ''});
    const currentUser = useSelector(state => state.currentUser);
    const searchValue = useSelector(state => state.search);
    let currentUrl = window.location.href;
    let history = useHistory();
    const getNameElem = useRef();
    const getLinkElem = useRef();
    const getDiscElem = useRef();

    const getCurrentCompanyDetails = async () => {
        let companyDetails = await getCurrentCompany();
        setCurrentCompany(companyDetails);

        await getAllImportLinks(companyDetails.id);
    };

    const getAllImportLinks = async (projectId) => {
        let response = await getAllResourceLinks(token, projectId, searchValue.value != '' ? searchValue.value : '');
        if (response.data.data && response.data.data.length > 0) {
            setImportLinks(response.data.data);
        } else {
            setImportLinks([]);
        }
    };

    const filterImportLinks = async () => {
        await getAllImportLinks(currentCompany.id);
    }

    function gotoImportLink() {
        history.push('/project/import-link')
    }

    useEffect(() => {
        if (currentCompany && currentCompany.id != null) {
            filterImportLinks();
        }
    }, [searchValue])

    useEffect(() => {
        getCurrentCompanyDetails();
    }, []);


    const onChangeCreateImportLinks = (key, value) => {
        let importLink = createImportLinks;
        importLink[key] = value;

        setCreateImportLinks(importLink);
    };

    const onSubmitCreateLinks = async () => {

        let response = await createResourceImportLinks(currentCompany.id, createImportLinks, token);
        console.log(response);
        if (createImportLinks.link) {
            if (ValidURL(createImportLinks.link) === false) {
                alert.error('enter Valid URL')
                setShowError({proper: 'Enter Valid Url'});
            }

        }
        if (createImportLinks.name && createImportLinks.link) {
            if (response.data.success) {
                await getAllImportLinks(currentCompany.id);
                document.getElementById('linkModalClose').click();
                alert.success(response.data.message);
                setCreateImportLinks({});
            } else {
                alert.success(response.data.message);
            }
        } else {
            if (!createImportLinks.name) {
                alert.error("Name is required");
                setShowError({name: "Name is required"});
            }
            if (!createImportLinks.link) {
                alert.error("Link is required");
                setShowError({link: 'Link is required'});
            }
        }
    };


    function ValidURL(str) {
        let regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (!regex.test(str)) {
            alert.error("Please enter valid URL.");
            return false;
        } else {
            return true;
        }
    }


    {/* Card of Link separated as function*/
    }

    function importLinkCard(row, index) {
        return (

                <div className='col-3 p-2' key={index}>
                    <a href={row.link} style={{textDecoration:'none',color:'black'}}target='_blank' >
                    <div className='shadow-sm rounded-4 p-0'
                         style={{backgroundColor: "#f6f6f6", border: "1px solid #eeeeee"}}>
                        <div className='d-flex justify-content-between w-100 ps-2'>
                            <div className='d-flex flex-column'
                                 style={{whiteSpace: 'nowrap', overflow: 'hidden', alignSelf: 'center'}}>
                                                <span className='fs-7 fw-semibold text-capitalize' style={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                    fontSize: "13px",
                                                    fontWeight: "600"
                                                }}>{truncateString(row.name, 30)}</span>
                                <span
                                    className='fs-7 fw-semibold' style={{
                                    fontSize: "10px",
                                    color: '#013566'
                                }}>{truncateString(row.link, 30)}</span>
                            </div>


                            <div className='align-self-center p-2 m-2'>
                                <img src="./assets/icons/threeDots.svg"/>
                            </div>
                        </div>
                    </div>
                    </a>
                </div>

        );
    }

    return (
        <>

            <div className='m-4 px-4 py-2 bg-white rounded-4 shadow-sm fontstyle '>
                <div className='d-flex pb-2'>

                        <button className='btn bg-white ri-add-line text-primary '
                                data-bs-toggle="modal"
                                data-bs-target="#createImportLink"
                                style={{
                                    borderBottom: "2px solid #D76D00",
                                    borderRadius: "12px",
                                    boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.13)"
                                }}>

                        </button>

                    <div className=' w-100 d-flex flex-row justify-content-between'>
                        <span className='fw-semibold p-2 text-center ms-1'>Links and Resources</span>
                        {(currentUrl.includes('import-link')) ?
                            <></> : <span className="align-self-center"><Link to='#' onClick={gotoImportLink} style={{textDecoration:'none'}}>
                                <span className='text-primary' style={{fontSize:'13px'}}>see more</span>
                            </Link></span>
                        }
                    </div>
                </div>

                <div className='row gx-3'>

                    {
                        (importLinks && importLinks.length > 0) ?
                            (currentUrl.includes('import-link'))
                                ? (importLinks.map((row, index) => importLinkCard(row, index)
                                )) : (importLinks.slice(0, 8).map((row, index) => importLinkCard(row, index)
                                )) : <>no data found</>
                    }

                </div>

            </div>


            {/*create import link modal*/}

            <div className="modal fade" id="createImportLink" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <p className='w-100 border-0 fw-semibold outline-none' type='text'>Import Link</p>

                                <div className='outline none' id={'linkModalClose'} data-bs-dismiss="modal"
                                     type='button'
                                     onClick={() => {
                                         getNameElem.current.value = '';
                                         getLinkElem.current.value = '';
                                         getDiscElem.current.value = '';
                                         setShowError({name: '', link: '', proper: ''});
                                     }}><GrClose/></div>
                            </div>

                            <div className='p-2'>
                                <input type='text' className=' w-100 outline-none mt-3'
                                       ref={getNameElem}
                                       placeholder='Enter your link name' name="name" required
                                       style={{height: '3rem', borderLeft:"none",borderRight:"none",borderTop:"none",borderBottom: "1px solid #cecece"}}
                                       onChange={(e) => {
                                           onChangeCreateImportLinks("name", e.target.value)
                                       }}/>
                                <span style={{borderBottom: '0.5px solid #dadada'}}></span>
                                <span style={{fontSize: '12px', color: 'red'}}>{showError.name}</span>

                                <input type="url" className=' w-100 outline-none rounded-2 mt-3 border-0'
                                       ref={getLinkElem}
                                       placeholder='provide exact link path' name='link' required
                                       style={{backgroundColor: '#fffaf3', height: '3rem', color: '#d76d00'}}
                                       onChange={(e) => {
                                           onChangeCreateImportLinks("link", e.target.value)
                                       }}/>
                                <span style={{fontSize: '12px', color: 'red'}}>{showError.link}</span>
                                <span style={{fontSize: '12px', color: 'red'}}>{showError.proper}</span>

                                <textarea className=' w-100 outline-none rounded-2 mt-3' ref={getDiscElem}
                                          placeholder='Description or type (optional)' name="type"
                                          onChange={(e) => {
                                              onChangeCreateImportLinks("type", e.target.value)
                                          }}
                                          style={{
                                              border: '0.5px solid #ebebeb',
                                              backgroundColor: '#fafafa',
                                          }}></textarea>
                            </div>
                            <div className='w-100 d-flex justify-content-center p-2'>

                                <button className='btn buttonOrangeFill'
                                        style={{width: '8.5rem'}}
                                        onClick={() => onSubmitCreateLinks()}
                                > Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}

export default ImportLink;