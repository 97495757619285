import {types} from '../types';

const rSetChecklistId = (checklist_id) => {
    console.log(checklist_id, 'action')
    return {
        type: types.SET_CHECKLIST_ID,
        payload: checklist_id
    };
};
const rSetChecklistSectionId = (checklist_id) => {
    return {
        type: types.SET_CHECKLIST_SECTION_ID,
        payload: checklist_id
    };
};

export default {
    rSetChecklistId,
    rSetChecklistSectionId
}