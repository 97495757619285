import React, {useEffect, useRef, useState} from "react";
import {MdArrowDropDown} from "react-icons/md";
import truncateString from "../../utills/reusableFunction";
import {toast} from "react-hot-toast";
import NotebookPageMap from "./NotebookPageMap";
import {GrClose} from "react-icons/gr";
import {createNotebook, deleteNotebook, updateNotebook} from "../../actions/Notebooks";
import {MdOutlineDelete} from "react-icons/md";
import {RiEditLine} from "react-icons/ri";


const NotebookSidebar = (props) => {
    const notebooksList = props.notebookList;
    const refreshNotebook = props.refreshNotebook;
    const currentCompany = props.currentCompany;

    const token = localStorage.getItem('token')
    const [toggle, setToggle] = useState({value: false, id: null});
    const [pageToggle, setPageToggle] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [deleteValue, setDeleteValue] = useState({value: null, name: null});
    const [editValue, setEditValue] = useState();
    const [editID, setEditId] = useState();
    const alert = toast;
    const inputBook = useRef();
    const inputEdit = useRef();


    const handleCreateNotebook = async () => {
        let body = {};

        if (inputBook.current.value && inputBook.current.value != "") {
            let response = await createNotebook(currentCompany.id, {name: inputBook.current.value}, token);
            if (response.data.success) {
                alert.success(response.data.message);
                refreshNotebook();
                inputBook.current.value = '';
                document.getElementById('closeNotebookModal').click();
            } else {
                alert.error(response.data.errors);
            }
        } else {
            alert.error("Please Enter Name");
        }

    };

    const handleOnDelete = async () => {
        try {
            let response = await deleteNotebook(deleteValue.value, token);
            if (response.data.success) {

                alert.success(response.data.message);
                document.getElementById('closeDeleteNote').click();
                refreshNotebook();
            } else {
                alert.error(response.data.errors);
            }
        } catch (e) {
            console.log(e);
        } finally {
            //onCloseDeleteNote();
        }
    };


    const changeProjectName = async () => {
        let response = await updateNotebook(editID, {name: editValue}, token);
        if (response.data.success) {
            document.getElementById('closeEditNotebookModal').click();
            alert.success(response.data.message);
            refreshNotebook();
        } else {
            alert.error(response.data.errors);
        }
    };


    return (
        <>
            <div
                className='overflow-hidden d-flex flex-column bg-secondary border-end h-auto px-3 py-4'
                style={{
                    width: '300px',
                    transition: 'width 0.2s ease 0s',
                    whiteSpace: 'nowrap'
                }}>

                <button data-bs-toggle='modal' data-bs-target='#AddNewNotebook'
                        className='btn btn-outline-primary mb-4 fw-semibold w-100 overflow-hidden'>+ {'New Notebook'}</button>
                <span className="fw-semibold text-primary">My Notebooks</span>
                <div className='list-group'
                     style={{overflow: 'scroll', overflowX: 'hidden '}}>
                    {(notebooksList && notebooksList.length > 0) ?
                        (notebooksList.map((row, index) =>
                            <>
                                <div key={index} className='d-flex justify-content-between py-1'>
                                <span
                                    className='btn fw-semibold d-flex w-100 border-0 outline-none p-0'

                                    onClick={() => {
                                        setToggle({value: true, id: index});
                                        setPageToggle(!pageToggle);
                                        // setIsActive(!isActive);
                                        // fetchAllNotebooksNote(row.id);

                                        let chacha = document.getElementById(`a${row.id}`);
                                        let bhatija = document.getElementById(`b${row.id}`);
                                        chacha.classList.toggle('d-none');
                                        bhatija.classList.toggle('triangleToggle');

                                    }}>

                                    <div className='d-flex'>
                                                    <span className={` align-self-end triangleToggle`}
                                                          id={`b${row.id}`}><MdArrowDropDown/></span>
                                                <i className="ri-booklet-line fs-6"/>
                                                <text className='align-self-center'
                                                      style={{fontSize: '14px'}}> &nbsp;{truncateString(row.name, 17)}
                                                </text>
                                    </div>
                                </span>

                                    <span type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                          className='align-self-center'>
                                            <img className=' align-self-end' src="./assets/icons/threeDots.svg"/>
                                        </span>
                                    <ul className="dropdown-menu bg-white" aria-labelledby="dropdownMenuButton1"
                                        style={{zIndex: '11'}}>
                                        <li className='dropdownButton'>
                                            <span className="dropdown-item"
                                                  data-bs-toggle='modal' data-bs-target='#editNotebookName'
                                                  onClick={() => {
                                                      setEditValue(row.name);
                                                      setEditId(row.id);
                                                  }}><RiEditLine/>&nbsp;Edit Name
                                            </span>
                                        </li>
                                        <li className='dropdownButton'>
                                            <span className="dropdown-item"
                                                  data-bs-toggle='modal' data-bs-target='#deleteNote'
                                                  onClick={() => {
                                                      setDeleteValue({value: row.id, name: row.name});
                                                  }}><MdOutlineDelete/>&nbsp;Delete
                                            </span>
                                        </li>

                                    </ul>

                                </div>


                                <div id={`a${row.id}`} className='d-none'>
                                    <NotebookPageMap currentCompany={currentCompany} data={row.id}
                                                     refreshNotebook={refreshNotebook}/>
                                </div>

                            </>
                        ))
                        : ''}
                </div>
            </div>


            {/* Add New Notebook Modal*/}
            <div className="modal fade" id="AddNewNotebook" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <p className='fw-semibold'>Add New Notebook</p>
                                <div id='closeNotebookModal' className='outline none' data-bs-dismiss="modal"
                                     type='button'
                                     onClick={() => {
                                         inputBook.current.value = '';
                                     }}><GrClose/></div>
                            </div>

                            <div className='p-2'>
                                <input type='text' className=' w-100 outline-none rounded-2'
                                       ref={inputBook}
                                       placeholder='Enter new notebook name'
                                       style={{
                                           border: '0.5px solid #ebebeb',
                                           backgroundColor: '#fafafa',
                                           height: "3rem"
                                       }}></input>
                            </div>
                            <div className='w-100 d-flex justify-content-center p-2'>

                                <button className='btn buttonOrangeFill'
                                        style={{width: '8.5rem'}}
                                        onClick={async () => {
                                            await handleCreateNotebook();
                                        }}> Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/*Delete Single Quetion Modal */}
            <div className="modal" id='deleteNote' tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Notebook ?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close" id='closeDeleteNote'
                                    onClick={() => {
                                        setDeleteValue({value: null, name: null});
                                    }}></button>
                        </div>
                        <div className="modal-body d-flex">
                            <img className='m-2' style={{width: "4rem"}}
                                 src='./assets/icons/84228-delete-animation.gif'/>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <p className='justify-content-center d-flex fw-semibold mb-0'
                                    >Are you sure ?&nbsp;</p>
                                    <span className='fw-normal'>
                                        <b>{deleteValue.name}</b> will be deleted permanently.</span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                    onClick={() => {
                                        setDeleteValue({value: null, name: null});
                                    }}>Close
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={async () => {
                                        await handleOnDelete()
                                    }}
                            >Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {/*Edit Checklist Name Modal*/}
            <div className="modal fade" id="editNotebookName" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <text className='fw-semibold'>Edit Check-list Name</text>
                                <div className='outline none' data-bs-dismiss="modal" type='button'
                                     id='closeEditNotebookModal'
                                     onClick={() => {

                                     }}><GrClose/></div>
                            </div>

                            <div className='p-2'>
                                <input type='text' className=' w-100 outline-none rounded-2'
                                       value={editValue}
                                       ref={inputEdit}
                                       onChange={(e) => {
                                           setEditValue(e.target.value);
                                       }}
                                       style={{
                                           border: '0.5px solid #ebebeb',
                                           backgroundColor: '#fafafa',
                                           height: "3rem"
                                       }}></input>
                            </div>
                            <div className='w-100 d-flex justify-content-center p-2'>

                                <button className='btn buttonOrangeFill'
                                        style={{width: '8.5rem'}}
                                        onClick={() => {
                                            changeProjectName();
                                        }}> Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    );
}

export default NotebookSidebar;