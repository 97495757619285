import React, {useState, useEffect} from "react";
import {
    deleteNote,
    getAllNotesDocuments,
    getAllUploadedNotesDocuments,
    updateProjectDocumentName
} from "../../actions/Notes/Notes";
import {getCurrentCompany} from "../../utills/Common";
import {Link, useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {MdOutlineDelete} from "react-icons/md";
import {GrClose} from "react-icons/gr";
import UploadNotesDocument from "./UploadNotesDocument";
import {toast} from "react-hot-toast";


let NotesListView = () => {

    const token = localStorage.getItem('token');
    // const user = localStorage.getItem('user');
    const history = useHistory();
    // const currentUser = useSelector(state => state.currentUser);
    const searchValue = useSelector(state => state.search);
    const [currentCompany, setCurrentCompany] = useState({});
    const [noteDocuments, setNoteDocuments] = useState([]);
    // const [searchedText, setSearchedText] = useState("");
    const [projectId, setProjectId] = useState();
    const [noteId, setNoteId] = useState("");
    const [placeholder, setPlaceholder] = useState("");
    const [toggleModal, setToggleModal] = useState(false);
    const alert = toast;
    let currentUrl = window.location.href;

    const loadData = async () => {
        let projectDetails = await getCurrentCompany();
        setCurrentCompany(projectDetails);
        setProjectId(projectDetails.id);
        await fetchAllNoteDocuments(projectDetails.id)
    };


    const fetchAllNoteDocuments = async (Id) => {
        let response = await getAllNotesDocuments(Id, token);

        if (response.data.data && response.data.data.length > 0) {
            let checkListData = response.data.data.map((report, index) => {
                report.key = index + 1;
                report.action = null;
                return report;
            });
            setNoteDocuments(checkListData);
        } else {
            setNoteDocuments([]);
            // alert.error("No data found!");
        }
    };


    const getAllUploadedResources = async (id) => {
        let response = await getAllUploadedNotesDocuments(token, id, searchValue.value !== '' ? searchValue.value : '');
        if (response.data.data && response.data.data.length > 0) {
            setNoteDocuments(response.data.data);
        } else {
            setNoteDocuments([]);
        }
    };

    const filterMyNotesDocument = async () => {
        await getAllUploadedResources(currentCompany.id);
    }

    const changeProjectName = async (name) => {
        let response = await updateProjectDocumentName(noteId, {name: name}, token);
        console.log("function called", response);
        if (response.data.success) {
            await getAllUploadedResources(currentCompany.id);
            document.getElementById('editClose').click();
             alert.success(response.data.message);
        } else {
             alert.error(response.data.errors);
        }
    };

    const handleOnDelete = async () => {
        try {
            let response = await deleteNote(noteId, token);
            if (response.data.success) {
                await getAllUploadedResources(currentCompany.id);
                document.getElementById('cancelDelete').click();
                alert.success(response.data.message);
            } else {
                 alert.error(response.data.errors);
            }
        } catch (e) {
            console.log(e);
        } finally {

        }
    };

    function tabClose() {
        let nameFile = document.getElementById("nameFile")
        let fileInput = document.getElementById("filesInput")
        let urlInput = document.getElementById("url")
        let urlNameInput = document.getElementById("urlNameInput")
        if (nameFile){
            nameFile.value = ''
        }
        if (fileInput){
            console.log(fileInput.value,"file value")
            fileInput.value = ''
        }
        if (urlInput){
            urlInput.value = ''
        }
        if (urlNameInput){
            urlNameInput.value = ''
        }
        setToggleModal(!toggleModal);
        document.getElementById('close_upload_modal').click();

    }




    useEffect(() => {
        if (projectId && projectId != null) {
            filterMyNotesDocument();
        }
    }, [projectId,searchValue]); // taking another useEffect for searching. no need to recall loadData again.

    useEffect(() => {
        loadData();
    },[] );


    function cardNote(row, index) {
        return (<div className='col-3 p-2' key={index}>
            <div className='shadow-sm rounded-4 p-0' style={{backgroundColor: "#f6f6f6", border: "1px solid #eeeeee"}}>
                <img className='w-100' style={{height: "6.3rem"}}  alt={'img'} src="./assets/images/pdfPreview.png"
                     onClick={() => {
                         history.push(`/notes/view/${row.id}`)
                     }}/>
                <div className='d-flex  rounded-1 align-items-center' style={{backgroundColor: "#f6f6f6"}}>

                    <img src="./assets/images/ageisCompany.png" className="rounded-circle m-1"
                         style={{width: "2.5rem"}}
                         alt="Avatar" onClick={() => {
                        history.push(`/notes/view/${row.id}`)
                    }}/>
                    <div className='d-flex justify-content-between w-100'>
                        <div className='d-flex flex-column justify-content-center'
                             onClick={() => {
                                 history.push(`/notes/view/${row.id}`)
                             }}
                             style={{whiteSpace: 'nowrap', overflow: 'hidden', alignSelf: 'center'}}>
                                                <span className='fs-7 fw-semibold text-capitalize' style={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                }}>{row.name}</span>
                            <span
                                className='fs-7 text-capitalize'>pdf - uploaded 1 month ago</span>
                        </div>

                        {/* Menu Buttons Edit Name and Delete*/}
                        <div className="btn-group dropdown align-self-center cursor-pointer">
                                          <span data-bs-toggle="dropdown" aria-expanded="false">
                                        <img className=' p-1 pe-2 ' alt={'img'} src="./assets/icons/threeDots.svg"/>
                                          </span>
                            <ul className="dropdown-menu cursor-pointer">
                                <li className='dropdownButton'>
                                    <span className="dropdown-item"
                                          data-bs-toggle='modal' data-bs-target='#editName'
                                          onClick={() => {
                                              setNoteId(row.id);
                                              setPlaceholder(row.name);
                                          }}><i className='ri-edit-line align-self-center'/> &nbsp;Edit Name
                                    </span>
                                </li>
                                <li className='dropdownButton '>
                                    <span className="dropdown-item"
                                          data-bs-toggle='modal' data-bs-target='#deleteProject'
                                          onClick={() => {
                                              setNoteId(row.id);
                                          }}><MdOutlineDelete/>&nbsp;Delete
                                    </span>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>

            </div>
        </div>)


    }

    function gotoWorkArea() {
        history.push('/project/work-area');
    }

    // function gotoNotesView() {
    //     history.push(`/notes/view/${noteId}`);
    // }

    return (
        <>
            <div className='m-4 px-4 py-2 bg-white rounded-4 shadow-sm fontstyle '>
                <div className='d-flex pb-2'>
                    <button className='btn bg-white ri-add-line text-primary '
                            data-bs-toggle='modal' data-bs-target='#uploadDoc'
                            style={{
                                borderBottom: "2px solid #D76D00",
                                borderRadius: "12px",
                                boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.13)"
                            }}
                    ></button>
                    <div className='w-100 d-flex flex-row justify-content-between'>
                        <span className='fw-semibold p-2 text-center ms-1'>Work Area</span>
                        {
                            (currentUrl.includes('work-area')) ?
                                <></> : <span className="align-self-center">
                                    <Link to='#' onClick={gotoWorkArea}
                                          style={{textDecoration: 'none'}}>
                                <span className='text-primary' style={{ fontSize: '13px'}}>see more</span>
                            </Link></span>
                        }
                    </div>
                </div>

                <div className='row mt-0  gx-3'>
                    {
                        (noteDocuments && noteDocuments.length > 0) ?
                            (currentUrl.includes('work-area'))
                                ? (noteDocuments.map((row, index) => cardNote(row, index)
                                )) : (noteDocuments.slice(0, 4).map((row, index) => cardNote(row, index)
                                )) : <>no data found</>
                    }

                </div>
            </div>


            {/* Upload New Doccument Report*/}
            <div className="modal fade" id="uploadDoc" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <p className='fw-semibold p-2'>Upload your document</p>
                                <div className='outline none p-2' id='close_upload_modal' data-bs-dismiss="modal"
                                     type='button' onClick={() => {
                                    tabClose();

                                }}><GrClose/></div>
                            </div>

                            {/* Tab Panel for Upload doccument from component*/}
                            <UploadNotesDocument data={toggleModal} tabClose={tabClose}/>

                        </div>

                    </div>
                </div>
            </div>


            {/* Edit Project Name*/}
            <div className="modal fade" id="editName" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <p className='fw-semibold'>Edit Project Name</p>
                                <div className='outline none' data-bs-dismiss="modal" type='button'
                                     id='editClose'
                                     onClick={() => {

                                }}><GrClose/></div>
                            </div>

                            <div className='p-2'>
                                <input type='text' className=' w-100 outline-none rounded-2'
                                       value={placeholder}
                                       onChange={(e) => {
                                           setPlaceholder(e.target.value)
                                       }}
                                       style={{
                                           border: '0.5px solid #ebebeb',
                                           backgroundColor: '#fafafa',
                                           height: "3rem"
                                       }}></input>
                            </div>
                            <div className='w-100 d-flex justify-content-center p-2'>

                                <button className='btn buttonOrangeFill'
                                        style={{width: '8.5rem'}}
                                        onClick={() => {
                                            changeProjectName(placeholder);
                                        }}> Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Delete Project Sure Modal*/}
            <div className="modal fade" id="deleteProject" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div style={{borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}>
                                <div className='d-flex justify-content-center'>
                                    <img className='m-2' style={{width: "10rem"}}
                                         src='./assets/icons/84228-delete-animation.gif' alt={'img'}/>
                                </div>
                                <p className='justify-content-center d-flex'
                                   style={{fontSize: "18px", fontWeight: '600'}}>Do you want to DELETE this
                                    Report?</p>
                                <p className='justify-content-center d-flex fw-semibold pb-4'
                                   style={{fontSize: '14px'}}>Are you sure ?&nbsp; <span className='fw-normal'> your 'Report' will be deleted permanently.</span>
                                </p>
                                <div className='d-flex'>
                                    <button className='w-50 border-0' data-bs-dismiss="modal"
                                            id='cancelDelete'
                                            style={{
                                                height: '56px',
                                                color: '#ff0000',
                                                backgroundColor: '#fff8f8',
                                                borderBottomLeftRadius: '6px'
                                            }}
                                            onClick={() => {
                                                setNoteId(null);
                                            }}>CANCEL
                                    </button>
                                    <button className='w-50 border-0'
                                            style={{
                                                height: '56px',
                                                color: '#ffffff',
                                                backgroundColor: '#d76d00',
                                                borderBottomRightRadius: '6px'
                                            }}
                                            onClick={() => {
                                                handleOnDelete();
                                            }}>DELETE
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default NotesListView;