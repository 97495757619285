import React, {useEffect, useState} from "react";
import "../../../../src/styles/main.css"
import {AiOutlineCalendar} from "react-icons/ai";
import {BiHash} from "react-icons/bi";
import "../hoverMenu.css"
import {
    getAllCompanies,
    getAllUserProjects,
    getProjectById,
    updateProjectDescription,
    updateProjectName, updateProjectPrivacy
} from "../../../actions/Projects";
import {useSelector, useDispatch} from "react-redux";
import truncateString from "../../../utills/reusableFunction";
import moment from 'moment';
import {GrClose} from "react-icons/gr";
import {Link, useHistory} from "react-router-dom";
import {setCompanySession} from "../../../utills/Common";
import "bootstrap/dist/js/bootstrap.bundle.min";
import {toast, Toaster} from "react-hot-toast";
import {getLocalToken, getLocalUser} from "../../../utils/storage";
import {strConvert} from "../../../utills/reusableFunction";

let MyProject = () => {
    const token = getLocalToken();
    const user = getLocalUser();
    const currentUser = useSelector(state => state.currentUser);
    const searchValue = useSelector(state => state.search);
    const refreshToggle = useSelector(state => state.refreshToggle);

    const [options, setOptions] = useState([]);
    const [myProjects, setMyProjects] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [projectId, setProjectId] = useState();
    const [privacyValue, setPrivacyValue] = useState();
    const [isReload, setReload] = useState(false);
    const [placeholderDisc, setPlaceholderDisc] = useState('');
    const [placeholderName, setPlaceholderName] = useState('');
    // const [placeholder, setPlaceholder] = useState({name:'',description:''});

    const History = useHistory();
    const alert = toast;


    const getAllProjects = async () => {

        if (user && token) {
            let response = await getAllUserProjects(token, searchValue.value != "" ? searchValue.value : ''); //checking for input value empty or not
            if (response.data  && response.data.data && response.data.data.length > 0) {
                setMyProjects(response.data.data);
            } else {
                setMyProjects([]);
            }
        }
    };


    const getSingleProject = async (id) => {
        let response = await getProjectById(id, token);

        if (response.data.success && response.data.data) {
            setCompanySession(response.data.data);
            History.push("/project/dashboard/" + id);
        } else {
            alert.success(response.data.message);
        }
    };

    const updateProject = async (name) => {

        let response = await updateProjectName(projectId, {
            company_id: companyId,
            name: name
        }, token);
        if (response.data.success) {
            setReload(!isReload);
            document.getElementById("modalClose").click();
            alert.success(response.data.message);
        }


    };


    const updateDescription = async (description) => {

        let response = await updateProjectDescription(projectId, {description: description }, token);

        if (response.data.success) {
            setPlaceholderDisc('');
            setReload(!isReload);
        }
    };

    function renderPrivacy(projectVisibility) {
        if (projectVisibility === 0) {
            return <i className="ri-lock-2-line"/>
        } else if (projectVisibility === 1) {
            return <i className='ri-building-line'/>
        } else if (projectVisibility === 2) {
            return <i className='ri-group-line'/>
        }
    }

    const changeProjectPrivacy = async (id, visibility) => {
        let response = await updateProjectPrivacy(id, {
            visibility: visibility
        }, token);
        if (response.data.success) {
            setPrivacyValue(null);
            setProjectId(null);
            setReload(!isReload);
            document.getElementById("privacyClose").click();
            alert.success('"Project privacy changed successfully !"');
        }

    };


    useEffect(() => {
        getAllProjects();
    }, [isReload, searchValue, refreshToggle]);


    return (
        <>


            <div className='container-fluid mt-3'>
                <div className='px-1 row row-cols-3 g-3 '>

                    {(myProjects && myProjects.length > 0) ? <>
                        {(myProjects.map((project, index) =>

                            <div className='col bottom-bar-hover' key={index}>
                                <Link className='text-decoration-none text-dark' to={'/#'}
                                      onClick={() => getSingleProject(project.id)}>
                                    <div
                                        className='card-border rounded-4 p-4 bg-white card-shadow d-flex flex-column justify-content-between'
                                        style={{height: "calc(100% - 44px)"}}>
                                        <div className='d-flex flex-column'
                                             style={{whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                        <span className='fw-semibold fs-5 text-capitalize' style={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                        }}>{project.name}</span>
                                            <p className='text-black-50 mb-3 text-capitalize' style={{
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                            }}>{project.user.name}</p>
                                            <div
                                                className='d-flex align-items-center bg-light bg-gradient p-2 rounded mb-3'>
                                                <img className='rounded me-3 ms-2'
                                                     style={{width: "30px", height: "30px"}}
                                                     src='./assets/images/Zebralogo.png'/>
                                                <div className='d-flex flex-column'
                                                     style={{whiteSpace: 'nowrap', overflow: 'hidden'}}>
                                                <span className='fs-7 fw-semibold text-capitalize' style={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                }}>{project.company.name}</span>
                                                    <span
                                                        className='fs-7 text-capitalize'>{project.company.industry.name}</span>
                                                </div>
                                            </div>

                                        </div>
                                        <div>
                                            <p className='bg-black-70 mb-3 text-capitalize'>{project.description }</p>
                                            <div className='d-flex align-items-center mb-2'>
                                                <svg className='me-2' stroke="currentColor" fill="currentColor"
                                                     strokeWidth="0" viewBox="0 0 1024 1024" height="20px" width="20px"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z">
                                                    </path>
                                                </svg>
                                                <span>{moment(new Date(project.createdAt)).format("MMMM D, YYYY")}</span>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='d-flex align-items-center'>
                                                    <span className="fs-2 mx-1">#</span>
                                                    <span
                                                        className="badge mx-1 bg-success fs-6 py-2 bg-opacity-25 text-success fw-normal text-capitalize">{strConvert(project.company.industry.name)[0]}</span>
                                                    <span
                                                        className="badge mx-1 bg-primary fs-6 py-2 bg-opacity-25 text-primary fw-normal text-capitalize">{strConvert(project.company.industry.name)[1]}</span>
                                                </div>
                                                <div>
                                                    <div className='position-relative d-flex align-items-center'
                                                         style={{marginRight: '30px', width: '40px'}}>
                                                        <img
                                                            className='rounded-circle border border-white border-4 rounded-circle '
                                                            style={{width: '40px'}}
                                                            src='./assets/images/Zebralogo.png'/>
                                                        <img
                                                            className='rounded-circle border border-white border-4 rounded-circle position-absolute start-50'
                                                            style={{width: '40px'}}
                                                            src='./assets/images/profile person photo.png'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                {/*Hove Menu Option Buttons*/}
                                <div
                                    className='bottom-bar d-flex align-items-center justify-content-between card-border border-top-0 mx-5 px-3 py-2'
                                    style={{
                                        borderRadius: '0px 0px 1rem 1rem',
                                        backgroundColor: 'rgba(252, 252, 255, 1)'
                                    }}>
                                    <div className='d-flex'>

                                        {/*edit project name and discription*/}

                                        <svg data-bs-toggle="modal"  data-bs-target="#editNameDescription"
                                             onClick={() => {
                                                 setProjectId(project.id);
                                                 setPlaceholderName(project.name);
                                                 setPlaceholderDisc(project.description);
                                             }}
                                             className='me-4 align-self-center cursor-pointer' width="15" height="16"
                                             viewBox="0 0 15 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M3.34523 10.9478L10.9517 3.34132L9.89123 2.28082L2.28473 9.88732V10.9478H3.34523ZM3.96698 12.4478H0.784729V9.26557L9.36098 0.689317C9.50163 0.548713 9.69236 0.469727 9.89123 0.469727C10.0901 0.469727 10.2808 0.548713 10.4215 0.689317L12.5432 2.81107C12.6838 2.95171 12.7628 3.14244 12.7628 3.34132C12.7628 3.54019 12.6838 3.73092 12.5432 3.87157L3.96698 12.4478V12.4478ZM0.784729 13.9478H14.2847V15.4478H0.784729V13.9478Z"
                                                fill="#323232"/>
                                        </svg>

                                        {/*change project privacy Menubutton*/}
                                        <div className="dropdown dropup">
                                                <span className="cursor-pointer"
                                                      id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                      aria-expanded="false">
                                                    {renderPrivacy(project.visibility)}
                                                </span>
                                            <ul className="dropdown-menu cursor-pointer"
                                                aria-labelledby="dropdownMenuButton1">

                                                {
                                                    project.visibility !== 0 ?
                                                        <li className='dropdownButton' data-bs-toggle="modal"
                                                            data-bs-target="#changePrivacy">
                                                            <p className="dropdown-item fw-semibold m-0"
                                                               onClick={() => {
                                                                   setPrivacyValue(0);
                                                                   setProjectId(project.id);
                                                               }}><i className="ri-lock-2-line"/>&nbsp; Private
                                                            </p>
                                                        </li> : ""
                                                }
                                                {
                                                    project.visibility !== 1 ?
                                                        <li className='dropdownButton' data-bs-toggle="modal"
                                                            data-bs-target="#changePrivacy">
                                                            <p className="dropdown-item fw-semibold m-0"
                                                               onClick={() => {
                                                                   setPrivacyValue(1);
                                                                   setProjectId(project.id);
                                                               }}><i className='ri-building-line'/>&nbsp;Company
                                                            </p>
                                                        </li> : ""
                                                }
                                                {
                                                    project.visibility !== 2 ?
                                                        <li className='dropdownButton' data-bs-toggle="modal"
                                                            data-bs-target="#changePrivacy">
                                                            <p className="dropdown-item fw-semibold m-0"
                                                               onClick={() => {
                                                                   setPrivacyValue(2);
                                                                   setProjectId(project.id);
                                                               }}><i className='ri-group-line'/>&nbsp;Public
                                                            </p>
                                                        </li> : ""
                                                }

                                            </ul>
                                        </div>

                                    </div>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg" onClick={() => {
                                        alert.error('Delete option is disabled');
                                    }}>
                                        <path
                                            d="M4.57263 1.94781V0.447815H12.0726V1.94781H15.8226V3.44781H14.3226V14.6978C14.3226 14.8967 14.2436 15.0875 14.103 15.2281C13.9623 15.3688 13.7715 15.4478 13.5726 15.4478H3.07263C2.87372 15.4478 2.68295 15.3688 2.5423 15.2281C2.40165 15.0875 2.32263 14.8967 2.32263 14.6978V3.44781H0.822632V1.94781H4.57263ZM3.82263 3.44781V13.9478H12.8226V3.44781H3.82263ZM6.07263 5.69781H7.57263V11.6978H6.07263V5.69781ZM9.07263 5.69781H10.5726V11.6978H9.07263V5.69781Z"
                                            fill="#323232"/>
                                    </svg>

                                </div>
                            </div>
                        ))}

                    </> : <></>}


                    {/* dev end */}


                </div>
            </div>

            {/* Edit Project Name And Description*/}
            <div className="modal fade " id="editNameDescription" data-bs-backdrop="static" data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <input className='w-100 border-0 fw-semibold outline-none' type='text'
                                       value={placeholderName}
                                       onChange={(e) => {
                                           setPlaceholderName(e.target.value);
                                       }}/>
                                <div className='outline none' data-bs-dismiss="modal" id='modalClose' type='button'
                                     onClick={() => {
                                         setPlaceholderName('');
                                         setPlaceholderDisc('');
                                     }}><GrClose/></div>
                            </div>

                            <div className='p-2'>
                                <textarea className=' w-100 outline-none rounded-2'
                                          value={placeholderDisc}
                                          onChange={(e) => {
                                              setPlaceholderDisc(e.target.value);
                                          }}
                                          style={{
                                              border: '0.5px solid #ebebeb',
                                              backgroundColor: '#fafafa',
                                              height: '7rem'
                                          }}>
                                </textarea>
                            </div>
                            <div className='w-100 d-flex justify-content-between p-2'>
                                <div>
                                    <BiHash/>
                                    <span className="badge bg-primary ms-2">Primary</span>
                                    <span className="badge bg-success ms-2">Success</span>
                                </div>
                                <button className='btn buttonOrangeFill'
                                        style={{width: '8.5rem'}}
                                        onClick={async () => {
                                            await updateProject(placeholderName);
                                            await updateDescription(placeholderDisc);
                                        }}> Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/*Change Project Privacy Modal*/}
            <div className="modal fade" id="changePrivacy" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">

                        <div className="modal-body p-0">
                            <div style={{borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}>
                                <div className='d-flex justify-content-center'>
                                    <img className='m-2' style={{width: '10rem'}} src='./assets/icons/94138-lock.gif'/>
                                </div>
                                <p className='justify-content-center d-flex'
                                   style={{fontSize: "18px", fontWeight: '600'}}>Do you want to change project
                                    privacy?</p>
                                <p className='justify-content-center d-flex fw-semibold pb-4'
                                   style={{fontSize: '14px'}}>Are you sure ?&nbsp; <span className='fw-normal'> your privacy will be changed.</span>
                                </p>
                                <div className='d-flex'>
                                    <button className='w-50 border-0' id="close-btn-pch" data-bs-dismiss="modal"
                                            id='privacyClose'
                                            style={{
                                                height: '56px',
                                                color: '#ff0000',
                                                backgroundColor: '#fff8f8',
                                                borderBottomLeftRadius: '6px'
                                            }}
                                            onClick={() => {
                                                setProjectId(null);
                                                setPrivacyValue(null);
                                            }}>Disagree
                                    </button>
                                    <button className='w-50 border-0'
                                            style={{
                                                height: '56px',
                                                color: '#ffffff',
                                                backgroundColor: '#d76d00',
                                                borderBottomRightRadius: '6px'
                                            }}
                                            onClick={() => {
                                                changeProjectPrivacy(projectId, privacyValue);
                                            }}>Agree
                                    </button>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default MyProject;