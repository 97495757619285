import React, {useEffect, useState} from "react";
import {BsArrowLeft} from "react-icons/bs";
import 'remixicon/fonts/remixicon.css'
import "./header.css";
import {useDispatch, useSelector} from "react-redux";
import search from "../../Redux/Action/search";


let Header = () => {
    let currentUrl = window.location.href;
    const [title, setTitle] = useState({name: '', sub: ''})
    const dispatch = useDispatch();
    const searchValue = useSelector(state => state.search);

    const checkHeader = () => {
        if (currentUrl.includes('my-project')) {
            setTitle({
                name: 'Private Project',
                sub: 'Projects private to you, which cannot be accessed by anyone else!'
            })
        } else if (currentUrl.includes('company-project')) {
            setTitle({name: 'Company Project', sub: ' Projects seen by you and your company members'});
        } else if (currentUrl.includes('public-projects')) {
            setTitle({name: 'Public Project', sub: 'Projects that can be be seen by anyone.'});
        }
    };

    const handleChange = (e) => {
        dispatch(search.setSearch(e.target.value ))
    }

    useEffect(() => {
        checkHeader();
    }, [currentUrl]);

    return (
        <>

            <div className='d-flex justify-content-between p-4 align-items-center border-bottom'
                 style={{height: '100px'}}>
                <div className='d-flex align-items-center'>
                    {/*<button*/}
                    {/*    className='btn-hover-icon-white btn btn-outline-dark px-4 py-2 me-4 flex-nowrap d-flex align-items-center me-4'*/}
                    {/*    style={{borderRadius: '500px'}}>*/}
                    {/*    <svg width="16" height="8" viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg">*/}
                    {/*        <path*/}
                    {/*            d="M14.696 4.79053C14.9722 4.79053 15.196 4.56667 15.196 4.29053C15.196 4.01438 14.9722 3.79053 14.696 3.79053L14.696 4.79053ZM0.160137 3.93698C-0.0351248 4.13224 -0.0351248 4.44882 0.160137 4.64408L3.34212 7.82606C3.53738 8.02132 3.85396 8.02132 4.04923 7.82606C4.24449 7.6308 4.24449 7.31422 4.04923 7.11896L1.2208 4.29053L4.04922 1.4621C4.24449 1.26684 4.24449 0.950257 4.04922 0.754994C3.85396 0.559732 3.53738 0.559732 3.34212 0.754994L0.160137 3.93698ZM14.696 3.79053L0.513691 3.79053L0.513691 4.79053L14.696 4.79053L14.696 3.79053Z"*/}
                    {/*        />*/}
                    {/*    </svg>*/}

                    {/*    <span>Back</span>*/}
                    {/*</button>*/}
                    <div>
                        <h1 className='fs-5 fw-semibold mb-1 text-capitalize'>{title.name}</h1>
                        <span>{title.sub}</span>
                    </div>
                </div>

                <div className='border rounded py-2 d-flex' style={{width: "375px"}}>
                    <img className='px-3' type='button' src='./assets/icons/search glass icon.svg'/>
                    <input className='border-0 outline-none py-1 w-100'  placeholder='Search Project Name' id='myInput'
                           onChange={handleChange}
                    />
                    {/*ri-delete-back-2-line*/}
                    <img className=" align-self-center pe-2" type='reset'  src="assets/icons/delete.svg"
                       onClick={() => {
                           document.getElementById('myInput').value = '';
                           dispatch(search.setSearch(''));
                       }}/>
                </div>

            </div>
        </>
    );
}

export default Header;