import AdminRoutes from "../../route-lists/AdminRoutes";
import {NavLink, useHistory} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {getLocalToken, getLocalUser} from "../../utils/storage";
import {sidebarWidthControl} from "../../Redux/Action";
import {getCurrentCompany, removeUserSession} from "../../utills/Common";
import {useDispatch, useSelector} from "react-redux";
import {MdArrowDropDown} from "react-icons/md";
import {createNewCheckListTopic, getChecklistSections, getChecklistTopics} from "../../actions/CheckList";
import checklistActions from "../../Redux/Action/checklist";


let ChecklistSidebar = () => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const currentUrl = window.location.href;
    const sidebarToggle = useSelector((state => state.changeTheSidebar));
    const [toggle, setToggle] = useState(false);
    const [isActive, setIsActive] = useState();

    const dispatch = useDispatch();
    const history = useHistory();

    const [isReload, setReload] = useState(false);

    const currentUser = useSelector(state => state.currentUser);
    const rChecklist = useSelector(state => state.checklist);

    const [projectDetails, setProjectDetails] = useState({});
    const [checklistTopics, setChecklistTopics] = useState([]);
    const [checklistId, setChecklistId] = useState([]);
    const [checklistSections, setChecklistSections] = useState([]);
    const [formValue, setFormValue] = useState({});

    useEffect(() => {

        loadData();
            console.log('hello moto', rChecklist);
            if (rChecklist && rChecklist.rCheckListID) {
                fetchAllChecklistSections(rChecklist.rCheckListID);
            }


    }, [isReload, rChecklist.rCheckListID]);


    const loadData = async () => {
        let projectDetails = await getCurrentCompany();
        setProjectDetails(projectDetails);
        await fetchAllChecklistTopics(projectDetails.id);
    };

    const fetchAllChecklistTopics = async (projectId) => {
        let response = await getChecklistTopics(projectId, token);
        if (response.data.data.length > 0 && response.data.data) {
            let checkListTopicsData = response.data.data.map((report, index) => {
                report.key = index + 1;
                report.action = null;
                return report;
            });
            setChecklistTopics(checkListTopicsData);
            dispatch(checklistActions.rSetChecklistId(0));
        } else {
            setChecklistTopics([]);
        }
    };

    const fetchAllChecklistSections = async (checklistId) => {
        try {
            console.log(checklistId, 'section');
            if (checklistId !== 0) {
                let response = await getChecklistSections(checklistId, token);
                if (response && response.data.data && response.data.data.length > 0) {
                    let list = response.data.data.map((report, index) => {
                        report.key = index + 1;
                        report.action = null;
                        return report;
                    });
                    setChecklistSections(list);

                } else {
                    setChecklistSections([]);
                }
            }
            return [];
        } catch (e) {
            setChecklistTopics([]);
            console.log(e)
        }
    };

    const handleOnSubmit = async () => {
        if (formValue.name) {
            let response = await createNewCheckListTopic(projectDetails.id, formValue, token);

            if (response.data.success && response.data.data) {
                await fetchAllChecklistTopics(projectDetails.id);

                alert.success(response.data.message);
                history.push(`/check-list/view/${response.data.data.id}`)
                setChecklistSections([]);
            } else {
                alert.error(response.data.errors);
            }
        } else {
            alert.error("Name is required");
        }
    };


    /*   const ChecklistSelect = useMemo(() => {
           return (
               <Select onChange={(e) => {
                   history.push(`/check-list/view/${e.target.value}`)
                   setChecklistSections([]);
               }}>
                   {
                       (checklistTopics && checklistTopics.length > 0)
                           ? (checklistTopics.map((row, index) =>
                               <option key={row.key} value={row.id}>
                                   {row.name}
                               </option>
                           ))
                           : ""
                   }
               </Select>
           )
       }, [checklistTopics]);*/


    //    Alert
    const [alertProp, setAlertProp] = useState({
        display: "d-none",
        status: "success",
        message: "Added Successfully",
        icon: <></>
    })

    const History = useHistory();

    const onSidebarWidthChange = () => {
        dispatch(sidebarWidthControl())
    }

    const logout = async () => {
        await removeUserSession();
        History.push("/login");
    };

    const row = 2;
    const index = 2;
    return (
        <>

            <div
                className='overflow-hidden d-flex flex-column justify-content-between bg-secondary border-end vh-100 px-3 py-4'
                style={{
                    width: sidebarToggle === true ? '230px' : '85px',
                    transition: 'width 0.2s ease 0s',
                    whiteSpace: 'nowrap'
                }}>

                <div>
                    <div
                        className={`d-flex ${`fw-semibold ${sidebarToggle ? 'justify-content-between' : 'justify-content-center'}`} align-items-center mb-4 pb-2`}>
                        <div><img className='me-2' src='./assets/icons/zebrapro logo /Zebralogo.svg'/><img
                            className={`${(sidebarToggle === true) ? '' : 'd-none'}`}
                            src='./assets/icons/zebrapro logo /zebrapro logo name.svg'/></div>
                        <img className='shadow-sm rounded position-absolute' onClick={onSidebarWidthChange}
                             type='button'
                             style={{
                                 transform: (sidebarToggle == true) ? 'rotate(0deg)' : 'rotate(180deg)',
                                 marginRight: (sidebarToggle == true) ? '' : '-40px',
                                 left: (sidebarToggle == true) ? '217px' : '72px',
                                 transition: ' 0.2s ease 0s'
                             }}
                             src='./assets/icons/sidebar side controller.svg'/>
                    </div>
              {/*      <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" style={{fontSize: '0.9rem'}}
                            className='btn btn-outline-primary mb-4 fw-semibold w-100 overflow-hidden'>+ {(sidebarToggle === true) ? 'Create checklist topic' : ''}</button>*/}


                    <button className='btn fw-semibold text-primary d-flex justify-content-between w-100 border-0'
                            onClick={() => {
                                setToggle(!toggle)
                            }}>
                        <div className='d-flex'>
                            <span
                                className={`ri-list-check-2 ${(sidebarToggle === true) ? '' : 'justify-content-center'}`}/>
                            <p className={`${(sidebarToggle === true) ? 'd-block' : 'd-none'} m-0`}>&nbsp;Sections</p>
                        </div>
                        <span className={`text-primary align-self-end`}
                              style={{transform: `${toggle === true ? '' : 'rotate(-90deg)'}`}}><MdArrowDropDown/></span>
                    </button>
                    <div className={`${toggle === true ? '' : 'd-none'} `} id="noteListsDiv"
                         style={{maxHeight: '20rem', overflowY: 'scroll', overflowX: 'hidden', scrollbarWidth: '0px'}}>

                        {(checklistSections && checklistSections.length > 0) ?
                            (checklistSections.map((row, index) => {
                                return (
                                    <span className='text-decoration-none cursor-pointer ' key={index}>
                                        <li className={`${(sidebarToggle === true) ? '' : 'justify-content-center text-align-center'} ${isActive == row.id ? 'text-primary' : 'text-dark'} d-flex px-2 py-1 my-1`}
                                            style={{
                                                listStyle: 'none',
                                                fontSize: '12px',
                                                backgroundColor: `${isActive == row.id ? '#ffedd2' : ''}`
                                            }}
                                            onClick={() => {
                                                setIsActive(row.id);
                                                dispatch(checklistActions.rSetChecklistSectionId(index));
                                            }}>
                                            <span className={``}>#</span>
                                            <span className={`${(sidebarToggle === true) ? 'd-block' : 'd-none'}`}
                                                  style={{textOverflow: 'ellipsis'}}> &nbsp;{row.section}</span>
                                        </li>
                                    </span>
                                )
                            })) : ''}

                    </div>


                    <ul className='list-unstyled d-grid gap-3 mt-3 '>
                        {
                            AdminRoutes.admin.map((i, index) => {
                                if (index > 2) {
                                    return (
                                        <NavLink key={index} to={`${i.layout}${i.path}`} activeClassName="active"
                                                 className={`${(sidebarToggle === true) ? '' : 'justify-content-center'}  d-flex align-items-center text-decoration-none text-black`}>{i.icon}
                                            <span
                                                className={`fw-semibold ${(sidebarToggle === true) ? 'd-block ps-1' : 'd-none'}`}>{i.name}</span>
                                        </NavLink>
                                    )
                                }
                            })
                        }

                    </ul>

                </div>
                <div>
                    <div className='d-flex p-2 rounded-2 mb-2'
                         style={{backgroundColor: sidebarToggle === true ? '#FFECD1' : ''}}>
                        <div className='position-relative d-flex align-items-center'
                             style={{marginRight: (sidebarToggle == true) ? '30px' : '0',}}>
                            <img className='rounded-circle border border-white border-4 rounded-circle '
                                 style={{width: (sidebarToggle == true) ? '40px' : '25px'}}
                                 src='./assets/images/Zebralogo.png'/>
                            <img
                                className='rounded-circle border border-white border-4 rounded-circle position-absolute start-50'
                                style={{width: (sidebarToggle == true) ? '40px' : '25px'}}
                                src='./assets/images/profile person photo.png'/>
                        </div>
                        <div className={`d-flex flex-column ${(sidebarToggle === true) ? 'd-block' : 'd-none'}`}
                             style={{whiteSpace: 'nowrap', overflow: 'hidden',}}>
                               <span className='cursor-pointer fs-6 text fw-semibold mb-0'
                                     style={{textOverflow: 'ellipsis', overflow: 'hidden',}}
                                     title={getLocalUser().name}>{getLocalUser().name}</span>
                            <span className='cursor-pointer fs-6'
                                  style={{textOverflow: 'ellipsis', overflow: 'hidden',}}
                                  title={getLocalUser().email}>{getLocalUser().email}</span>
                        </div>
                    </div>

                    <button className='logout-btn btn btn-outline-primary w-100 fw-semibold' onClick={logout}>
                        {sidebarToggle === true ? <span>Sign Out</span> :
                            <svg className='btn-hover-white' fill='#D76D00' xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 24 24" width="20"
                                 height="20">
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path
                                    d="M5 11h8v2H5v3l-5-4 5-4v3zm-1 7h2.708a8 8 0 1 0 0-12H4A9.985 9.985 0 0 1 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.985 9.985 0 0 1-8-4z"/>
                            </svg>
                        }
                    </button>
                </div>
            </div>
        </>
    )
}

export default ChecklistSidebar;