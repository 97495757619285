import {getNotesSpreadsheet, postNotesSpreadsheet} from "../../../actions/Notes/Notes";
import {getCurrentCompany} from "../../../utills/Common";
import querystring from "query-string";
import Spreadsheet, {createEmptyMatrix, CellComponent, CellComponentProps, Point} from "react-spreadsheet";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {getLocalToken, getLocalUser} from "../../../utils/storage";

const SheetsView = (props) => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const currentUser = useSelector(state => state.currentUser);
    const [data, setData] = useState(createEmptyMatrix(50, 26));
    const sheetRef = useRef();
    let documentId = props.documentId;
    const [currentCompany, setCurrentCompany] = useState({});
    const [isSaving, toggleSaving] = useState(false);

    useEffect(() => {
        getCurrentProjectDetails();
        fetchSheetData();
    }, [documentId]);


    const getCurrentProjectDetails = async () => {
        let companyDetails = await getCurrentCompany();
        setCurrentCompany(companyDetails);

    };

    const fetchSheetData = async () => {
        try {
            toggleSaving(true)
            const response = await getNotesSpreadsheet(documentId, token);

            if (response.data && response.data.success && response.data.data.body) {
                const body = response.data.data.body;
                let sheetData = [];
                for (const row of body) {
                    let myRow = [];
                    for (const cell of row) {
                        if (cell) {
                            myRow.push(cell)
                        } else {
                            myRow.push({value: ""})
                        }
                    }
                    sheetData.push(myRow)
                }

                setData(sheetData);

            } else {
                setData(createEmptyMatrix(50, 26))
            }
        } catch (e) {
            console.log(e)
        } finally {
            toggleSaving(false)
        }
    };

    const saveSheetData = async () => {
        toggleSaving(true);
        try {
            const response = await postNotesSpreadsheet(documentId,
                querystring.stringify({
                    content: JSON.stringify(data)
                }),
                token);
            if (response.success) {
            }
        } catch (e) {
            console.log(e)
        } finally {
            toggleSaving(false);
        }
    };
    return (
        <>
            <div className=''>
                <div className='d-flex bg-white sticky-top justify-content-between'>
                    <text className='p-2' fontSize='20'>Sheet View</text>
                    <div>
                        <button className='btn btn-primary px-3 m-1' onClick={saveSheetData}>Save</button>
                        <button className='btn btn-outline-primary px-3 m-1' isLoading={isSaving}>Download</button>
                    </div>
                </div>
                <div className='overflow-scroll w-100'
                     style={{width: 'calc(100vh - 10rem)', height: 'calc(100vh - 8rem)'}}>
                    <Spreadsheet id={"sheet-i"} data={data} onChange={setData}></Spreadsheet>
                </div>

            </div>
        </>
    )
};

export default SheetsView;