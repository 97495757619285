import AdminRoutes from "../../route-lists/AdminRoutes";
import {NavLink, useHistory, useLocation, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {getLocalToken, getLocalUser} from "../../utils/storage";
import {useDispatch, useSelector} from "react-redux";
import {sidebarWidthControl} from "../../Redux/Action";
import {getCurrentCompany, removeUserSession} from "../../utills/Common";
import {MdArrowDropDown} from "react-icons/md";
import {getAllNotesDocuments} from "../../actions/Notes/Notes";
import {createNewCheckListTopic} from "../../actions/CheckList";
import {GrClose} from "react-icons/gr";
import UploadNotesDocument from "../../views/notes/UploadNotesDocument";

let NotesSidebar = () => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    let url = window.location.href;
    let location = useLocation();
    const currentUser = useSelector(state => state.currentUser);
    const [currentProject, setCurrentProject] = useState({});
    const [noteDocumentsLists, setNoteDocumentLists] = useState([]);
    const [formValue, setFormValue] = useState({});
    const [currentCompany, setCurrentCompany] = useState({});
    let history = useHistory();
    const [reload, toggleReload] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [isActive, setIsActive] = useState(urlSplit(url));

    function urlSplit(url) {
        let id = ((url.split('/')).at(-1));
        return id;
    }

    const getCurrentProjectDetails = async () => {
        let companyDetails = await getCurrentCompany();
        setCurrentCompany(companyDetails);

    };

    const fetchNoteDocumentsLists = async (projectId) => {
        let response = await getAllNotesDocuments(projectId, token);

        if (response.data.success && response.data.data && response.data.data.length > 0) {
            setNoteDocumentLists(response.data.data);
        } else {
            setNoteDocumentLists([]);
            alert.error(response.data.message);
        }
    };

    const handleOnSubmit = async () => {
        if (formValue.name && formValue.extra_details) {
            let response = await createNewCheckListTopic(currentProject.id, formValue, token);

            if (response.data.success && response.data.data) {
                await fetchNoteDocumentsLists(currentProject.id);

                alert.success(response.data.message);
            } else {
                alert.error(response.data.message);
            }
        } else {
            if (!formValue.name) {
                alert.error("Name is required");
            } else if (!formValue.extra_details) {
                alert.error("Extra details is required");
            }
        }
    };

    useEffect(() => {

        let projectDetails = getCurrentCompany();
        setCurrentProject(projectDetails);
        fetchNoteDocumentsLists(projectDetails.id);
        getCurrentProjectDetails();

    }, [reload]);

    // Check Active Menu
    const getSelectedTopicId = () => {
        if (location.pathname.includes("/notes/view")) {
            return parseInt(location.pathname.replace("/notes/view/", ''));
        } else {
            return null;
        }
    };


    const sidebarToggle = useSelector((state => state.changeTheSidebar));
    const dispatch = useDispatch();
    //    Alert
    const [alertProp, setAlertProp] = useState({
        display: "d-none",
        status: "success",
        message: "Added Successfully",
        icon: <></>
    })

    const History = useHistory();
    const onSidebarWidthChange = () => {
        dispatch(sidebarWidthControl())
    }

    const logout = async () => {
        await removeUserSession();
        History.push("/login");
    };
    return (
        <>
            <div
                className={`alert alert-${alertProp.status} d-flex ${alertProp.display} align-items-center position-absolute m-4 end-0 top-0`}
                role="alert">

                {alertProp.icon}

                <div>
                    {alertProp.message}
                </div>
            </div>

            <div
                className='overflow-hidden d-flex flex-column justify-content-between bg-secondary border-end vh-100 px-3 py-4'
                style={{
                    width: sidebarToggle === true ? '230px' : '85px',
                    transition: 'width 0.2s ease 0s',
                    whiteSpace: 'nowrap'
                }}>

                <div>
                    <div
                        className={`d-flex ${`fw-semibold ${sidebarToggle ? 'justify-content-between' : 'justify-content-center'}`} align-items-center mb-2 pb-2`}>
                        <div><img className='me-2' src='./assets/icons/zebrapro logo /Zebralogo.svg'/><img
                            className={`${(sidebarToggle === true) ? '' : 'd-none'}`}
                            src='./assets/icons/zebrapro logo /zebrapro logo name.svg'/></div>
                        <img className='shadow-sm rounded position-absolute' onClick={onSidebarWidthChange}
                             type='button'
                             style={{
                                 transform: (sidebarToggle == true) ? 'rotate(0deg)' : 'rotate(180deg)',
                                 marginRight: (sidebarToggle == true) ? '' : '-40px',
                                 left: (sidebarToggle == true) ? '217px' : '72px',
                                 transition: ' 0.2s ease 0s'
                             }}
                             src='./assets/icons/sidebar side controller.svg'/>
                    </div>
                    <button data-bs-toggle='modal' data-bs-target='#uploadDoc'
                            className='btn btn-outline-primary mb-2 fw-semibold w-100 overflow-hidden'>+ {(sidebarToggle === true) ? 'Upload Document' : ''}</button>


                    <button className='btn fw-semibold text-primary d-flex justify-content-between w-100 border-0'
                            onClick={()=>{setToggle(!toggle)}}>
                        <div className='d-flex'>
                            <img className={`${(sidebarToggle === true) ? '' : 'justify-content-center'}`}
                                  src='/assets/icons/workAreaIcon/sidebarReport.svg'/>
                                  <text className={`${(sidebarToggle === true) ? 'd-block' : 'd-none'}`}>&nbsp;All report</text>
                        </div>
                        <span className={`text-primary align-self-end`} style={{transform:`${toggle===true?'':'rotate(-90deg)'}`}}><MdArrowDropDown/></span>
                    </button>
                    <div className={`${toggle===true?'':'d-none'} p-2 `} id="noteListsDiv" style={{maxHeight:'20rem', overflowY:'scroll',overflowX:'hidden', scrollbarWidth:'0px'}}>
                      {noteDocumentsLists.map((row,index)=>{
                          return(
                              <NavLink className='text-decoration-none' key={index} to={`/notes/view/${row.id}`}>
                              <li  className={`${(sidebarToggle === true) ? '' : 'justify-content-center text-align-center'} ${isActive==row.id?'text-primary':'text-dark'} d-flex px-2 py-1`}
                                  style={{listStyle:'none',fontSize:'12px',backgroundColor:`${isActive==row.id?'#ffedd2':''}`}}
                              onClick={()=>{
                                  setIsActive(row.id);
                              }}>
                                  <span className={``}>#</span>
                                  <text className={`${(sidebarToggle === true) ? 'd-block' : 'd-none'}`} style={{textOverflow:'ellipsis'}}> &nbsp;{row.name}</text>
                              </li>
                              </NavLink>
                          )
                      })}
                    </div>


                    <ul className='list-unstyled d-grid gap-3 mt-2'>
                        {
                            AdminRoutes.admin.map((i, index) => {
                                if (index > 2) {
                                    return (
                                        <NavLink to={`${i.layout}${i.path}`} activeClassName="active" key={index}
                                                 className={`${(sidebarToggle === true) ? '' : 'justify-content-center'}  d-flex align-items-center text-decoration-none text-black`}>{i.icon}
                                            <span
                                                className={`fw-semibold ${(sidebarToggle === true) ? 'ps-1 d-block' : 'd-none'}`}>{i.name}</span>
                                        </NavLink>
                                    )
                                }
                            })
                        }

                    </ul>

                </div>
                <div>
                    <div className='d-flex p-2 rounded-2 mb-2'
                         style={{backgroundColor: sidebarToggle === true ? '#FFECD1' : ''}}>
                        <div className='position-relative d-flex align-items-center'
                             style={{marginRight: (sidebarToggle == true) ? '30px' : '0',}}>
                            <img className='rounded-circle border border-white border-4 rounded-circle '
                                 style={{width: (sidebarToggle == true) ? '40px' : '25px'}}
                                 src='./assets/images/Zebralogo.png'/>
                            <img
                                className='rounded-circle border border-white border-4 rounded-circle position-absolute start-50'
                                style={{width: (sidebarToggle == true) ? '40px' : '25px'}}
                                src='./assets/images/profile person photo.png'/>
                        </div>
                        <div className={`d-flex flex-column ${(sidebarToggle === true) ? 'd-block' : 'd-none'}`}
                             style={{whiteSpace: 'nowrap', overflow: 'hidden',}}>
                               <span className='cursor-pointer fs-6 text fw-semibold mb-0'
                                     style={{textOverflow: 'ellipsis', overflow: 'hidden',}}
                                     title={getLocalUser().name}>{getLocalUser().name}</span>
                            <span className='cursor-pointer fs-6'
                                  style={{textOverflow: 'ellipsis', overflow: 'hidden',}}
                                  title={getLocalUser().email}>{getLocalUser().email}</span>
                        </div>
                    </div>

                    <button className='logout-btn btn btn-outline-primary w-100 fw-semibold' onClick={logout}>
                        {sidebarToggle === true ? <span>Sign Out</span> :
                            <svg className='btn-hover-white' fill='#D76D00' xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 24 24" width="20"
                                 height="20">
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path
                                    d="M5 11h8v2H5v3l-5-4 5-4v3zm-1 7h2.708a8 8 0 1 0 0-12H4A9.985 9.985 0 0 1 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.985 9.985 0 0 1-8-4z"/>
                            </svg>
                        }
                    </button>
                </div>
            </div>


            {/* Upload New Doccument Report*/}
            <div className="modal fade" id="uploadDoc" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <text className='fw-semibold'>Upload your document</text>
                                <div className='outline none' data-bs-dismiss="modal" type='button' onClick={() => {

                                }}><GrClose/></div>
                            </div>

                            {/*here including Tab Panel for Upload doccument from another component*/}
                            <UploadNotesDocument/>

                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default NotesSidebar;