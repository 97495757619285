const initialState = true;

const RefreshToggle = (state = initialState, action) => {

    switch (action.type) {
        case "REFRESH_TOGGLE":
            return !state;
        default:
            return state;
    }


};


export default RefreshToggle;