import {deleteRequest, getFileRequest, getRequest, postRequest} from '../../services/httpService';

/**
 * @description Get My All Resource Files By Project Id
 * @param token
 * @param projectId
 * @param searchedValue
 */
export const getAllDriveFiles = async(token, projectId, searchedValue = '') => {
    try {
        return await getRequest(`files-drive/${projectId}?name=${searchedValue}`, token);
    } catch (error) {
        return error;
    }
};


/**
 *
 * @param projectId
 * @param body
 * @param token
 * @returns {Promise<*|*>}
 */
export const uploadDriveFiles = async(projectId, body, token) => {
    try {
        return await postRequest(`files-drive/${projectId}`, body, token);
    } catch (error) {
        return error;
    }
};


/**
 *
 * @param fileId
 * @param token
 * @returns {Promise<*|*>}
 */
export const downloadDriveFileById = async(fileId, token) => {
    try {
        return await getFileRequest(`files-drive/download/${fileId}`, token);
    } catch (error) {
        return error;
    }
};


/**
 * @description Get My All Projects
 * @param projectId Project ID
 * @param token
 */
export const getAllDrives = async(projectId, token) => {
    try {
        return await getRequest(`files-drive/${projectId}`, token)
    } catch (error) {
        return error;
    }
};


/**
 *
 * @param filesId
 * @param token
 * @returns {Promise<*>}
 */
export const deleteDriveFile = async(filesId, token) => {
    try {
        return await deleteRequest(`files-drive/${filesId}`, token)
    } catch (error) {
        return error;
    }
};