import {deleteRequest, getFileRequest, getRequest, patchRequest, postRequest} from '../../services/httpService';

/**
 * @description Get My All Projects
 * @param projectId Project ID
 * @param token
 */
export const getAllNotesDocuments = async(projectId, token) => {
    try {
        return await getRequest(`notes/documents/${projectId}`, token);
    } catch (error) {
        return error;
    }
};

/**
 * @notes Get My All Notes By Project Id
 * @param token
 * @param projectId
 * @param searchedValue
 */
export const getAllUploadedNotesDocuments = async(token, projectId, searchedValue = '') => {
    try {
        let response = await getRequest(`notes/documents/${projectId}?name=${searchedValue}`, token);
        return response;
    } catch (error) {
        return error;
    }
};
/**
 *
 * @param projectId
 * @param body
 * @param token
 * @returns {Promise<unknown>}
 */
export const uploadNotesDocument = async(projectId,body, token) => {


    try {
        return await postRequest(`notes/documents/${projectId}`, body, token);
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param documentId
 * @param body
 * @param token
 * @returns {Promise<unknown>}
 */
export const updateProjectDocumentName = async(documentId,body, token) => {
    try {
        return await patchRequest(`notes/documents/${documentId}`, body, token);
    } catch (error) {
        return error;
    }
};

/**
 * Delete Note
 * @param id
 * @param token
 * @returns {Promise<*>}
 */
export const deleteNote = async(id, token) => {
    try {
        return await deleteRequest(`notes/documents/${id}`, token);
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param projectId
 * @param body
 * @param token
 * @returns {Promise<unknown>}
 */
export const uploadNotesDocumentLink = async(projectId,body, token) => {
    try {
        return await postRequest(`notes/documents/link/${projectId}`, body, token);
    } catch (error) {
        return error;
    }
};

//<editor-fold desc="Notes Content">
/**
 *
 * @param documentId
 * @param body
 * @param token
 * @returns {Promise<unknown>}
 */
export const getNotesContent = async(documentId, token) => {
    try {
        let response = await getRequest(`notes/documents/content/${documentId}`, token);
        return response;
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param documentId
 * @param body
 * @param token
 * @returns {Promise<unknown>}
 */
export const getNotesContentsPdf = async(documentId, token) => {
    try {
        let response = await getFileRequest(`notes/documents/content/pdf/${documentId}`, token);
        return response;
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param documentId
 * @param body
 * @param token
 * @param headers
 * @returns {Promise<unknown>}
 */
export const storeNotesContent = async(documentId, body, token, headers) => {
    try {
        let response = await postRequest(`notes/documents/content/${documentId}`, body, token, headers);
        return response;
    } catch (error) {
        return error;
    }
};

/**
 *  Store Notes HTML Content
 * @param documentId
 * @param body
 * @param token
 * @param headers
 * @returns {Promise<unknown>}
 */
export const storeNotesHTMLContent = async (documentId, body, token, headers) => {
    try {
        return await postRequest(`notes/documents/content/html/${documentId}`, body, token, headers);
    } catch (error) {
        return error;
    }
};


/**
 *
 * @param documentId
 * @param body
 * @param token
 * @returns {Promise<unknown>}
 */
export const getNotesHtmlContent = async (documentId, token) => {
    try {
        let response = await getRequest(`notes/documents/content/${documentId}`, token);
        return response;
    } catch (error) {
        return error;
    }
};
//</editor-fold>



//<editor-fold desc="Notes Highlights">
/**
 *
 * @param documentId
 * @param token
 * @returns {Promise<unknown>}
 */
export const getNotesHighlights = async(documentId, token) => {
    try {
        let response = await getRequest(`notes/highlights/${documentId}`, token);
        return response;
    } catch (error) {
        return error;
    }
};
/**
 * Get Highlights by type
 * @param documentId
 * @param type
 * @param token
 * @returns {Promise<*>}
 */
export const getNotesHighlightsByType = async(documentId, type, token) => {
    try {
        let response = await getRequest(`notes/highlights/list/${documentId}?type=${type}`, token);
        return response;
    } catch (error) {
        return error;
    }
};
/**
 * Get User Annotations
 * @param documentId
 * @param type
 * @param token
 * @returns {Promise<*>}
 */
export const getNotesUserAnnotations = async(documentId, token) => {
    try {
        let response = await getRequest(`notes/highlights/annotations/${documentId}`, token);
        return response;
    } catch (error) {
        return error;
    }
};
/**
 *
 * @param documentId
 * @param data
 * @param token
 * @returns {Promise<*>}
 */
export const postNotesHighlights = async(documentId, data, token) => {
    try {
        let response = await postRequest(`notes/highlights/${documentId}`, data, token);
        return response;
    } catch (error) {
        return error;
    }
};
//</editor-fold>




//<editor-fold desc="Notes Spreadsheet">
/**
 * Get Notes Spreadsheet data
 * @param documentId
 * @param token
 * @returns {Promise<unknown>}
 */
export const getNotesSpreadsheet = async(documentId, token) => {
    try {
        let response = await getRequest(`notes/spreadsheet/get/${documentId}`, token);
        return response;
    } catch (error) {
        return error;
    }
};


/**
 * Post Notes Spreadsheet
 * @param documentId
 * @param data
 * @param token
 * @returns {Promise<unknown>}
 */
export const postNotesSpreadsheet = async(documentId, data, token) => {
    try {
        let response = await postRequest(`notes/spreadsheet/${documentId}`, data,token);
        return response;
    } catch (error) {
        return error;
    }
};
//</editor-fold>




//<editor-fold desc="Notes Comments">
/**
 * Get Notes Comments
 * @param documentId
 * @param token
 * @returns {Promise<unknown>}
 */
export const getNotesComments = async(documentId, token) => {
    try {
        let response = await getRequest(`notes/comments/${documentId}`, token);
        return response;
    } catch (error) {
        return error;
    }
};


/**
 * Post Notes Comments
 * @param documentId
 * @param data
 * @param token
 * @returns {Promise<unknown>}
 */
export const postNotesComments = async(documentId, data, token) => {
    try {
        let response = await postRequest(`notes/comments/${documentId}`, data,token);
        return response;
    } catch (error) {
        return error;
    }
};
//</editor-fold>


//region Notes Multi Label
/**
 * Get Highlights by type
 * @param documentId
 * @param type
 * @param token
 * @returns {Promise<*>}
 */
export const getNotesMultiLabelByType = async(documentId, type, token) => {
    try {
        if (type) {
            return await getRequest(`notes/highlights/multi-label/${documentId}?type=${type}`, token);
        } else {
            return await getRequest(`notes/highlights/multi-label/${documentId}`, token);
        }
    } catch (error) {
        return error;
    }
};
//endregion

