import React from 'react';
import ReactDOM from 'react-dom/client';
import Admin from "./layouts/Admin";
import {HashRouter, Switch, Route} from "react-router-dom";
import {Config} from "../src/config/Config";
import {useHistory} from "react-router-dom";
import Home from "./layouts/Home";
import Project from "./layouts/Project";
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
//import '../node_modules/bootstrap/js/dist/modal.js';
import SignIn from "./views/Authentication/SignIn/SignIn";
import { Provider } from "react-redux";
import store, {persistor} from "./Redux/store";
import NotesLayout from "./views/notes/NotesLayout";
import ChecklistLayout from "./views/checklist/ChecklistLayout";
import ProtectedRoute from "./route-lists/ProtectedRoute";
import {Toaster} from "react-hot-toast";
import './styles/main.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/js/src/modal.js'
import '../node_modules/bootstrap/js/src/dropdown.js'
import '../node_modules/bootstrap/js/src/toast.js'
import '../node_modules/bootstrap/js/src/alert.js'
import '../node_modules/bootstrap/js/src/button.js'
import '../node_modules/bootstrap/js/src/carousel.js'
import '../node_modules/bootstrap/js/src/offcanvas.js'
import '../node_modules/bootstrap/js/src/popover.js'
import '../node_modules/bootstrap/js/src/tab.js'
import '../node_modules/bootstrap/js/src/tooltip.js'
import Notebook from "./layouts/NoteBook";
import {PersistGate} from "redux-persist/integration/react";
import {isEmptyObject} from "./utills/Common";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                <HashRouter>
                    <Switch>
                        <Route path={`/login`} component={SignIn}/>

                        <ProtectedRoute path={`/admin`} component={Admin}/>
                        <ProtectedRoute path={`/home`} component={Home}/>
                        <ProtectedRoute path={`/notes`} component={NotesLayout}/>
                        <ProtectedRoute path={`/project`} component={Project}/>
                        <ProtectedRoute path={`/check-list`} component={ChecklistLayout}/>
                        <ProtectedRoute path={`/notebook`} component={Notebook}/>
                        <ProtectedRoute path={`/`} component={SignIn}/>
                    </Switch>
                </HashRouter>
            </PersistGate>
        </Provider>

    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

