import AnnualReports from "../reports/AnnualReports";
import React from "react";
import ImportLink from "../reports/ImportLink";
import Images from "../images/Images";
import FileDrive from "../fileDrive/FileDrive";
import NotesListView from "../notes/NotesListView";
import Checklist from "../checklist/Checklist";

let Dashboard =()=>{
    return(
        <>
            <NotesListView/>
            <AnnualReports />
            <ImportLink/>
            <Images/>
            <FileDrive/>
            <Checklist/>
        </>
    )
}

export default Dashboard;