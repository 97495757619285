import React, {useState, useEffect, useRef} from "react";
import {getCurrentCompany} from "../../utills/Common";
import {getAllImages, downloadImage, getImage, deleteImage} from "../../actions/Projects/images";
import {useSelector} from "react-redux";
import {getAllImageFiles, uploadImageFiles} from "../../actions/Images/Images";
import {Config} from "../../config/Config";
import {Link, useHistory} from "react-router-dom";
import {GrClose} from "react-icons/gr";
import {toast} from "react-hot-toast";
import './image.css';
import {HiOutlineDownload} from "react-icons/hi";
import {MdOutlineDelete} from "react-icons/md";


let Images = () => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const currentUser = useSelector(state => state.currentUser);
    const searchValue = useSelector(state => state.search);
    const [projectDetails, setCurrentProjectDetails] = useState({});
    const [createFileUploads, setCreateFileUploads] = useState({});
    const [allUploadedResources, setAllUploadedResources] = useState([]);
    const [projectId, setProjectId] = useState();
    const [isReload, setReload] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [imageName, setImageName] = useState();
    const [modalInputImage, setModalInputImage] = useState();
    const [imageId, setImageId] = useState(null);
    const [showError, setShowError] = useState({name: '', image: ''});
    let currentUrl = window.location.href;
    const history = useHistory();
    const alert = toast;
    const fileElem = useRef();
    const nameElem = useRef();

    const getAllUploadedResources = async (projectId) => {
        if (projectId && projectId != null) {
            let response = await getAllImageFiles(token, projectId, searchValue.value != '' ? searchValue.value : '');

            if (response.data && response.data.data && response.data.data.length > 0) {
                setAllUploadedResources(response.data.data);
            } else {
                setAllUploadedResources([]);
            }
        }
    }

    const filterMyUploads = async () => {
        await getAllUploadedResources(projectDetails.id);
    }


    const getCurrentCompanyDetails = async () => {
        let projectDetails = await getCurrentCompany();
        setCurrentProjectDetails(projectDetails);
        if (projectDetails.id) {
            await fetchImages(projectDetails.id);
            setProjectId(projectDetails.id);
        }
    };

    const fetchImages = async (projectId) => {

        let response = await getAllImages(projectId, token);

        if (response.data && response.data.data && response.data.data.length > 0) {
            let imageData = response.data.data.map((report, index) => {
                report.key = index + 1;
                report.action = null;
                return report;
            });

            setAllUploadedResources(imageData);

        } else {
            setAllUploadedResources([]);
            // alert.error("No data found!");
        }
    };

    const onChangeMyUploads = (key, value) => {
        let fileUpload = createFileUploads;
        fileUpload[key] = value;
        setCreateFileUploads(fileUpload);

    }


    const onSubmitCreateLinks = async () => {
        if (createFileUploads.name && createFileUploads.imgFile) {
            let imageData = new FormData();
            imageData.append("image", createFileUploads.imgFile);
            imageData.append("name", createFileUploads.name);
            let response = await uploadImageFiles(projectDetails.id, imageData, token);
            setReload(!isReload);
            if (response.data.success) {
                await getAllUploadedResources(projectDetails.id);
                document.getElementById("closeAddImage").click();   //after success close modal
                alert.success(response.data.message);
                setCreateFileUploads({});
            } else {
                alert.success(response.data.message);
            }
        } else {
            if (!createFileUploads.name) {
                alert.error('Name Is Required');
                setShowError({name: "Name is required"});
            }
            if (!createFileUploads.imgFile) {
                alert.error('Please Upload Image File');
                setShowError({image: "Image file is required"});
            }
        }
    };

    const deleteSingleImage = async (imageid, token) => {
        let response = await deleteImage(imageid, token);
        if (response.data.success) {
            alert.success(response.data.message);
            setReload(!isReload);
            document.getElementById('closeImageSure').click();
        } else {
            alert.error('some error occured');
        }
    };

    const getImage = async (imageId) => {
        let fileId = imageId.id;
        let filePath = imageId.path;
        let fileExtenstion = filePath.split('.').pop();
        let fileName = new Date().getTime();

        downloadImage(fileId, token).then(response => {
            if (response) {
                let url = window.URL.createObjectURL(new Blob([response.data], {type: imageId.type}));
                let link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', fileName + "." + fileExtenstion);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
                alert.success('Image downloaded successfully !')
            } else {
                alert.error("Something went wrong with file download")
            }
        });
    }

    const fileInput = useRef(null);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e.dataTransfer.files;
        if (files.length > 0) {
            handleFileSelect(files);
        }
    };

    const handleClick = () => {
        fileInput.current.click();
    };

    const handleFileSelect = (files) => {
        console.log(files);
        onChangeMyUploads("imgFile", files[0]);
        if (files && files[0]) {
            setModalInputImage(URL.createObjectURL(files[0]));
        }
        // Here, you can use the files that were dropped or selected
        // to perform some operation, such as uploading to a server
    };

    useEffect(() => {
        filterMyUploads();
    }, [searchValue]);

    useEffect(() => {
        getCurrentCompanyDetails();
        getAllUploadedResources();
    }, [isReload]);


    function imageCard(img, index) {
        return (
            <div className='col-3 p-2' key={index}>
                <div id='imageCard' className=''>

                    <img

                        data-bs-toggle='modal'
                        data-bs-target='#imageFullView'
                        className='w-100 rounded-4 bg-white ' style={{height: "8.25rem", objectFit: 'cover'}}
                        onClick={() => {
                            setImageUrl(Config.API_URL + "images/download/" + img.id);
                            setImageName(img.name);
                        }}
                        alt="Loading..."
                        src={Config.API_URL + "images/download/" + img.id}/>

                    <div className='hstack justify-content-between px-3' id={'divBottom'}>
                        <span className='text-light'>{img.name}</span>
                        <div className='cursor-pointer'>
                            <span className=' m-1 me-2 text-light'
                                  data-bs-toggle="tooltip" title="Download"
                                  onClick={async () => {
                                      await getImage(img)
                                  }}
                            ><HiOutlineDownload/></span>
                            <span className='m-1 text-light'
                                  data-bs-toggle='modal' data-bs-target='#deleteImage'
                                 title="Delete"
                                  onClick={() => {
                                      setImageId(img.id);
                                  }}
                            ><MdOutlineDelete/></span>
                        </div>
                    </div>

                </div>
            </div>

        )
    }

    function gotoImages() {
        history.push('/project/on-ground-images')
    }


    return (
        <>

            <div className='m-4 px-4 py-2 bg-white rounded-4 shadow-sm fontstyle '>
                <div className='d-flex pb-2'>

                        <button className='btn bg-white ri-add-line text-primary '
                                data-bs-toggle='modal'
                                data-bs-target='#addNewImage'
                                style={{
                                    borderBottom: "2px solid #D76D00",
                                    borderRadius: "12px",
                                    boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.13)"
                                }}
                        ></button>

                    <div className='w-100 d-flex flex-row justify-content-between'>
                        <span className='fw-semibold p-2 text-center ms-1'>On-Ground Images</span>
                        {(currentUrl.includes('on-ground-images')) ?
                            <></> : <span className="align-self-center"><Link to='#' onClick={gotoImages} style={{textDecoration: 'none'}}>
                                <span className='text-primary' style={{fontSize: '13px'}}>see more</span>
                            </Link></span>
                        }
                    </div>
                </div>

                <div className='row mt-0 gx-3'>
                    {
                        (allUploadedResources && allUploadedResources.length > 0) ?
                            (currentUrl.includes('on-ground-images'))
                                ? (allUploadedResources.map((img, index) => imageCard(img, index)
                                )) : (allUploadedResources.slice(0, 8).map((img, index) => imageCard(img, index)
                                )) : <>no data found</>
                    }

                </div>
            </div>


            {/*Add New Image Modal*/}
            <div className="modal fade" id="addNewImage" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <p className='w-100 border-0 fw-semibold outline-none' type='text'>Upload Image
                                </p>

                                <div className='outline none' id={'closeAddImage'} data-bs-dismiss="modal" type='button'
                                     onClick={() => {
                                         setModalInputImage(null);
                                         fileInput.current.value = null;
                                         nameElem.current.value = '';
                                     }}><GrClose/></div>
                            </div>

                            <div className='p-2' onClick={handleClick}>

                                <div
                                    onDragEnter={handleDragEnter}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    className=' w-100 outline-none rounded-2 mt-3 d-flex'
                                    style={{
                                        border: "1px dashed #d76005",
                                        height: '10rem',
                                        justifyContent: 'center',
                                        backgroundColor: '#fffaf3', color: '#d76d00'
                                    }}
                                >


                                    {modalInputImage == null ?
                                        <div  className='text-center d-flex align-self-center'>
                                            "Drag and drop files here or click to select files." </div> :
                                        <div className='w-100 align-items-center justify-content-center d-flex'>
                                            <img className='p-1' style={{height: '9rem', objectFit: 'cover'}}
                                                 src={modalInputImage}/>
                                        </div>}


                                    <input
                                        type="file"
                                        ref={fileInput}
                                        name="imgFile"
                                        accept="image/png, image/gif, image/jpeg"
                                        style={{display: 'none'}}
                                        onChange={(e) => {
                                            handleFileSelect(e.target.files)
                                        }}
                                    />
                                </div>
                                {/*<input className=' w-100 outline-none rounded-2 mt-3 border-0' ref={fileElem}
                                       placeholder='select file' required
                                       style={{backgroundColor: '#fffaf3', height: '49px', color: '#d76d00'}}
                                       type="file"
                                       name="imgFile"
                                       accept="image/png, image/gif, image/jpeg"
                                       onChange={(e) => {

                                       }}/>*/}
                                <span style={{fontSize: '12px', color: 'red'}}>{showError.link}</span>
                                <span style={{fontSize: '12px', color: 'red'}}>{showError.proper}</span>

                                <input className=' w-100 outline-none rounded-2 mt-3' ref={nameElem}
                                       placeholder='Description or type (optional)'
                                       placeholder='Enter Image Name'
                                       name="name"
                                       onChange={(e) => {
                                           onChangeMyUploads("name", e.target.value)
                                       }}
                                       style={{
                                           border: '0.5px solid #ebebeb',
                                           backgroundColor: '#fafafa',
                                           height: '49px'
                                       }}></input>
                            </div>

                            <div className='w-100 d-flex justify-content-center p-2'>

                                <button className='btn buttonOrangeFill mt-4'
                                        style={{width: '8.5rem'}}
                                        onClick={() => {
                                            onSubmitCreateLinks();
                                        }}
                                > Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/*Image full scale view Modal*/}
            <div className="modal fade" id="imageFullView" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <span className='w-100 border-0 fw-semibold outline-none'  type='text'>{imageName}
                                </span>

                                <div className='outline none' data-bs-dismiss="modal" type='button'
                                     onClick={() => {

                                     }}><GrClose/></div>
                            </div>

                            <img className='w-100' src={imageUrl}/>
                        </div>

                    </div>
                </div>
            </div>

            {/*Delete file Sure Modal*/}
            <div className="modal fade" id="deleteImage" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div style={{borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}>
                                <div className='d-flex justify-content-center'>
                                    <img className='m-2' style={{width: "10rem"}}
                                         src='./assets/icons/84228-delete-animation.gif'/>
                                </div>
                                <p className='justify-content-center d-flex'
                                   style={{fontSize: "18px", fontWeight: '600'}}>Do you want to DELETE this
                                    file?</p>
                                <p className='justify-content-center d-flex fw-semibold pb-4'
                                   style={{fontSize: '14px'}}>Are you sure ?&nbsp; <span className='fw-normal'> your file will be deleted permanently.</span>
                                </p>
                                <div className='d-flex'>
                                    <button className='w-50 border-0' data-bs-dismiss="modal"
                                            id='closeImageSure'
                                            style={{
                                                height: '56px',
                                                color: '#ff0000',
                                                backgroundColor: '#fff8f8',
                                                borderBottomLeftRadius: '6px'
                                            }}
                                            onClick={() => {
                                                setImageId(null);
                                            }}>CANCEL
                                    </button>
                                    <button className='w-50 border-0'
                                            style={{
                                                height: '56px',
                                                color: '#ffffff',
                                                backgroundColor: '#d76d00',
                                                borderBottomRightRadius: '6px'
                                            }}
                                            onClick={() => {
                                                deleteSingleImage(imageId, token);
                                            }}>DELETE
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Images;