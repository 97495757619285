import {getCurrentCompany} from "../../../utills/Common";
import {getNotesHighlights, getNotesHighlightsByType} from "../../../actions/Notes/Notes";
import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {useSelector} from "react-redux";



let HighlightsView = forwardRef((props, ref) => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const currentUser = useSelector(state => state.currentUser);
    const [projectDetails, setProjectDetails] = useState({});
    const [highlights, setHighlights] = useState(null);
    const documentId = props.documentId;
    // const alert = useAlert();
    const onClickHighlight = props.onClickHighlight;
    const [reload, toggleReload] = useState(false);
    const [currentBtn, setCurrentBtn] = useState('All');

    useImperativeHandle(ref, () => ({
        reload() {
            toggleReload(!reload);
        }
    }));

    const loadData = async () => {
        let projectDetails = await getCurrentCompany();
        setProjectDetails(projectDetails);
        await loadHighlights();
    };

    const loadHighlights = async (type = null) => {
        let response = null;
        if (type === null) {
            response = await getNotesHighlights(documentId, token);
        } else {
            response = await getNotesHighlightsByType(documentId, type, token);
        }

        if (response && response.data.data && response.data.data.length > 0) {
            let highlights = response.data.data.map((report, index) => {
                report.key = index + 1;
                report.action = null;
                return report;
            });
            setHighlights(highlights);
        } else {
            setHighlights([]);
        }
    };

    useEffect(() => {
        async function loadingData() {
            await loadData();
        }

        console.log(documentId, 'this is somethinsg');
        loadingData();

    }, [documentId, reload]);

    const getStyleByType = (type) => {
        if (type === "Strategy") return '/assets/icons/workAreaIcon/strategy.svg';
        if (type === "Goals") return '/assets/icons/workAreaIcon/goals.svg';
        if (type === "Strength") return '/assets/icons/workAreaIcon/strength.svg';
        if (type === "Weakness") return '/assets/icons/workAreaIcon/weakness.svg';
        if (type === "Assumption") return '/assets/icons/workAreaIcon/assumption.svg';
        if (type === "RedFlag") return '/assets/icons/Red_Flag.svg';
        return "warning"
    };

    const getColorByType = (type) => {
        if (type === "Strategy") return '#019d9d';
        if (type === "Goals") return '#8352ff';
        if (type === "Strength") return '#3fba44';
        if (type === "Weakness") return '#ba3f3f';
        if (type === "Assumption") return '#3f53ba';
        if (type === "RedFlag") return 'red';
        return "gray"
    };


    const findSentence = (text) => {

    }

    const fetchClassifications = async (type) => {
        if (type === 'All') {
            await loadHighlights();
        } else {
            await loadHighlights(type);
        }
    }


    return (
        <>
            <div className='p-0 m-0' style={{backgroundColor: '#f5f5f5'}}>
                <button className='btn p-2 px-4 m-1 rounded-3 fw-semibold'
                        style={{
                            borderBottom: '2px solid #d76d00',
                            fontSize: '10px',
                            color: (currentBtn === 'All' ? 'white' : '#d76d00'),
                            backgroundColor: (currentBtn === 'All' ? '#d76d00' : 'white')
                        }}
                        onClick={() => {
                            setCurrentBtn('All');
                            fetchClassifications();
                        }}>
                    All
                </button>
                <button className='btn px-3 p-2 m-1 rounded-3 outline-none shadow fw-semibold'
                        style={{
                            borderBottom: '2px solid #8352ff',
                            fontSize: '10px',
                            color: (currentBtn === 'Goals' ? 'white' : '#8352ff'),
                            backgroundColor: (currentBtn === 'Goals' ? '#8352ff' : 'white')
                        }}
                        onClick={() => {
                            setCurrentBtn('Goals');
                            fetchClassifications('Goals');
                        }}>
                    <img src={`/assets/icons/workAreaIcon/${currentBtn === 'Goals' ? 'goalsWhite' : 'goals'}.svg`}/>
                    &nbsp;Goals
                </button>
                <button className='btn px-3 p-2 m-1 rounded-3 shadow fw-semibold'
                        style={{
                            borderBottom: '2px solid #3fba44',
                            fontSize: '10px',
                            color: (currentBtn === 'Strength' ? 'white' : '#3fba44'),
                            backgroundColor: (currentBtn === 'Strength' ? '#3fba44' : 'white')
                        }}
                        onClick={() => {
                            setCurrentBtn('Strength');
                            fetchClassifications('Strength');
                        }}>
                    <img
                        src={`/assets/icons/workAreaIcon/${currentBtn === 'Strength' ? 'strengthWhite' : 'strength'}.svg`}/>
                    &nbsp;Strength
                </button>
                <button className='btn px-3 p-2 m-1 rounded-3 shadow fw-semibold'
                        style={{
                            borderBottom: '2px solid #ba3f3f',
                            fontSize: '10px',
                            color: (currentBtn === 'Weakness' ? 'white' : '#ba3f3f'),
                            backgroundColor: (currentBtn === 'Weakness' ? '#ba3f3f' : 'white')
                        }}
                        onClick={() => {
                            setCurrentBtn('Weakness');
                            fetchClassifications('Weakness');
                        }}>
                    <img
                        src={`/assets/icons/workAreaIcon/${currentBtn === 'Weakness' ? 'weaknessWhite' : 'weakness'}.svg`}/>
                    &nbsp;Weakness
                </button>
                <button className='btn px-3 p-2 m-1 rounded-3 shadow fw-semibold'
                        style={{
                            borderBottom: '2px solid #3f53ba',
                            fontSize: '10px',
                            color: (currentBtn === 'Assumption' ? 'white' : '#3f53ba'),
                            backgroundColor: (currentBtn === 'Assumption' ? '#3f53ba' : 'white')
                        }}
                        onClick={() => {
                            setCurrentBtn('Assumption');
                            fetchClassifications('Assumption');
                        }}>
                    <img
                        src={`/assets/icons/workAreaIcon/${currentBtn === 'Assumption' ? 'assumptionWhite' : 'assumption'}.svg`}/>
                    &nbsp;Assumption
                </button>
                <button className='btn px-3 p-2 m-1 rounded-3 shadow fw-semibold'
                        style={{
                            borderBottom: '2px solid #019d9d',
                            fontSize: '10px',
                            color: (currentBtn === 'Strategy' ? 'white' : '#019d9d'),
                            backgroundColor: (currentBtn === 'Strategy' ? '#019d9d' : 'white')
                        }}
                        onClick={() => {
                            setCurrentBtn('Strategy');
                            fetchClassifications('Strategy');
                        }}>
                    <img
                        src={`/assets/icons/workAreaIcon/${currentBtn === 'Strategy' ? 'strategyWhite' : 'strategy'}.svg`}/>
                    &nbsp;Strategy
                </button>
            </div>

            <div className='vstack mt-2'>
                {
                    highlights && highlights.length > 0 ? <>
                        {
                            highlights.map((item) => (

                                <div className=' p-2 m-2 rounded shadow'
                                     style={{borderTop: `3px solid ${getColorByType(item.type)} `}} //change color dynamic
                                     onClick={() => {
                                         onClickHighlight(item.description, item.type);
                                     }}>
                                    <div className='d-flex align-items-start '>
                                        <img className='me-2 m-1 ' style={{height: '1.3rem'}}
                                             src={getStyleByType(item.type)}/>
                                        <text className='d-flex align-self-center fw-semibold'
                                              style={{fontSize: '12px'}}>{item.type}</text>
                                    </div>
                                    <div>
                                        <text className='mx-2' style={{fontSize: '12px'}}>{item.description}</text>
                                    </div>
                                </div>
                            ))
                        }

                    </> : ''
                }
            </div>

        </>
    )
});

export default HighlightsView;