import React, {useCallback, useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";

import 'remixicon/fonts/remixicon.css';
import "../../styles/main.css";
import "./sidebar.css";
import ProjectRoutes from "../../route-lists/ProjectRoutes";
import {sidebarWidthControl} from "../../Redux/Action";
import {removeUserSession, setCompanySession} from "../../utills/Common";
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getLocalToken, getLocalUser} from "../../utils/storage";
import {getAllUserProjects, getProjectById} from "../../actions/Projects";
import _debounce from "lodash/debounce";


let ProjectSidebar = () => {

    const user = getLocalUser();
    const token = getLocalToken();
    const [myProjects, setMyProjects] = useState();
    const [searchedValue, setSearchedValue] = useState('');
    const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);
    const sidebarToggle = useSelector((state => state.changeTheSidebar));
    const dispatch = useDispatch();
    //    Alert
    const [alertProp, setAlertProp] = useState({
        display: "d-none",
        status: "success",
        message: "Added Successfully",
        icon: <></>
    })

    const History = useHistory();

    const onSidebarWidthChange = () => {
        dispatch(sidebarWidthControl())
    }

    const logout = async () => {
        await removeUserSession();
        History.push("/login");
    };

    const getAllProjects = async () => {
        if (user && token) {
            let response = await getAllUserProjects(token, searchedValue);
            if (response.data.data && response.data.data.length > 0) {
                setMyProjects(response.data.data);
            } else {
                setMyProjects([]);
            }
        }
    };

    const getSingleProject = async (id) => {
        let response = await getProjectById(id, token);

        if (response.data.success && response.data.data) {
            setCompanySession(response.data.data);
            History.push("/project/dashboard/" + id);
        } else {
            alert.success(response.data.message);
        }
    };

    function handleDebounceFn(event) {
        setSearchedValue(event.target.value);
        getAllProjects();
    }

    useEffect(() => {
        getAllProjects();
    }, [searchedValue]);


    return (
        <>

            <div
                className={`alert alert-${alertProp.status} d-flex ${alertProp.display} align-items-center position-absolute m-4 end-0 top-0`}
                role="alert">

                {alertProp.icon}

                <div>
                    {alertProp.message}
                </div>
            </div>

            <div
                className=' d-flex flex-column justify-content-between bg-secondary border-end vh-100 px-3 py-4'
                style={{
                    width: sidebarToggle === true ? '230px' : '85px',
                    transition: 'width 0.2s ease 0s',
                    whiteSpace: 'nowrap'
                }}>

                <div>
                    <div
                        className={`d-flex ${`fw-semibold ${sidebarToggle ? 'justify-content-between' : 'justify-content-center'}`} align-items-center mb-3 pb-2`}>
                        <div><img className='me-2' src='./assets/icons/zebrapro logo /Zebralogo.svg'/><img
                            className={`${(sidebarToggle === true) ? '' : 'd-none'}`}
                            src='./assets/icons/zebrapro logo /zebrapro logo name.svg'/></div>
                        <img className='shadow-sm rounded position-absolute' onClick={onSidebarWidthChange}
                             type='button'
                             style={{
                                 transform: (sidebarToggle == true) ? 'rotate(0deg)' : 'rotate(180deg)',
                                 marginRight: (sidebarToggle == true) ? '' : '-40px',
                                 left: (sidebarToggle == true) ? '217px' : '72px',
                                 transition: ' 0.2s ease 0s'
                             }}
                             src='./assets/icons/sidebar side controller.svg'/>
                    </div>

                    {/*Project sidebar option list*/}
                    <ul className='list-unstyled d-grid gap-1 '
                        style={{paddingLeft: sidebarToggle === true ? '' : '0px'}}>

                        {
                            ProjectRoutes.resource.map((i, index) => {
                                return (
                                    <NavLink to={`${i.layout}${i.path}`} key={index} activeClassName='activeOption'
                                             className={`${(sidebarToggle === true) ? 'ps-1' : 'justify-content-center'} d-flex align-items-center text-decoration-none py-1 rounded`}

                                    >{i.icon}
                                        <span
                                            className={`fw-semibold ${(sidebarToggle === true) ? 'd-block ps-1' : 'd-none'} text-black`}>{i.name}</span>
                                    </NavLink>
                                )
                            })
                        }

                    </ul>

                </div>

                {/*project change list*/}

                <div>
                    <div className='w-100 d-flex  activeOption mb-1 rounded cursor-pointer dropend'>
                        <div data-bs-toggle="dropdown" aria-expanded="false"
                             className='d-flex w-100 justify-content-between'>
                            <span
                                className={`${(sidebarToggle === true) ? 'ps-1' : 'justify-content-center'} d-flex align-items-center text-decoration-none py-1 rounded`}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z"/>
                                    <path
                                        d="M3 21a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2H20a1 1 0 0 1 1 1v3h-2V7h-7.414l-2-2H4v11.998L5.5 11h17l-2.31 9.243a1 1 0 0 1-.97.757H3zm16.938-8H7.062l-1.5 6h12.876l1.5-6z"/>
                                </svg>
                                <span
                                    className={`fw-semibold ${(sidebarToggle === true) ? 'd-block ps-1' : 'd-none'}`}>Projects</span>
                            </span>
                            <div className='align-self-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z"/>
                                    <path d="M13.172 12l-4.95-4.95 1.414-1.414L16 12l-6.364 6.364-1.414-1.414z"/>
                                </svg>
                            </div>
                        </div>

                        <div className="dropdown-menu dropdown-menu-end overflow-hidden ">
                            <input
                                className='m-2 outline-none border-0'
                                placeholder='Search project...' type='text'
                                onChange={(event) => {
                                    debounceFn(event);
                                }}/>
                            <hr/>
                            <div className='overflow-scroll' style={{height: '30rem'}}>
                                <ul className='p-0 mx-1'>
                                    {myProjects ?
                                        myProjects.map((project, index) => {
                                            return (
                                                <NavLink key={index} to={'/#'} className='text-decoration-none'>
                                                    <li className='dropdown-item rounded-4'
                                                        onClick={() => {
                                                            getSingleProject(project.id);
                                                        }}>{project.name}</li>
                                                </NavLink>
                                            )
                                        }) : ''}

                                </ul>
                            </div>

                        </div>

                    </div>

                    <div className='d-flex p-2 rounded-2 mb-2'
                         style={{backgroundColor: sidebarToggle === true ? '#FFECD1' : ''}}>
                        <div className='position-relative d-flex align-items-center'
                             style={{marginRight: (sidebarToggle == true) ? '30px' : '0',}}>
                            <img className='rounded-circle border border-white border-4 rounded-circle '
                                 style={{width: (sidebarToggle == true) ? '40px' : '25px'}}
                                 src='./assets/images/Zebralogo.png'/>
                            <img
                                className='rounded-circle border border-white border-4 rounded-circle position-absolute start-50'
                                style={{width: (sidebarToggle == true) ? '40px' : '25px'}}
                                src='./assets/images/profile person photo.png'/>
                        </div>
                        <div className={`d-flex flex-column ${(sidebarToggle === true) ? 'd-block' : 'd-none'}`}
                             style={{whiteSpace: 'nowrap', overflow: 'hidden',}}>
                               <span className='cursor-pointer fs-6 text fw-semibold mb-0'
                                     style={{textOverflow: 'ellipsis', overflow: 'hidden',}}
                                     title={getLocalUser().name}>{getLocalUser().name}</span>
                            <span className='cursor-pointer fs-6'
                                  style={{textOverflow: 'ellipsis', overflow: 'hidden',}}
                                  title={getLocalUser().email}>{getLocalUser().email}</span>
                        </div>
                    </div>

                    <button className='logout-btn btn btn-outline-primary w-100 fw-semibold' onClick={logout}>
                        {sidebarToggle === true ? <span>Sign Out</span> :
                            <svg className='btn-hover-white' fill='#D76D00' xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 24 24" width="20"
                                 height="20">
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path
                                    d="M5 11h8v2H5v3l-5-4 5-4v3zm-1 7h2.708a8 8 0 1 0 0-12H4A9.985 9.985 0 0 1 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.985 9.985 0 0 1-8-4z"/>
                            </svg>
                        }
                    </button>
                </div>
            </div>


        </>
    );
}
export default ProjectSidebar;
