
const initialState = true;

const changeTheSidebar = (state = initialState, action) => {
    switch (action.type){
        case "WIDTHCONTROL":
            return !state;
        default:
            return state;
    }


};


export default changeTheSidebar