
import routes from "../../route-lists/checklist";
import React, {useState} from "react";
import {Switch, Redirect,useHistory,Route} from "react-router-dom"
import ChecklistSidebar from "./ChecklistSidebar";
import {useSelector} from "react-redux";
import { useParams} from "react-router";
// import ChecklistView from "./ChecklistView";
import Sidebar from "../../component/sidebar/Sidebar";
import Header from "../../component/header/Header";
import AdminRoutes from "../../route-lists/AdminRoutes";


let ChecklistLayout =(props)=>{

    const {...rest} = props;
    let {id} = useParams();
    const History = useHistory();
    const currentUser = useSelector(state => state.currentUser);
    //console.log('page id', id);

    // states and functions
    const [sidebarVariant, setSidebarVariant] = useState("opaque");
    const [fixed, setFixed] = useState(false);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [sidebarWidth, setSidebarWidth] = useState(275);

    // ref for main panel div
    const mainPanel = React.createRef();

    // functions for changing the states from components
    const getRoute = () => {
        return window.location.pathname !== "/admin/full-screen-maps";
    };

    const sidebarToggle = useSelector((state => state.changeTheSidebar));

    const getActiveRoute = (routes) => {
        let activeRoute = "Zebra Pro";
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = getActiveRoute(routes[i].items);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (routes[i].category) {
                let categoryActiveRoute = getActiveRoute(routes[i].items);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };

    const getActiveNavbar = (routes) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveNavbar = getActiveNavbar(routes[i].items);
                if (collapseActiveNavbar !== activeNavbar) {
                    return collapseActiveNavbar;
                }
            } else if (routes[i].category) {
                let categoryActiveNavbar = getActiveNavbar(routes[i].items);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].secondaryNavbar;
                }
            }
        }
        return activeNavbar;
    };

    const getRoutes = (routes) => {
        return routes.map((prop, key) => (
                <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                />
            )
        );
    };



    document.documentElement.dir = "ltr";
    document.documentElement.layout = "admin";
    return(
        <>
            <div className="d-flex vh-100">
                <ChecklistSidebar key={id}/>
                <div style={{
                    width: sidebarToggle ? "calc(100% - 230px)" : "calc(100% - 85px)",
                    transition: 'width 0.2s ease 0s'
                }}>

                    <div className='w-100'
                         style={{overflow: "auto", height: "calc(100vh)", backgroundColor: '#f5f5f5'}}>

                        <Switch>

                            {getRoutes(routes)}
                            <Redirect from="/projects/check-list" to={"/projects/check-list"}/>
                        </Switch>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ChecklistLayout;