import axios from 'axios';
import { Config } from '../config/Config';

/**
 *
 * @param actionName
 * @param token
 * @returns {Promise|Promise}
 */
export const getRequest = (actionName, token) => {
    return new Promise((resolve, reject) => {
        try {
            let header = {};

            if (token && token !== undefined) {
                header = {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };
            }
            axios.get(`${Config.API_URL}${actionName}`, header).then(response => {
                resolve(response);
            }).catch(err => {
                reject(err)
            });
        } catch (error) {
            reject(error);
        }
    })
};

/**
 *
 * @param actionName
 * @param token
 * @returns {Promise|Promise}
 */
export const getFileRequest = (actionName, token) => {
    return new Promise((resolve, reject) => {
        try {
            let header = {};

            if (token && token !== undefined) {
                header = {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    responseType: 'arraybuffer',
                };
            }
            axios.get(`${Config.API_URL}${actionName}`, header).then(response => {
                resolve(response);
            }).catch(err => {
                reject(err)
            });
        } catch (error) {
            reject(error);
        }
    })
};

/**
 *
 * @param actionName
 * @param body
 * @param token
 * @param headers
 * @returns {Promise|Promise}
 */
export const postRequest = (actionName, body = {}, token = "", headers = {}) => {
    return new Promise((resolve, reject) => {
        try {
            let header = {};

            if (token && token !== undefined) {
                header = {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };
            }
            let allHeaders = {
                ...header,
                ...headers
            };
            //single login for workstation and zebra pro app
            if(actionName === 'login'){
                let body1 = {
                    "username":body.username,
                    "firebase_uid":body.password
                }
                axios.post("https://app.zebrapro.in/api/v1/"+`${actionName}`, body1, allHeaders).then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err)
                });
            }
            //single login for workstation and zebra pro app
            else
            {
                axios.post(`${Config.API_URL}${actionName}`, body, allHeaders).then(response => {
                    resolve(response);
                }).catch(err => {
                    reject(err)
                });
            }
        } catch (error) {
            reject(error);
        }
    })
};

/**
 *
 * @param actionName
 * @param token
 * @returns {Promise|Promise}
 */
export const deleteRequest = (actionName, token) => {
    return new Promise((resolve, reject) => {
        try {
            let header = {};

            if (token && token !== undefined) {
                header = {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };
            }

            axios.delete(`${Config.API_URL}${actionName}`, header).then(response => {
                resolve(response);
            }).catch(err => {
                reject(err)
            });
        } catch (error) {
            reject(error);
        }
    })
};
/**
 *
 * @param actionName
 * @param body
 * @param token
 * @returns {Promise|Promise}
 */
export const patchRequest = (actionName, body = {}, token = "") => {
    return new Promise((resolve, reject) => {
        try {
            let header = {};

            if (token && token !== undefined) {
                header = {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                };
            }

            axios.patch(`${Config.API_URL}${actionName}`, body, header).then(response => {
                resolve(response);
            }).catch(error => {
                resolve(error.response);
            });
        } catch (error) {
            reject(error);
        }
    })
};