const env = require('../env');

const MODE = env.mode; // development, production

// For development
let API_URL = "http://localhost:8000/api/v1/";

// For production
if (MODE === 'production') {
    API_URL = "https://workstation.zebrapro.in/api/v1/";
}

const firebaseConfig = {
    apiKey: "AIzaSyAUOniQvoQgvbUM_lHrDpXFMbBgeCYsYs8",
    authDomain: "zebrapro-4619c.firebaseapp.com",
    projectId: "zebrapro-4619c",
    storageBucket: "zebrapro-4619c.appspot.com",
    messagingSenderId: "674064519867",
    appId: "1:674064519867:web:f96c45d44bd068ebf80f9e",
    measurementId: "G-9PBPXRL92M"
};

export const Config = {
    API_URL: API_URL,
    DASHBOARD_ROUTE: "/admin/my-projects",
    firebase_config: firebaseConfig
};