
// For truncate string to limited words
export default function truncateString(str, num) {

    if (str!=null && str.length > num) {
        return str.slice(0, num) + "...";
    } else {
        return str;
    }
}

{/* Used this function for separating tags from string in Project Card*/
}
export const strConvert = (str) => {
    let newStr = str.replaceAll("& ", "").split(" "); //removed special caracter & and replace it by empty string
    return newStr;
}