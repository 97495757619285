const currentNotebookPage = (state = {}, action) => {
    if (action.type == 'SET_NOTEBOOK') {
        return {
            ...state,
            data: action.payload
        }
    } else {
        return state;
    }
}

export default currentNotebookPage;