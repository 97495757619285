import React, {useEffect, useState} from "react";
import {deleteImageFile, uploadImageFiles} from "../../../actions/Images/Images";
import {Config} from "../../../config/Config";
import {generateUUID} from "three/src/math/MathUtils";
import {useSelector} from "react-redux";
import {AiOutlineDelete} from "react-icons/ai";
import {GrClose} from "react-icons/gr";
import {toast} from "react-hot-toast";

const ChecklistImages = React.forwardRef((props, ref) => {

    const token = localStorage.getItem('token');
    const [images, setImages] = useState([]);
    const [indexDelete, setIndexDelete] = useState({});

    const question = props.question;
    const questionIdx = props.questionIdx;
    const sectionIdx = props.sectionIdx;
    const onChangeContent = props.onChangeContent;
    const project_id = props.project_id;
    const hiddenFileInput = React.useRef(null);
    const alert = toast



    useEffect(() => {
        if (question.answer.values) {
            setImages(question.answer.values);
        }
    }, []);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = async (event) => {
        const files = event.target.files;
        console.log("change");
        if (files) {
            for (const file of files) {
                try {
                    let formData = new FormData();
                    formData.append("image", file);
                    formData.append("name", generateUUID());
                    formData.append("type", 'checklist_image');

                    let response = await uploadImageFiles(project_id, formData, token);

                    if (response.data.success) {
                        let filename = response.data.data.filename;
                        images.push(filename);
                        setImages(images);
                        alert.success(response.data.message);
                    } else {
                        alert.success(response.data.message);
                    }
                } catch (e) {
                    console.error(e)
                }
            }

            question.answer.values = images;
            onChangeContent(questionIdx, sectionIdx, question);

        } else {
            alert.error("Image is required")
        }
    };

    let deleteImage = async (index, filename) => {
        console.log(index, filename, 'from function');

        question.answer.values.splice(index, 1);
        onChangeContent(questionIdx, sectionIdx, question);
        let response = await deleteImageFile(filename, token);
        console.log(response);
        if (response.data.success) {
            //console.log("uploaded file", filename);
            document.getElementsByClassName('closeDeleteImg').click();
            alert.success(response.data.message);
        } else {
            alert.error(response.data.message);
        }
    };


    return (
        <>


                <div className='hstack p-1 d-flex flex-wrap ' >
                    <div className='bg-white rounded m-1' >
                        <div className='vstack align-items-center justify-content-center d-flex border p-1'
                             style={{cursor: 'pointer', width: '80px', height: '80px', borderRadius: '12px'}}

                             onClick={handleClick}>

                            <img src={'/assets/icons/imageIcon.svg'}/>
                            <p className="m-0 " style={{fontSize: '12px', fontWeight: '500'}}>Add Images</p>
                        </div>

                        <input
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{display: 'none'}}
                            multiple={true}
                        />
                    </div>


                    {
                        question.answer.values && question.answer.values.length > 0
                            ? (
                                question.answer.values.map((val, index) => {
                                    return (
                                        <div style={{position: 'relative'}} key={index}>
                                            <img
                                                data-bs-toggle='modal'
                                                data-bs-target='#imageFullView'
                                                className='m-1'
                                                style={{
                                                    objectFit: 'cover',
                                                    width: '80px',
                                                    height: '80px',
                                                    borderRadius: '12px',
                                                    cursor: 'zoom-in'
                                                }}
                                                onClick={() => {

                                                    //setImageUrl(Config.API_URL + 'images/download/file/' + val);

                                                    document.getElementById('unique').src = (Config.API_URL + 'images/download/file/' + val);
                                                    // passing src using useState not working giving error
                                                }}
                                                src={Config.API_URL + 'images/download/file/' + val}/>


                                            <div className='m-1 border cursor-pointer'
                                                 _hover={{bg: "#d76d00"}}

                                                 style={{
                                                     borderRadius: '12px',
                                                     backgroundColor: '#ffffff',
                                                     position: 'absolute',
                                                     top: '0',
                                                     right: '0'
                                                 }}
                                            ><AiOutlineDelete
                                                 data-bs-toggle='modal'
                                                 data-bs-target='#deleteImage'
                                                onClick={async () => {
                                                     setIndexDelete({Idx:index,val:val})
                                                    //await deleteImage(index, val);
                                                }}/>
                                            </div>

                                        </div>


                                    )
                                })
                            )
                            : (<></>)
                    }
                </div>



            {/*Image full scale view Modal*/}
            <div className="modal fade" id="imageFullView" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <p className='w-100 border-0 fw-semibold outline-none m-0' type='text'>
                                    {/*image name here*/}
                                </p>

                                <div className='outline none' data-bs-dismiss="modal" type='button'
                                     onClick={() => {
                                         //setImageUrl(null);
                                     }}><GrClose/></div>
                            </div>

                            <img id='unique' className='w-100'/>

                        </div>
                    </div>
                </div>
            </div>

            {/* Delete Image Sure Modal*/}
            {/*DELETE Modal*/}
            <div className="modal" id='deleteImage' tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Image ?</h5>
                            <button type="button" className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => {

                                    }}>

                            </button>
                        </div>
                        <div className="modal-body d-flex">
                            <img className='m-2' style={{width: "4rem"}}
                                 src='./assets/icons/84228-delete-animation.gif'/>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <p className='justify-content-center d-flex fw-semibold mb-0'>Are you sure
                                        ?&nbsp;</p>
                                    <span className='fw-normal'>This Image will be deleted permanently.</span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary closeDeleteImg"
                                    data-bs-dismiss="modal"
                                    onClick={() => {

                                    }}>Close
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={async () => {
                                        console.log(indexDelete,"check")
                                         await deleteImage(indexDelete.Idx, indexDelete.val);
                                    }}
                            >Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/*modal end*/}

        </>
    )
});

export default ChecklistImages;

