import React, {useRef, useEffect} from "react";
import WebViewer from "@pdftron/webviewer";
import {useSelector} from "react-redux";
import {getLocalToken, getLocalUser} from "../utils/storage";



let Home = () => {

    const token = getLocalToken();
    const user = getLocalUser();
    const viewer = useRef(null);
    const currentUser = useSelector(state => state.currentUSer)



    useEffect(() => {
        WebViewer({path: 'webviewer/lib', initialDoc: '/assets/pdf/demoPdf.pdf'},
            viewer.current).then(instance => {

        })
    }, []);
    return (
        <>
            <div className="MyComponent">
                <div className="header">React sample</div>
                {token ? <div className="webviewer" ref={viewer} style={{height: "100vh"}}></div> : ''}
            </div>
        </>
    );
}

export default Home;