import { postRequest, getRequest } from '../../services/httpService';

/**
 * @description Login User
 * @param  {} body
 */
export const loginUser = async (body) => {
    try {
        let response = await postRequest('login', body);
        return response;
    } catch (error) {
        return error;
    }
};

/**
 * @description Login User
 * @param  {} body
 */
export const registerUser = async (body) => {
    try {
        let response = await postRequest('register', body);
        return response;
    } catch (error) {
        return error;
    }
};

/**
 * @description Login User
 * @param  {} body
 */
export const verifyUserToken = async (token) => {
    try {
        let response = await postRequest('verify', {}, token);
        return response;
    } catch (error) {
        return error;
    }
};

/**
 * @description Get All PMS Company List
 * @param  {} body
 */
export const getAllPMSCompanies = async () => {
    try {
        let response = await getRequest('pms-companies');
        return response;
    } catch (error) {
        return error;
    }
};