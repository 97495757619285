import React, {useMemo, useRef, useState} from "react";

import AnnualReportModalView from "../reports/AnnualReportModalView";
import {useSelector} from "react-redux";
import {getCurrentCompany} from "../../utills/Common";
import {uploadNotesDocument, uploadNotesDocumentLink} from "../../actions/Notes/Notes";
import {useHistory} from "react-router-dom";
import {toast} from "react-hot-toast";
//import {getLocalToken, getLocalUser} from "../../utils/storage";

let UploadNotesDocument = (props) => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const tabClose = props.tabClose;

   /* const fileInputRef = useRef(null);*/
    {/*this is modal for Notes/resources upload Reuseable*/
    }

    const [docUploadInputs, setDocUploadInputs] = useState({});
    const history = useHistory();

    const [projectDetails, setCurrentProjectDetails] =useState({});
    const [projectId, setProjectId] =useState(0);
    const [uploadingState, toggleUploadingState] = useState(false);
    const [modalInputFile, setModalInputFile] = useState(null);

    const [showError, setShowError] = useState({name1: '', name2: '', url: '', file: '', proper: ''});

    const [tabIndex, setTabIndex] = useState(0);
    const alert = toast;


    const getCurrentCompanyDetails = async () => {
        let projectDetails = await getCurrentCompany();

        await setCurrentProjectDetails(projectDetails);
        if (projectDetails.id) {
            await setProjectId(projectDetails.id);
            return projectDetails.id
        }
        return null;
    };


    const fileInput = useRef(null);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e.dataTransfer.files;
        if (files.length > 0) {
            handleFileSelect(files);
        }
    };

    const handleClick = () => {
        fileInput.current.click();
    };

    const handleFileSelect = (files) => {
        onChangeDocInputs("file", files[0]);
        if (files && files[0]) {
            setModalInputFile((files[0].name));
        }
    };


    React.useEffect( () => {

        getCurrentCompanyDetails();


    }, [user]);

    const onChangeDocInputs = (key, value) => {
        let fileUploads = docUploadInputs;
        fileUploads[key] = value;
        setDocUploadInputs(fileUploads);
    };


    const handleUploadImage = async () => {
        if ((docUploadInputs.name1 && docUploadInputs.file) || (docUploadInputs.name2 && docUploadInputs.url)) {
            toggleUploadingState(true);
            try {
                if ('url' in docUploadInputs && docUploadInputs.url && docUploadInputs.url !== "") {
                    if (docUploadInputs.url) {
                        if (ValidURL(docUploadInputs.url) === false) {
                            alert.error('enter Valid URL')
                            setShowError({proper: 'Enter Valid Url'});
                        }
                    }

                    let formData = new FormData();
                    formData.append("link", docUploadInputs.url);
                    formData.append("name", docUploadInputs.name2);

                    let response = await uploadNotesDocumentLink(projectDetails.id, formData, token);

                    if (response.data.success) {
                        toggleUploadingState(false);
                        history.push("/notes/view/" + response.data.data.id);
                        //toggleReload(!reload);
                        onChangeDocInputs("name2", null);
                        onChangeDocInputs("url", null);
                        setDocUploadInputs({});
                        fileInput.current.value='';
                        tabClose();
                        alert.success(response.data.message);
                    } else {
                        toggleUploadingState(false);
                        alert.error(response.data.message);
                    }

                } else {
                    // upload image here
                    let formData = new FormData();
                    console.log(docUploadInputs);
                  formData.append("file", docUploadInputs.file);
                  formData.append("name", docUploadInputs.name1);
                    console.log(formData);
                    let response = await uploadNotesDocument(projectDetails.id, formData, token);
                    console.log(response);
                    if (response.data.success) {
                        toggleUploadingState(false);
                        history.push("/notes/view/" + response.data.data.id);
                       // toggleReload(!reload);
                        onChangeDocInputs("name1", null);
                        onChangeDocInputs("file", null);
                        tabClose();
                        alert.success(response.data.message);
                    } else {
                        toggleUploadingState(false);
                        alert.error(response.data.message);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            toggleUploadingState(false);
            if (tabIndex === 0) {
                if (!docUploadInputs.name1) {
                    alert.error("Name is required")
                    setShowError({name1: 'Name is required'});
                }
                if (!docUploadInputs.file) {
                    alert.error("file is  required");
                    setShowError({file: 'file is required'});
                }
            } else {
                if (!docUploadInputs.name2) {
                    alert.error("Name is required")
                    setShowError({name2: 'Name is required'});
                }
                if (!docUploadInputs.url) {
                    alert.error("url is required");
                    setShowError({url: 'Url is required'});
                }
            }

        }
    }


    function ValidURL(str) {
        let regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
        if (!regex.test(str)) {
            alert.error("Please enter valid URL.");
            return false;
        } else {
            return true;
        }
    }

   useMemo(()=>{
     setModalInputFile(null);
    },[props.data])



    return(
        <>

            <ul className="nav mb-3  justify-content-between d-flex nav-pills nav-fill" id="pills-tab" role="tablist">
                <li className="nav-item col-4 text-center" role="presentation">
                    <div className="tabButton active" id="pills-home-tab" data-bs-toggle="pill"
                         data-bs-target="#pills-home" type="tab" role="tab" aria-controls="pills-home"
                         aria-selected="true" >Upload file
                    </div>
                </li>
                <li className="nav-item col-4 text-center" role="presentation">
                    <div className="tabButton" id="pills-profile-tab" data-bs-toggle="pill"
                         data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile"
                         aria-selected="false">Upload link
                    </div>
                </li>
                <li className="nav-item col-4 text-center" role="presentation">
                    <div className="tabButton" id="pills-contact-tab" data-bs-toggle="pill"
                         data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"
                         aria-selected="false">Select Report
                    </div>
                </li>
            </ul>


            {/* ------ Upload from files-------*/}
            <div className="tab-content " id="pills-tabContent">
                <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                     aria-labelledby="pills-home-tab">

                    <div className='px-4 py-2' onClick={handleClick}>

                        <div
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDrop={handleDrop}
                            className=' w-100 outline-none rounded-2 mt-3 d-flex'
                            style={{
                                border: "1px dashed #d76005",
                                height: '10rem',
                                justifyContent: 'center',
                                backgroundColor: '#fffaf3', color: '#d76d00'
                            }}
                        >



                            {modalInputFile == null ?
                                <div  className='text-center d-flex align-self-center'>
                                    "Drag and drop files here or click to select files." </div> :
                                <div
                                    className='w-100 align-items-center justify-content-center d-flex flex-column'>
                                    <img className='m-2' style={{width: "4rem"}}
                                         src="./assets/icons/pdf-svgrepo-com.svg"/>
                                    <span className='p-1'>{modalInputFile}</span>
                                </div>}
                            <input
                                type="file" id="filesInput"
                                ref={fileInput}
                                name="imgFile"
                                style={{display: 'none'}}
                                onChange={(e) => {
                                    handleFileSelect(e.target.files)
                                }}
                            />

                        </div>




                       {/* <input className=' w-100 outline-none rounded-2 mt-3 border-0'
                               required
                               style={{backgroundColor: '#fffaf3', height: '49px', color: '#d76d00'}}
                               type="file" id='file'
                               name={"file"}
                               accept={'.pdf'}
                               onChange={(e) => {
                                   onChangeDocInputs("file", e.target.files[0])
                               }}/>*/}

                        <input className=' w-100 outline-none rounded-2 mt-3'
                                type='text' id="nameFile"
                               placeholder='Enter File Name'
                               name="name1"
                               onChange={(e) => {
                                   onChangeDocInputs("name1", e.target.value)
                               }}
                               style={{
                                   border: '0.5px solid #ebebeb',
                                   backgroundColor: '#fafafa',
                                   height: '49px'
                               }}></input>
                    </div>

                    <div className='w-100 d-flex justify-content-center p-2'>

                        <button className='btn btn-primary my-3'
                                style={{width: '8.5rem'}}
                                onClick={() => {
                                    handleUploadImage();
                                }}
                        > Save
                        </button>
                    </div>

                </div>

                {/*--------upload pdf link------- */}
                <div className="tab-pane fade" id="pills-profile" role="tabpanel"
                     aria-labelledby="pills-profile-tab">
                    <div className='px-4 py-2'>
                        <input className=' w-100 outline-none rounded-2 mt-3 border-0'
                               required
                               style={{backgroundColor: '#fffaf3', height: '49px', color: '#d76d00'}}
                               id="url" variant='outline' placeholder='Enter PDF URL Link' type={'url'}
                               name={"url"}
                               onChange={(e) => {
                                   onChangeDocInputs("url", e.target.value)
                               }}/>

                        <input className=' w-100 outline-none rounded-2 mt-3'
                               placeholder='Enter File Name'
                               name="name2" id="urlNameInput"
                               onChange={(e) => {
                                   onChangeDocInputs("name2", e.target.value)
                               }}
                               style={{
                                   border: '0.5px solid #ebebeb',
                                   backgroundColor: '#fafafa',
                                   height: '49px'
                               }}></input>
                    </div>

                    <div className='w-100 d-flex justify-content-center p-2'>

                        <button className='btn btn-primary my-3'
                                style={{width: '8.5rem'}}
                                onClick={handleUploadImage}
                        > Save
                        </button>
                    </div>

                </div>


                {/*------select from annual report------- */}
                <div className="tab-pane fade" id="pills-contact" role="tabpanel"
                     aria-labelledby="pills-contact-tab"><AnnualReportModalView tabClose={tabClose}/>
                </div>
            </div>
        </>
    )
}

export default UploadNotesDocument;