import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Formik, Form, Field} from "formik";
import {getCurrentCompany} from "../../../utills/Common";
import {getChecklistTemplates} from "../../../actions/CheckList/templates";
import {GrClose} from "react-icons/gr";
import {log} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";
import {toast} from "react-hot-toast";


let CheckListCreateModal = (props) => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const onNewCreate = props.onNewCreate;
    const openTemplatePreview = props.openTemplatePreview;
    const onImportTemplate = props.onImportTemplate;
    const onDismiss = props.onDismiss;
    const clearState = props.clearState;
    const [projectDetails, setCurrentProjectDetails] = useState({});
    const [projectId, setProjectId] = useState(0);
    const [templatesList, setTemplatesList] = useState([]);
    const [checklistTopicName, setChecklistTopicName] = useState({});
    const alert = toast;




    useEffect(() => {
        setChecklistTopicName({});

        getTemplateList();
    }, []);

    const getCurrentCompanyDetails = async () => {
        let projectDetails = await getCurrentCompany();

        await setCurrentProjectDetails(projectDetails);
        if (projectDetails.id) {
            await setProjectId(projectDetails.id);
            return projectDetails.id
        }
        return null;
    };

    const getTemplateList = async () => {
        let response = await getChecklistTemplates(token);

        if (response.data.success) {
            if (response.data.data.length > 0) {
                setTemplatesList(response.data.data);
            }
        } else {
            if (response.data.message) {
                alert.error(response.data.message)
            }
        }
    };



    const clearInput=()=>{

        setChecklistTopicName({});
        clearState();
    }



    React.useEffect(() => {
        let id = getCurrentCompanyDetails();

    }, [user]);

    function validateField(name, value) {
        let error;
        switch (name) {
            case 'name':
                if (value === null || value === '') {
                    error = 'Name is required'
                }
                return error;
            case 'extra_details':
                if (value === null || value === '') {
                    error = 'Description is required'
                }
                return error;
            default:
                return error;

        }
    }

    const validateName = (value) => validateField('name', value);
    const validateDescription = (value) => validateField('extra_details', value);
    const validateChecklistTemplateName = (value) => validateField('name', value);
    const validateChecklistTemplateId = (value) => validateField('template_id', value);
    const [value, setValue] = React.useState('1')


    const validateForm = values => {
        const errors = {};
        if (!values.name) {
            errors.name = 'Name is required';
        } else if (values.name.length > 50) {
            errors.name = 'limit of characters reached';
        }


        if (!values.content) {
            errors.name = 'description is required';
        } else if (values.name.length > 100) {
            errors.name = 'limit of character reached';
        }

        return errors;
    };


    return <>
        <ul className="nav nav-pills mb-3  justify-content-between d-flex" id="myTab" role="tablist">
            <li className="nav-item w-50" role="presentation">
                <div className="tabButton active text-center" id="createChecklist" data-bs-toggle="tab"
                     data-bs-target="#home" type="button" role="tab" aria-controls="home"
                     aria-selected="true">Blank checklist
                </div>
            </li>
            <li className="nav-item w-50" role="presentation">
                <div className="tabButton text-center" id="selectTemplate" data-bs-toggle="tab"
                     data-bs-target="#profile" type="button" role="tab" aria-controls="profile"
                     aria-selected="false">Create checklist template
                </div>
            </li>
        </ul>


        {/*create new checklist Tab*/}
        <div className="tab-content">
            <div className="tab-pane active" id="home" role="tabpanel" aria-labelledby="createChecklist">

               {/* <Formik
                    initialValues={{name: '', content: '', extra_details: ''}}
                    onSubmit={(values, {setSubmitting}) => {
                        setTimeout(async () => {
                            //alert(JSON.stringify(values, null, 2));
                            await onNewCreate(values);
                            setSubmitting(false);

                            onDismiss();
                        }, 1000);
                    }}
                    validate={validateForm}
                >
                    {(formik, isSubmitting) => (
                        <Form className='px-4'>
                            <div className="form-group p-2">
                                   <label className='fw-semibold' htmlFor="name">Name</label>
                                <Field name="name" placeholder='name' id="nameCheckTopic"
                                       className={(formik.touched.name && formik.errors.name) ? 'form-control is-invalid' : 'form-control'}
                                       type="text"/>

                                {formik.touched.name && formik.errors.name ? (
                                    <div className="invalid-feedback">{formik.errors.name}</div>
                                ) : null}

                            </div>
                            <div className="p-2">
                                      <label className='fw-semibold' htmlFor="content">Description</label>
                                <Field
                                    id="descCheckTopic"
                                    placeholder='Description'
                                    name="content" className="form-control" as="textarea" rows={3} cols={10}/>

                                {formik.touched.content && formik.errors.content ? (
                                    <div className="invalid-feedback">{formik.errors.content}</div>
                                ) : null}
                            </div>


                            <div className="form-group text-center p-2 pt-4">

                                <button type="submit" className="btn btn-primary "
                                        disabled={isSubmitting}>{isSubmitting ? "Please wait..." : "Submit"}</button>
                            </div>

                        </Form>
                    )
                    }
                </Formik>*/}

                <div className="modal-body">


                    <div className='p-2'>


                        <input className=' w-100 outline-none rounded-2 ' id='addChecklistNameId'
                               placeholder='Enter Name' required
                               style={{backgroundColor: '#fffaf3', height: '49px', color: '#d76d00',border: '0.5px solid #ebebeb'}}
                               type="text" id="nameTopic"
                               name="name"
                               onChange={(e) => {
                                   let formData = checklistTopicName;
                                   formData.name = e.target.value;
                                   setChecklistTopicName(formData);
                               }}/>


                        <input className=' w-100 outline-none rounded-2 mt-3' id='addChecklistDescId'
                               placeholder='Enter Description'
                               name="description" id="descTopic"
                               onChange={(e) => {
                                   let formData = checklistTopicName;
                                   formData.extra_details  = {description : e.target.value}
                                   setChecklistTopicName(formData);
                               }}
                               style={{
                                   border: '0.5px solid #ebebeb',
                                   backgroundColor: '#fafafa',
                                   height: '49px'
                               }}/>
                    </div>

                    <div className='w-100 d-flex justify-content-center p-2'>

                        <button className='btn btn-primary mt-4'
                                style={{width: '8.5rem'}}
                                onClick={async () => {
                                    let name = document.getElementById("nameTopic").value;
                                    if (name === ''){
                                        setChecklistTopicName({})
                                        alert.error('Name is required')

                                    }
                                    else {
                                        let res =  await onNewCreate(checklistTopicName)
                                        if (res==true){
                                            clearInput()
                                        }

                                    }


                                }}
                        > Submit
                        </button>
                    </div>
                </div>
            </div>

            {/*Create From Template*/}
            <div className="tab-pane px-4 overflow-scroll" style={{height: '50vh'}} id="profile" role="tabpanel"
                 aria-labelledby="selectTemplate">

                {
                    templatesList && templatesList.length > 0
                        ? templatesList.map((row, index) => (
                            <div className='m-2 d-flex shadow-sm justify-content-between rounded-1' key={index}>
                                <div className='align-self-center d-flex'>

                                    <i className='ri-list-check-2 p-2 '
                                       style={{fontSize: '20px', verticalAlign: 'middle'}}></i>

                                    <p className='p-2 align-middle align-self-center'>{row.name}</p>
                                </div>
                                <div className='d-flex'>
                                    <button className='btn btn-primary mx-2 my-3  text-center fw-semibold'
                                            onClick={() => {
                                                onImportTemplate(row.id);
                                            }}
                                            style={{fontSize: '11px'}}>Import
                                    </button>
                                    <button className='btn btn-outline-primary  mx-2 my-3 text-center fw-semibold'

                                            onClick={() => {
                                                onDismiss();
                                                openTemplatePreview(row.id);
                                            }}
                                            style={{fontSize: '11px'}}>Preview
                                    </button>
                                </div>

                            </div>
                        )) : <>No Templates Found</>}
            </div>
        </div>
    </>
}

export default CheckListCreateModal;