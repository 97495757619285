import axios from 'axios';
import {getRequest} from "../../services/httpService";

/**
 * @description Get Company's All Annual Reports
 * @param  {} companyCode
 */
export const getAnnualReports = async (companyCode) => {
    try {
        return await getRequest(`resources/annual_reports/${companyCode}`);
    } catch (error) {
        return error;
    }
};

/**
 * @description Get Quarterly & Other Reports
 * @param  {} strCat
 * @param  {} strPrevDate
 * @param  {} strToDate
 * @param  {} strScrip
 * @param  {} strType
 */
export const getQuarterlyAndOtherReports = (strCat, strPrevDate, strToDate, strScrip, strType) => {
    return new Promise((resolve, reject) => {
        try {
            axios.get(`https://api.bseindia.com/BseIndiaAPI/api/AnnGetData/w?strCat=${strCat}&strPrevDate=${strPrevDate}&strToDate=${strToDate}&strScrip=${strScrip}&strSearch=P&strType=${strType}`, {
                headers: {
                    "Origin": "https://www.bseindia.com",
                    "Referer": "https://www.bseindia.com/",
                    "User-Agent": "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/98.0.4758.87 Safari/537.36"
                }
            }).then(response => {
                resolve(response);
            });
        } catch (error) {
            reject(error);
        }
    })
};