import React, {useCallback, useEffect, useMemo, useState} from "react";
import {getProjectById} from "../../actions/Projects";
import {useDispatch} from "react-redux";
import {Link, useHistory} from 'react-router-dom';
import {getLocalToken} from "../../utils/storage";
import {getCurrentCompany} from "../../utills/Common";
import search from "../../Redux/Action/search";

let ProjectHeader = () => {
    let currentUrl = window.location.href;
    const [title, setTitle] = useState({name: '', sub: ''})
    const [currentCompany, setCurrentCompany] = useState({});
    const [currentProjectId, setCurrentProjectId] = useState({});
    const [currentProject, setCurrentProject] = useState({});
    const [flag, changeFlag] = useState(false);
    const History = useHistory();
    const dispatch = useDispatch();

    const getCurrentProjectDetails = () => {
        let projectDetails = getCurrentCompany();
        setCurrentProject(projectDetails);
    };

    const handleChange = (e) => {
        dispatch(search.setSearch(e.target.value))
    }

    useEffect(() => {
        getCurrentProjectDetails();
    }, []);

    const headingName = useMemo(() => {
        return (
            <>
                <div>
                    <h1 className='fs-6 fw-semibold mb-1 text-capitalize'>{currentProject.name ? currentProject.name : ""}</h1>
                </div>
            </>
        )

    }, [currentProject]);

    const headingCompany = useMemo(() => {
        return (
            <>
             <span className='fs-7 fw-semibold text-capitalize' style={{
                 textOverflow: 'ellipsis',
                 overflow: 'hidden',
             }}>{currentProject.company && currentProject.company.name ? currentProject.company.name : ''}</span>

            </>
        )
    }, [currentProject]);

    const headingIndustry = useMemo(() => {
        return (
            <>
                    <span
                        className='fs-7 text-capitalize'>{currentProject.company && currentProject.company.industry ? currentProject.company.industry.name : ''}</span>
            </>
        )

    }, [currentProject])

    return (
        <>

            <div className='d-flex justify-content-between p-4 align-items-center border-bottom'
                 style={{height: '100px'}}>
                <div className='d-flex align-items-center'>
                    <button
                        onClick={() => {
                            History.push('/admin/my-projects');
                        }}
                        className='btn-hover-icon-white btn btn-outline-dark px-4 py-2 me-4 flex-nowrap d-flex align-items-center me-4'
                        style={{borderRadius: '500px'}}>
                        <svg width="16" height="8" viewBox="0 0 16 8" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M14.696 4.79053C14.9722 4.79053 15.196 4.56667 15.196 4.29053C15.196 4.01438 14.9722 3.79053 14.696 3.79053L14.696 4.79053ZM0.160137 3.93698C-0.0351248 4.13224 -0.0351248 4.44882 0.160137 4.64408L3.34212 7.82606C3.53738 8.02132 3.85396 8.02132 4.04923 7.82606C4.24449 7.6308 4.24449 7.31422 4.04923 7.11896L1.2208 4.29053L4.04922 1.4621C4.24449 1.26684 4.24449 0.950257 4.04922 0.754994C3.85396 0.559732 3.53738 0.559732 3.34212 0.754994L0.160137 3.93698ZM14.696 3.79053L0.513691 3.79053L0.513691 4.79053L14.696 4.79053L14.696 3.79053Z"
                            />
                        </svg>

                        <span>Back</span>
                    </button>
                    {headingName}

                    <div
                        className='d-flex align-items-center bg-light bg-gradient p-2 rounded ms-2'>
                        <img className='rounded me-3 ms-2'
                             style={{width: "30px", height: "30px"}}
                             src='./assets/images/Zebralogo.png'/>
                        <div className='d-flex flex-column'
                             style={{whiteSpace: 'nowrap', overflow: 'hidden'}}>
                            {headingCompany}
                            {headingIndustry}

                        </div>
                    </div>
                </div>
                <div className='border rounded py-2 d-flex' style={{width: "375px"}}>
                    <img className='px-3' type='button' src='./assets/icons/search glass icon.svg'/>
                    <input className='border-0 outline-none py-1 w-100' placeholder='Search Project Name' id="myInput"
                           onChange={handleChange}/>
                    <img className=" align-self-center pe-2" type='reset' value='Reset' src="assets/icons/delete.svg"
                         onClick={() => {
                           document.getElementById('myInput').value = '';
                           dispatch(search.setSearch(''));
                       }}/>
                </div>
            </div>
        </>
    );
}

export default ProjectHeader;