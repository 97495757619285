import React, {useRef, useState} from "react";
import {Link, NavLink, useHistory} from "react-router-dom";
import AdminRoutes from "../../route-lists/AdminRoutes";
import {removeUserSession} from "../../utills/Common";
import {useSelector, useDispatch} from "react-redux";

import 'remixicon/fonts/remixicon.css';
import "../../styles/main.css";
import "./sidebar.css";
import {sidebarWidthControl} from "../../Redux/Action";
import {createProject, getAllCompanies, getAllUserProjects, updateProjectDescription} from "../../actions/Projects";
import {getLocalToken, getLocalUser} from "../../utils/storage";
import {toast} from "react-hot-toast";
import Select from "react-select";
import {refreshToggle} from "../../Redux/Action/refreshToggle";

let Sidebar = () => {
    //token
    const token = getLocalToken();
    const user = getLocalUser();

    const sidebarToggle = useSelector((state => state.changeTheSidebar));
    const dispatch = useDispatch();

    //state start
    const [options, setOptions] = useState([]);
    const [companyId, setCompanyId] = useState(null);
    const [projectName, setProjectName] = useState("");
    const [myProjects, setMyProjects] = useState([]);
    const currentUser = useSelector(state => state.currentUser);
    const [description, setDescription] = useState('');

    const alert = toast;


    //    Alert
    const [alertProp, setAlertProp] = useState({
        display: "d-none",
        status: "success",
        message: "Added Successfully",
        icon: <></>
    })


    //state end

    const History = useHistory();

    const onSidebarWidthChange = () => {
        dispatch(sidebarWidthControl())
    }

    const logout = async () => {
        await removeUserSession();
        History.push("/login");
    };


    // Modals start

    const getAllCompany = async (name) => {
        let response = await getAllCompanies("Active", "Equity", name);
        if (response.data.data && response.data.data.length > 0) {
            setOptions(response.data.data);
        } else {
            setOptions([]);
        }
    };

    const filterOptions = (inputValue) => {
        (async () => {
            await getAllCompany(inputValue);
        })();
    };

    const onChangeCompany = (company) => {
        if (company) {
            let selectedCompanyId = company.id;
            setCompanyId(selectedCompanyId);
        }

    };

    const updateDescription = async (projectID, desc) => {
        let response = await updateProjectDescription(projectID, {description: desc}, token);

    };


    const onSubmitCreateProject = async () => {

        if (companyId && projectName) {

            let response = await createProject({company_id: companyId, name: projectName}, token);

            if (response.data.success) {
                //if description added then adding it in project
                if (description != '') {
                    await updateDescription(response.data.data.id, description);
                }
                document.getElementById('closeModalOne').click();
                dispatch(refreshToggle());
                alert.success(response.data.message);

            } else {
                alert.error(response.data.message);

            }
        } else {
            if (!companyId) {
                alert.error("Please select any company");

            }

            if (!projectName) {
                alert.error("Project name is required");
            }
        }

    };
    // Modals end


    //use Effect
    React.useEffect(() => {
        getAllCompany('');
    }, []);


    return (
        <>
            <div
                className={`alert alert-${alertProp.status} d-flex ${alertProp.display} align-items-center position-absolute m-4 end-0 top-0`}
                role="alert">

                {alertProp.icon}

                <div>
                    {alertProp.message}
                </div>
            </div>

            <div
                className='overflow-hidden d-flex flex-column justify-content-between bg-secondary border-end vh-100 px-3 py-4'
                style={{
                    width: sidebarToggle === true ? '230px' : '85px',
                    transition: 'width 0.2s ease 0s',
                    whiteSpace: 'nowrap'
                }}>

                <div>
                    <div
                        className={`d-flex ${`fw-semibold ${sidebarToggle ? 'justify-content-between' : 'justify-content-center'}`} align-items-center mb-4 pb-2`}>
                        <div><img className='me-2' src='./assets/icons/zebrapro logo /Zebralogo.svg'/><img
                            className={`${(sidebarToggle === true) ? '' : 'd-none'}`}
                            src='./assets/icons/zebrapro logo /zebrapro logo name.svg'/></div>
                        <img className='shadow-sm rounded position-absolute' onClick={onSidebarWidthChange}
                             type='button'
                             style={{
                                 transform: (sidebarToggle == true) ? 'rotate(0deg)' : 'rotate(180deg)',
                                 marginRight: (sidebarToggle == true) ? '' : '-40px',
                                 left: (sidebarToggle == true) ? '217px' : '72px',
                                 transition: ' 0.2s ease 0s'
                             }}
                             src='./assets/icons/sidebar side controller.svg'/>
                    </div>
                    <button data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                            className='btn btn-outline-primary mb-4 fw-semibold w-100 overflow-hidden'>+ {(sidebarToggle === true) ? 'Create new project' : ''}</button>


                    <ul className='list-unstyled d-grid gap-1 '>
                        {
                            AdminRoutes.admin.map((i, index) => {
                                return (
                                    <NavLink key={index} to={`${i.layout}${i.path}`} activeClassName="activeOption"
                                             className={`${(sidebarToggle === true) ? 'ps-1' : 'justify-content-center'}  d-flex align-items-center text-decoration-none text-black py-1 rounded`}>{i.icon}
                                        <span
                                            className={`fw-semibold ${(sidebarToggle === true) ? 'd-block ps-1' : 'd-none'}`}>{i.name}</span>
                                    </NavLink>
                                )
                            })
                        }

                    </ul>

                </div>
                <div>
                    <div className='d-flex p-2 rounded-2 mb-2'
                         style={{backgroundColor: sidebarToggle === true ? '#FFECD1' : ''}}>
                        <div className='position-relative d-flex align-items-center'
                             style={{marginRight: (sidebarToggle == true) ? '30px' : '0',}}>
                            <img className='rounded-circle border border-white border-4 rounded-circle '
                                 style={{width: (sidebarToggle == true) ? '40px' : '25px'}}
                                 src='./assets/images/Zebralogo.png'/>
                            <img
                                className='rounded-circle border border-white border-4 rounded-circle position-absolute start-50'
                                style={{width: (sidebarToggle == true) ? '40px' : '25px'}}
                                src='./assets/images/profile person photo.png'/>
                        </div>
                        <div className={`d-flex flex-column ${(sidebarToggle === true) ? 'd-block' : 'd-none'}`}
                             style={{whiteSpace: 'nowrap', overflow: 'hidden',}}>
                            <span className='cursor-pointer fs-6 text fw-semibold mb-0'
                                  style={{textOverflow: 'ellipsis', overflow: 'hidden',}}
                                  title={getLocalUser().name}>{getLocalUser().name}</span>
                            <span className='cursor-pointer fs-6'
                                  style={{textOverflow: 'ellipsis', overflow: 'hidden',}}
                                  title={getLocalUser().email}>{getLocalUser().email}</span>
                        </div>
                    </div>

                    <button className='logout-btn btn btn-outline-primary w-100 fw-semibold' onClick={logout}>
                        {sidebarToggle === true ? <span>Sign Out</span> :
                            <svg className='btn-hover-white' fill='#D76D00' xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 24 24" width="20"
                                 height="20">
                                <path fill="none" d="M0 0h24v24H0z"/>
                                <path
                                    d="M5 11h8v2H5v3l-5-4 5-4v3zm-1 7h2.708a8 8 0 1 0 0-12H4A9.985 9.985 0 0 1 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.985 9.985 0 0 1-8-4z"/>
                            </svg>
                        }
                    </button>
                </div>
            </div>


            {/*   Create Project Modals   */}
            <div className="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered  p-2">
                    <div className="modal-content p-3">
                        <div className="modal-header border-bottom border-0 p-2">
                            <input value={projectName} className="modal-title w-100 border-0 outline-none"
                                   placeholder='Enter your project name' id="staticBackdropLabel"
                                   onChange={(e) => {
                                       setProjectName(e.target.value)
                                   }}/>
                            <button id='closeModalOne' type="button" className="border-0 bg-none"
                                    data-bs-dismiss="modal"
                                    aria-label="Close" onClick={() => {
                                setProjectName("");
                                setCompanyId("");
                                setDescription("");
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                                    <path fill="none" d="M0 0h24v24H0z"/>
                                    <path
                                        d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/>
                                </svg>
                            </button>
                        </div>
                        <div className="">
                            <Select
                                className=" my-3"
                                defaultValue={options[0]}
                                isClearable
                                options={options}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onInputChange={filterOptions}
                                onChange={(e) => {
                                    onChangeCompany(e)
                                }}
                            />

                            <textarea className="form-control mb-3 " placeholder="Description or Type"
                                      id="floatingTextarea2"
                                      onChange={(e) => {
                                          setDescription(e.target.value);
                                      }}
                            ></textarea>

                        </div>
                        <div
                            className="modal-footer border-0 d-flex flex-column flex-sm-row justify-content-sm-between justify-content-center">
                            <div className="d-flex align-items-center flex-wrap">
                                <span className="fs-2 mx-1">#</span>
                                <span
                                    className="badge mx-1 bg-primary fs-6 py-2 bg-opacity-25 text-primary fw-semibold">Primary</span>
                                <span
                                    className="badge mx-1 bg-success fs-6 py-2 bg-opacity-25 text-success fw-semibold">Primary</span>
                            </div>
                            <button type="button" className="btn btn-primary fw-semibold " onClick={() => {
                                onSubmitCreateProject();
                            }}>Create Project
                            </button>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}
export default Sidebar;
