import { postRequest, getRequest } from '../../services/httpService';

/**
 * @description Get My All Import Links By Project Id
 * @param  {} body
 */
export const getAllResourceLinks = async(token, projectId, searchedValue = '') => {
    try {
        let response = await getRequest(`resources/links/${projectId}?name=${searchedValue}`, token);
        return response;
    } catch (error) {
        return error;
    }
};

/**
 * @description Create Import Links By Project Id
 * @param  {} body
 */
export const createResourceImportLinks = async(projectId, body, token) => {
    try {
        let response = await postRequest(`resources/links/${projectId}`, body, token);
        return response;
    } catch (error) {
        return error;
    }
};