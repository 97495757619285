import {deleteRequest, getRequest, patchRequest, postRequest} from '../../services/httpService';

/**
 * Create Checklist Template
 * @param projectId
 * @param body
 * @param token
 * @returns {Promise<*>}
 */
export const createNewChecklistFromTemplate = async (projectId, body, token) => {
    try {
        return await postRequest(`checklist/templates/${projectId}`, body, token);
    } catch (error) {
        return error;
    }
};

/**
 * Get Checklist Templates List
 * @param token
 * @param nameSearch
 * @returns {Promise<*>}
 */
export const getChecklistTemplates = async (token, nameSearch = "") => {
    try {
        if (nameSearch !== "") {
            return await getRequest(`checklist/templates?name=${nameSearch}`, token);
        } else {
            return await getRequest(`checklist/templates`, token);
        }
    } catch (error) {
        return error;
    }
};

/**
 * Update Checklist Template
 * @param templateId
 * @param body
 * @param token
 * @returns {Promise<*>}
 */
export const updateChecklistTemplateName = async (templateId, body, token) => {

    try {
        return await patchRequest(`checklist/templates/${templateId}`, body, token);
    } catch (error) {
        return error;
    }
};

/**
 * Delete Checklist Template
 * @param templateId
 * @param token
 * @returns {Promise<*>}
 */
export const deleteChecklistTemplate = async (templateId, token) => {
    try {
        return await deleteRequest(`checklist/templates/${templateId}`, token);
    } catch (error) {
        return error;
    }
};