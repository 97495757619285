import {deleteRequest, getFileRequest, getRequest, patchRequest, postRequest} from '../../services/httpService';

/**
 * @description Get My All Notebooks
 * @param projectId Project ID
 * @param token
 * @param searchedValue
 */


export const getAllNotebooks = async (projectId, token, searchedValue = '') => {
    try {
        return await getRequest(`notebooks/${projectId}?name=${searchedValue}`, token);
    } catch (error) {
        return error;
    }
};
/**
 *
 * @param projectId
 * @param body
 * @param token
 * @returns {Promise<unknown>}
 */
export const createNotebook = async (projectId, body, token) => {
    try {
        return await postRequest(`notebooks/${projectId}`, body, token);
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param notebookId
 * @param body
 * @param token
 * @returns {Promise<unknown>}
 */
export const updateNotebook = async (notebookId, body, token) => {
    try {
        return await patchRequest(`notebooks/${notebookId}`, body, token);
    } catch (error) {
        return error;
    }
};

/**
 * Delete Notebook
 * @param id
 * @param token
 * @returns {Promise<*>}
 */
export const deleteNotebook = async (id, token) => {
    try {
        return await deleteRequest(`notebooks/${id}`, token);
    } catch (error) {
        return error;
    }
};

/**
 * @param documentId
 * @param token
 * @returns {Promise<unknown>}
 */
export const getNotebookPdf = async (documentId, token) => {
    try {
        return await getFileRequest(`notebooks/pdf/${documentId}`, token);
    } catch (error) {
        return error;
    }
};


//<editor-fold desc="Notes Content">
/**
 * Get List Of pages in notebook
 * @param notebookId
 * @param token
 * @returns {Promise<unknown>}
 */
export const getNotebookNotes = async (notebookId, token) => {
    try {
        return await getRequest(`notebooks/notes/${notebookId}`, token);
    } catch (error) {
        return error;
    }
};

/**
 * Get Notes Content HTML
 * @param notebookId
 * @param token
 * @returns {Promise<unknown>}
 */
export const getNotesContent = async (notebookId, token) => {
    try {
        return await getRequest(`notebooks/notes/get/${notebookId}`, token);
    } catch (error) {
        return error;
    }
};

/**
 * Create New Note page
 * @param notebookId
 * @param body
 * @param token
 * @param headers
 * @returns {Promise<unknown>}
 */
export const storeNotesContent = async (notebookId, body, token, headers) => {
    try {
        return await postRequest(`notebooks/notes/${notebookId}`, body, token, headers);
    } catch (error) {
        return error;
    }
};


/**
 * Update Notes Content
 * @param notebookId
 * @param body
 * @param token
 * @returns {Promise<unknown>}
 */
export const updateNotesContent = async (notebookId, body, token) => {
    try {
        return await patchRequest(`notebooks/notes/${notebookId}`, body, token);
    } catch (error) {
        return error;
    }
};


/**
 *
 * @param notebookId
 * @param body
 * @param token
 * @returns {Promise<unknown>}
 */
export const updateNotesProps = async (notebookId, body, token) => {
    try {
        return await patchRequest(`notebooks/notes/props/${notebookId}`, body, token);
    } catch (error) {
        return error;
    }
};

/**
 * Delete Notebook Page
 * @param id
 * @param token
 * @returns {Promise<*>}
 */
export const deleteNotebookPage = async (id, token) => {
    try {
        return await deleteRequest(`notebooks/notes/${id}`, token);
    } catch (error) {
        return error;
    }
};





