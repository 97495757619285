import changeTheSidebar from "./sidebar_control";



import {combineReducers} from "redux";
import currentUserReducer from "./currentUser";
import notesReducer from "./notes";
import search from "./search";
import checklist from "./checklist";
import RefreshToggle from "./refreshToggle";
import currentNotebookPageReducer from './notebook'

const rootReducer = combineReducers({
    changeTheSidebar: changeTheSidebar,
    currentUser :currentUserReducer,
    notes: notesReducer,
    checklist: checklist,
    search: search,
    refreshToggle: RefreshToggle,
    notebook: currentNotebookPageReducer
})


export default rootReducer;