import {useParams} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import notesActions from "../../Redux/Action/notes"
import Split from "react-split";
import PdfDocViewer from "../../component/Pdf/PdfDocViewer";
import CkEditorNotes from "./Notes/CkEditorNotes";
import HighlightsView from "./highlights/HighlightsView";
import MultilabelView from "./multilable/MultilabelView";
import AnnotationView from "./annotation/AnnotationView";
import SheetsView from "./sheet/SheetsView";
import CommentsView from "./comments/CommentsView";


let NotesView = (props) => {

    let {id} = useParams();

    const tabs = {
        NOTES: "notes",
        HIGHLIGHTS: "highlights",
        MULTI_LABEL: "multi_label",
        ANNOTATIONS: "annotations",
        SHEET: "sheet",
        COMMENTS: "comments"
    };

    const [isOpenNotes, onToggleNotes] = useState(true);
    const [isOpenHighlights, onToggleHighlights] = useState(false);
    const [isOpenMultiLabel, onToggleMultiLabel] = useState(false);
    const [isOpenAnnotations, onToggleAnnotations] = useState(false);
    const [isOpenComments, onToggleComments] = useState(false);
    const [isOpenSheet, onToggleSheet] = useState(false);
    const notesViewRef = useRef();
    const pdfViewerRef = useRef();
    const highlightsRef = useRef();
    const annotationsRef = useRef();
    const menuBtn = useRef();
    const [screenCapture, setScreenCapture] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const currentNotes = useSelector(state => state.notes);
    const [activeColor, setActiveColor] = useState('NOTES');
    const history = useHistory();

    const copyPdfText = (text) => {
        notesViewRef.current.copiedText(text);
    };

    const reloadHighlights = () => {
        highlightsRef.current.reload();
    };

    const reloadAnnotations = () => {
        annotationsRef.current.reload();
    };

    useEffect(() => {
        history.push("/notes/view/" + id);
        if (!currentNotes.tab) {
            dispatch(notesActions.tabChange(tabs.NOTES));
        } else {
            console.log("init notes view tab ", currentNotes.tab);
        }
    }, [id]);

    const followHighlight = (text, type) => {
        pdfViewerRef.current.showHighlightText(text, type);
    };

    const toggleTabButtons = (type) => {
        dispatch(notesActions.tabChange(type));
        console.log(type);
        onToggleHighlights(tabs.HIGHLIGHTS === type);
        onToggleMultiLabel(tabs.MULTI_LABEL === type);
        onToggleNotes(tabs.NOTES === type);
        onToggleAnnotations(tabs.ANNOTATIONS === type);
        onToggleComments(tabs.COMMENTS === type);
        onToggleSheet(tabs.SHEET === type);
    };

    return (
        <>
            <Split direction='horizontal' minSize={[30, 100]}
                   style={{display: 'flex', backgroundColor: '#efefef', width: '100%'}}>
                <div resize='horizontal'>
                    <div p={0}>
                        <div>
                            <PdfDocViewer ref={pdfViewerRef} documentId={id} copyPdfText={copyPdfText}
                                          reloadHighlights={reloadHighlights}
                                          reloadAnnotations={reloadAnnotations}> </PdfDocViewer>
                        </div>
                    </div>
                </div>

                <div className=' bg-white'>

                    {/*Notes View*/}
                    <div style={{display: isOpenNotes ? 'block' : 'none'}} className={'editor'} p={0} m={0}>
                        <CkEditorNotes documentId={id} ref={notesViewRef}> </CkEditorNotes>
                    </div>

                    {/*Highlights View*/}
                    <div style={{display: isOpenHighlights ? 'block' : 'none'}} className={'editor'} p={0} m={0}>
                        <HighlightsView ref={highlightsRef} onClickHighlight={followHighlight} documentId={id}/>
                    </div>

                    {/*Multilabel View*/}
                    <div style={{display: isOpenMultiLabel ? 'block' : 'none'}} className={'editor'} p={0} m={0}>
                        <MultilabelView ref={highlightsRef} onClickHighlight={followHighlight} documentId={id}/>
                    </div>

                    {/*Annotation View*/}
                    <div style={{display: isOpenAnnotations ? 'block' : 'none'}} className={'editor'} p={0} m={0}>
                        <AnnotationView ref={annotationsRef} onClickHighlight={followHighlight} documentId={id}/>
                    </div>

                    {/*Sheet View*/}
                    <div style={{display: isOpenSheet ? 'block' : 'none'}} className={'editor'} p={0} m={0}>
                        <SheetsView documentId={id}/>
                    </div>

                    {/*Comments View*/}
                    <div style={{display: isOpenComments ? 'block' : 'none'}} className={'editor'} p={0} m={0}>
                        <CommentsView documentId={id}/>
                    </div>


                    <div className="btn-group dropup position-absolute bottom-0 end-0 rounded-circle m-5 shadow">
                        <span type="button" data-bs-toggle="dropdown"
                              className='bg-white p-2 rounded-4' style={{borderBottom: '3px solid #d76d00'}}
                              aria-expanded="false">
                           <img src='/assets/icons/workAreaIcon/notesIcon.svg'
                                className='bg-white p-2 rounded-3'/>
                        </span>
                        <ul className="dropdown-menu border-0 shadow" style={{zIndex: '2'}}>
                            <li className={`dropdown-item py-2 px-3 rounded-4 ${activeColor === 'NOTES' ? 'activeBtn' : ''}`}
                                id="menuBtn"
                                onClick={() => {
                                    setActiveColor('NOTES');
                                    toggleTabButtons(tabs.NOTES);
                                }}>
                                <img src='/assets/icons/workAreaIcon/notes.png'/>
                                <text className='ps-2' style={{fontWeight: '500'}}>Note</text>
                            </li>
                            <li className={`dropdown-item py-2 px-3 rounded-4 ${activeColor === 'HIGHLIGHTS' ? 'activeBtn' : ''}`}
                                id="menuBtn"
                                onClick={() => {
                                    setActiveColor('HIGHLIGHTS');
                                    toggleTabButtons(tabs.HIGHLIGHTS);
                                }}>
                                <img src='/assets/icons/workAreaIcon/marker.png'/>
                                <text className='ps-2' style={{fontWeight: '500'}}>Marker</text>
                            </li>
                            <li className={`dropdown-item py-2 px-3 rounded-4 ${activeColor === 'MULTI_LABEL' ? 'activeBtn' : ''}`}
                                id="menuBtn"
                                onClick={() => {
                                    setActiveColor('MULTI_LABEL');
                                    toggleTabButtons(tabs.MULTI_LABEL);
                                }}>
                                <img src='/assets/icons/workAreaIcon/autoMarker.png'/>
                                <text className='ps-2' style={{fontWeight: '500'}}>Auto Marker</text>
                            </li>
                            <li className={`dropdown-item py-2 px-3 rounded-4 ${activeColor === 'ANNOTATIONS' ? 'activeBtn' : ''}`}
                                id="menuBtn"
                                onClick={() => {
                                    setActiveColor('ANNOTATIONS');
                                    toggleTabButtons(tabs.ANNOTATIONS);
                                }}>
                                <img src='/assets/icons/workAreaIcon/highlight.png'/>
                                <text className='ps-2' style={{fontWeight: '500'}}>Highlights</text>
                            </li>
                            <li className={`dropdown-item py-2 px-3 rounded-4 ${activeColor === 'SHEET' ? 'activeBtn' : ''}`}
                                id="menuBtn"
                                onClick={() => {
                                    setActiveColor('SHEET');
                                    toggleTabButtons(tabs.SHEET);
                                }}>
                                <img src='/assets/icons/workAreaIcon/excel.png'/>
                                <text className='ps-2' style={{fontWeight: '500'}}>Excel</text>
                            </li>
                            <li className={`dropdown-item py-2 px-3 rounded-4 ${activeColor === 'COMMENTS' ? 'activeBtn' : ''}`}
                                id="menuBtn"
                                onClick={() => {
                                    setActiveColor('COMMENTS');
                                    toggleTabButtons(tabs.COMMENTS);
                                }}>
                                <img src='/assets/icons/workAreaIcon/comment.png'/>
                                <text className='ps-2' style={{fontWeight: '500'}}>Discussion</text>
                            </li>
                        </ul>
                    </div>
                </div>


            </Split>

        </>
    )
}

export default NotesView;