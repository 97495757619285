import React, {useState, useEffect, useRef} from "react";
import 'remixicon/fonts/remixicon.css';
import {useSelector} from "react-redux";
import {
    deleteDriveFile,
    downloadDriveFileById,
    getAllDriveFiles,
    getAllDrives,
    uploadDriveFiles
} from "../../actions/FileDrive/FileDrives";
import {getCurrentCompany} from "../../utills/Common";
import truncateString from "../../utills/reusableFunction";
import {Link, useHistory} from "react-router-dom";
import {GrClose} from "react-icons/gr";
import {HiOutlineDownload} from "react-icons/hi";
import {MdOutlineDelete} from "react-icons/md";
import '../projects/hoverMenu.css';
import {toast} from "react-hot-toast";


let FileDrive = () => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const currentUser = useSelector(state => state.currentUser);
    const searchValue = useSelector(state => state.search);
    const [createFileUploads, setCreateFileUploads] = useState({});
    const [allUploadedResources, setAllUploadedResources] = useState([]);
    const [projectDetails, setCurrentProjectDetails] = useState({});
    const [projectId, setProjectId] = useState();
    const [modalInputFile, setModalInputFile] = useState();
    const [fileId, setFileId] = useState();
    const [isReload, setReload] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [showError, setShowError] = useState({name: '', file: ''});
    const history = useHistory();
    const alert = toast;
    let currentUrl = window.location.href;
    const fileElem = useRef();
    const nameElem = useRef();


    const getAllUploadedResources = async () => {
        if (projectId && projectId != null) {
            let response = await getAllDriveFiles(token, projectId, searchValue.value != '' ? searchValue.value : '');
            if (response.data.data && response.data.data.length > 0) {
                setAllUploadedResources(response.data.data);

            } else {
                setAllUploadedResources([]);
            }
        }
    }


    const getProjectDetails = async () => {
        let projectDetails = await getCurrentCompany();
        setCurrentProjectDetails(projectDetails);
        if (projectDetails.id) {
            await fetchDriveFiles(projectDetails.id);
            setProjectId(projectDetails.id);
        }
    }

    const fetchDriveFiles = async (projectId) => {
        let response = await getAllDrives(projectId, token);
        if (response.data.data && response.data.data.length > 0) {
            let fileData = response.data.data.map((report, index) => {
                report.key = index + 1;
                report.action = null;
                return report;
            });
            setAllUploadedResources(fileData);
        } else {
            setAllUploadedResources([]);
            // alert.error("No data found!");
        }
    }

    const deleteSingleDriveFile = async (filesId, tokenID) => {
        let response = await deleteDriveFile(filesId, tokenID);
        if (response.data.success) {
            document.getElementById('closeDeleteSure').click();
            alert.success(response.data.message);
            setReload(!isReload);
        } else {
            alert.error(response.message)
        }

    }


    const onChangeMyUpload = (key, value) => {
        let fileUpload = createFileUploads;
        fileUpload[key] = value;
        setCreateFileUploads(fileUpload);

    }

    const onSubmitCreateLinks = async () => {
        if (createFileUploads.name && createFileUploads.fileDrive) {
            let fileData = new FormData;
            fileData.append("file", createFileUploads.fileDrive);
            fileData.append("name", createFileUploads.name);
            let response = await uploadDriveFiles(projectDetails.id, fileData, token);
            setReload(!isReload);
            if (response.data.success) {
                await getAllUploadedResources(projectId);
                document.getElementById("addFileClose").click();
                alert.success(response.data.message);
                setCreateFileUploads({});
            } else {
                alert.success(response.data.message);
            }

        } else {
            if (!createFileUploads.name) {
                alert.error('Name Is Required');
                setShowError({name: "Name is required"});
            }
            if (!createFileUploads.fileDrive) {
                alert.error('Please Upload Document File');
                setShowError({file: "file is required"});
            }
        }

    }

    //download drive file
    const getDriveFile = async (files) => {
        let fileId = files.id;
        let filePath = files.path;
        let fileExtenstion = filePath.split('.').pop();
        let fileName = new Date().getTime();

        downloadDriveFileById(fileId, token).then(response => {
            if (response) {
                let url = window.URL.createObjectURL(new Blob([response.data], {type: files.type}));
                let link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', fileName + "." + fileExtenstion);

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            } else {
                alert.error("Something went wrong with file download")
            }
        });
    }

    const fileInput = useRef(null);

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const files = e.dataTransfer.files;
        if (files.length > 0) {
            handleFileSelect(files);
        }
    };

    const handleClick = () => {
        fileInput.current.click();
    };

    const handleFileSelect = (files) => {
        onChangeMyUpload("fileDrive", files[0]);
        if (files && files[0]) {
            setModalInputFile((files[0].name));
        }
    };

    useEffect(() => {
        getAllUploadedResources();
    }, [searchValue])

    useEffect(() => {
        try {
            getProjectDetails();
        } catch (e) {
            console.log(e);
        }
    }, [isReload]);


    function fileCard(row, index) {
        return (


            <div className='col-3 p-2' key={index}>
                <div className='shadow-sm rounded-4 p-0'
                     style={{backgroundColor: "#f6f6f6", border: "1px solid #eeeeee"}}>
                    <div className='d-flex flex-column'
                         style={{whiteSpace: 'nowrap'}}>
                    <span className='fs-7 p-2 d-flex fw-semibold text-capitalize justify-content-between'>
                       <div>
                           <img className='mx-3 m-2' style={{width: "30px"}}
                                src="./assets/icons/pdf-svgrepo-com.svg"/>
                           <span className='fs-7 fw-semibold  '>{truncateString(row.name, 18)}</span>
                       </div>
                         <div className="btn-group dropdown" style={{cursor: 'pointer'}}>
                            <span type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                                  style={{zIndex: '10'}}>
                                <img className='m-3 mb-0' src="./assets/icons/threeDots.svg"/>
                            </span>
                                    <ul className="dropdown-menu bg-white" aria-labelledby="dropdownMenuButton1"
                                        style={{zIndex: '11'}}>
                                        <li className='dropdownButton'>
                                            <span className="dropdown-item"
                                                  onClick={() => {
                                                      getDriveFile(row);
                                                  }}><HiOutlineDownload/>&nbsp;Download
                                            </span>
                                        </li>
                                        <li className='dropdownButton'>
                                            <span className="dropdown-item"
                                                  data-bs-toggle='modal' data-bs-target='#deleteFile'
                                                  onClick={() => {
                                                      setFileId(row.id);
                                                  }}><MdOutlineDelete/>&nbsp;Delete
                                            </span>
                                        </li>

                                    </ul>
                                </div>

                    </span>
                    </div>
                </div>
            </div>

        );
    }

    function gotoFiles() {
        history.push('/project/files');
    }

    return (
        <>

            <div className='m-4 px-4 py-2 bg-white rounded-4 shadow-sm fontstyle'>
                <div className='d-flex pb-2'>

                    <button className='btn bg-white ri-add-line text-primary '
                            data-bs-toggle='modal' data-bs-target='#addNewFile'
                            style={{
                                borderBottom: "2px solid #D76D00",
                                borderRadius: "12px",
                                boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.13)"
                            }}>

                    </button>

                    <div className=' w-100 d-flex flex-row justify-content-between '>
                        <span className='fw-semibold p-2 text-center ms-1'>Files</span>
                        {(currentUrl.includes('files')) ?
                            <></> : <span className="align-self-center">
                                <Link to='#' onClick={gotoFiles} style={{textDecoration: 'none'}}>
                                <span className='text-primary' style={{fontSize: '13px'}}>see more</span>
                            </Link>
                        </span>
                        }
                    </div>
                </div>


                <div className='row mt-0  gx-3'>

                    {
                        (allUploadedResources && allUploadedResources.length > 0) ?
                            (currentUrl.includes('files'))
                                ? (allUploadedResources.map((row, index) => fileCard(row, index)
                                )) : (allUploadedResources.slice(0, 8).map((row, index) => fileCard(row, index)
                                )) : <>no data found</>}
                </div>

            </div>


            {/*Add New File Modal*/}
            <div className="modal fade" id="addNewFile" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <p className='w-100 border-0 fw-semibold outline-none' type='text'>Upload File
                                </p>

                                <div className='outline none' id='addFileClose' data-bs-dismiss="modal" type='button'
                                     onClick={() => {
                                         fileInput.current.value = null;
                                         nameElem.current.value = '';
                                         setModalInputFile(null);
                                     }}><GrClose/></div>
                            </div>

                            <div className='p-2' onClick={handleClick}>


                                <div
                                    onDragEnter={handleDragEnter}
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    className=' w-100 outline-none rounded-2 mt-3 d-flex'
                                    style={{
                                        border: "1px dashed #d76005",
                                        height: '10rem',
                                        justifyContent: 'center',
                                        backgroundColor: '#fffaf3', color: '#d76d00'
                                    }}
                                >
                                    {modalInputFile == null ?
                                        <div  className='text-center d-flex align-self-center'>
                                            "Drag and drop files here or click to select files." </div> :
                                        <div
                                            className='w-100 align-items-center justify-content-center d-flex flex-column'>
                                            <img className='m-2' style={{width: "4rem"}}
                                                 src="./assets/icons/pdf-svgrepo-com.svg"/>
                                            <span className='p-1'>{modalInputFile}</span>
                                        </div>}


                                    <input
                                        type="file"
                                        ref={fileInput}
                                        name="imgFile"
                                        style={{display: 'none'}}
                                        onChange={(e) => {
                                            handleFileSelect(e.target.files)
                                        }}
                                    />

                                </div>
                                {/* <input className=' w-100 outline-none rounded-2 mt-3 border-0' ref={fileElem}
                                       placeholder='select file' required
                                       style={{backgroundColor: '#fffaf3', height: '49px', color: '#d76d00'}}
                                       type="file"
                                       name="fileDrive"
                                       onChange={(e) => {
                                           onChangeMyUpload("fileDrive", e.target.files[0]);
                                       }}/>*/}
                                <span style={{fontSize: '12px', color: 'red'}}>{showError.link}</span>
                                <span style={{fontSize: '12px', color: 'red'}}>{showError.proper}</span>

                                <input className=' w-100 outline-none rounded-2 mt-3' ref={nameElem}
                                       placeholder='Description or type (optional)'
                                       placeholder='Enter File Name'
                                       name="name"
                                       onChange={(e) => {
                                           onChangeMyUpload("name", e.target.value);
                                       }}
                                       style={{
                                           border: '0.5px solid #ebebeb',
                                           backgroundColor: '#fafafa',
                                           height: '49px'
                                       }}></input>
                            </div>

                            <div className='w-100 d-flex justify-content-center p-2'>

                                <button className='btn buttonOrangeFill mt-4'
                                        style={{width: '8.5rem'}}
                                        onClick={() => {
                                            onSubmitCreateLinks();
                                        }}
                                > Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Delete File Sure Modal*/}
            <div className="modal fade" id="deleteFile" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div style={{borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}>
                                <div className='d-flex justify-content-center'>
                                    <img className='m-2' style={{width: "10rem"}}
                                         src='./assets/icons/84228-delete-animation.gif'/>
                                </div>
                                <p className='justify-content-center d-flex'
                                   style={{fontSize: "18px", fontWeight: '600'}}>Do you want to DELETE this
                                    file?</p>
                                <p className='justify-content-center d-flex fw-semibold pb-4'
                                   style={{fontSize: '14px'}}>Are you sure ?&nbsp; <span className='fw-normal'> your file will be deleted permanently.</span>
                                </p>
                                <div className='d-flex'>
                                    <button className='w-50 border-0' data-bs-dismiss="modal"
                                            id='closeDeleteSure'
                                            style={{
                                                height: '56px',
                                                color: '#ff0000',
                                                backgroundColor: '#fff8f8',
                                                borderBottomLeftRadius: '6px'
                                            }}
                                            onClick={() => {

                                            }}>CANCEL
                                    </button>
                                    <button className='w-50 border-0'
                                            style={{
                                                height: '56px',
                                                color: '#ffffff',
                                                backgroundColor: '#d76d00',
                                                borderBottomRightRadius: '6px'
                                            }}
                                            onClick={() => {
                                                deleteSingleDriveFile(fileId, token);
                                            }}>DELETE
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </>
    )

}

export default FileDrive;