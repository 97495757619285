import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import WebViewer from "@pdftron/webviewer";
import '../../styles/css/editorjs.css';
import {useDispatch, useSelector} from "react-redux";
import notesAction from '../../Redux/Action/notes'
import {Config} from "../../config/Config";
import './doc-viewer.css'
import {
    getNotesHighlights,
    getNotesMultiLabelByType,
    getNotesUserAnnotations,
    postNotesHighlights
} from "../../actions/Notes/Notes";

const PdfDocViewer = forwardRef((props, ref) => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const currentUser = useSelector(state => state.currentUser);
    const [selectedText, setSelectedText] = useState('');

    const viewer = useRef(null);
    const docInstance = useRef(null);
    const documentId = props.documentId;

    const dispatch = useDispatch();
    const copyPdfText = props.copyPdfText;
    const reloadHighlights = props.reloadHighlights;
    const reloadAnnotations = props.reloadAnnotations;

    const element = document.querySelector(".Popup.TextPopup");
    console.log(element)

    let docUrl = Config.API_URL + 'notes/documents/download/' + documentId;

    useImperativeHandle(ref, () => ({
        showHighlightText(text, type) {
            highlightText(text, type)
        }
    }));

    useEffect(() => {


        WebViewer(
            {
                path: '/webviewer/lib',
                css: '/assets/css/pdf-viewer.css'
            },
            viewer.current,
        ).then((instance) => {
            instance.UI.loadDocument(docUrl, {
                filename: `Document`,
                extension: 'pdf',
                customHeaders: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/pdf'
                }
            });

            instance.UI.setFitMode("FitWidth");

            docInstance.current = instance;
            const {documentViewer, annotationManager, Annotations} = instance.Core;

            instance.UI.disableFeatures([
                instance.UI.Feature.Annotations,
                instance.UI.Feature.ThumbnailReordering,
                instance.UI.Feature.OutlineEditing,
                instance.UI.Feature.Copy,
            ]);
            // instance.UI.disableElements([
            //     instance.UI.Feature.ThumbnailReordering,
            //     instance.UI.Feature.OutlineEditing,
            //     'viewControlsButton',
            //     'viewControlsOverlay',
            //     'attachmentPanelButton',
            //     instance.UI.disableElements(['toolbarGroup-Shapes']),
            //     instance.UI.disableElements(['toolbarGroup-Edit']),
            //     instance.UI.disableElements(['toolbarGroup-Insert']),
            // ]);


            // Optionally use keyDown events
            documentViewer.addEventListener('keyDown', function (e) {
                if (e.keyCode == 67 && (e.ctrlKey || e.metaKey)) {
                    getSelectedText();

                }
            });



            documentViewer.addEventListener('textSelected', (quads, selectedText, pageNumber) => {
                // quads will be an array of 'Quad' objects
                // text is the selected text as a string
                // console.log(selectedText, "1");

                if (selectedText.length > 0) {
                    setSelectedText(selectedText)
                    // console.log(selectedText);
                }
            });


            // Set Selected Text
            const getSelectedText = () => {
                if (documentViewer) {
                    const page = documentViewer.getCurrentPage();
                    const text = documentViewer.getSelectedText(page);
                    if (!!text) {
                        dispatch(notesAction.copyText(text))
                        copyPdfText(text);
                    }
                }
            };

            addTools();

            documentViewer.addEventListener('documentLoaded', () => {
                getNotesHighlights(documentId, token).then((userHighlightsResponse) => {
                    if (userHighlightsResponse && userHighlightsResponse.data.data && userHighlightsResponse.data.data.length > 0) {
                        let userHighlights = userHighlightsResponse.data.data.map((item, index) => {
                            const highlight = new Annotations.TextHighlightAnnotation();
                            highlight.PageNumber = item.page_no;
                            highlight.StrokeColor = new Annotations.Color(255, 255, 0);
                            if (item.type === "Strategy") highlight.StrokeColor = new Annotations.Color(244, 143, 177);
                            if (item.type === "Goals") highlight.StrokeColor = new Annotations.Color(179, 157, 219);
                            if (item.type === "Strength") highlight.StrokeColor = new Annotations.Color(129, 199, 132);
                            if (item.type === "Weakness") highlight.StrokeColor = new Annotations.Color(239, 154, 154);
                            if (item.type === "Assumption") highlight.StrokeColor = new Annotations.Color(144, 202, 249);
                            if (item.type === "RedFlag") highlight.StrokeColor = new Annotations.Color(255, 82, 82);
                            // you might get the quads from text selection, a server calculation, etc
                            highlight.Quads = item.location;
                            annotationManager.addAnnotation(highlight);
                            annotationManager.drawAnnotations(highlight.PageNumber);

                        });
                    }
                });
                getNotesUserAnnotations(documentId, token).then((userHighlightsResponse) => {
                    if (userHighlightsResponse && userHighlightsResponse.data.data && userHighlightsResponse.data.data.length > 0) {
                        let userHighlights = userHighlightsResponse.data.data.map((item, index) => {
                            const highlight = new Annotations.TextHighlightAnnotation();
                            highlight.PageNumber = item.page_no;
                            highlight.StrokeColor = new Annotations.Color(255, 255, 0);
                            // you might get the quads from text selection, a server calculation, etc
                            highlight.Quads = item.location;
                            annotationManager.addAnnotation(highlight);
                            annotationManager.drawAnnotations(highlight.PageNumber);

                        });
                    }
                });
                getNotesMultiLabelByType(documentId, null, token).then((userHighlightsResponse) => {
                    console.log(userHighlightsResponse)
                    if (userHighlightsResponse && userHighlightsResponse.data.data && userHighlightsResponse.data.data.length > 0) {
                        console.log(userHighlightsResponse.data.data)
                        let userHighlights = userHighlightsResponse.data.data.map((item, index) => {
                            console.log("outside", item)
                            if (item.coordinates) {
                                console.log("inside", item)
                                const highlight = new Annotations.TextHighlightAnnotation();
                                highlight.PageNumber = item.page_no + 1;

                                highlight.StrokeColor = new Annotations.Color(255, 255, 0);
                                if (item.type === "Strategy") highlight.StrokeColor = new Annotations.Color(244, 143, 177);
                                if (item.type === "Goals") highlight.StrokeColor = new Annotations.Color(179, 157, 219);
                                if (item.type === "Strength") highlight.StrokeColor = new Annotations.Color(129, 199, 132);
                                if (item.type === "Weakness") highlight.StrokeColor = new Annotations.Color(239, 154, 154);
                                if (item.type === "Assumption") highlight.StrokeColor = new Annotations.Color(144, 202, 249);
                                if (item.type === "RedFlag") highlight.StrokeColor = new Annotations.Color(255, 82, 82);

                                highlight.Quads = item.coordinates;
                                annotationManager.addAnnotation(highlight);
                                annotationManager.drawAnnotations(highlight.PageNumber);
                            }

                        });
                    }
                });
            })


        });
        // })


    }, []);

    function addTools() {
        let instance = docInstance.current;
        let documentViewer = instance.Core.documentViewer;
        let oldButtons = instance.UI.textPopup.getItems();
        // console.log("tools", oldButtons);
        let actionButtons = [
            {
                type: 'actionButton',
                label: 'Highlight',
                dataElement:'Highlight',
                onClick: async () => {
                    const quads = documentViewer.getSelectedTextQuads(documentViewer.getCurrentPage());
                    await uploadHighlight(instance.Core.documentViewer.getSelectedText(), 'Highlight', quads, documentViewer.getCurrentPage())
                },
            }, {
                type: 'actionButton',
                label: 'Strategy',
                dataElement:'Strategy',

                onClick: async () => {
                    const quads = documentViewer.getSelectedTextQuads(documentViewer.getCurrentPage());
                    console.log("current page", documentViewer.getCurrentPage());
                    await uploadHighlight(instance.Core.documentViewer.getSelectedText(), 'Strategy', quads, documentViewer.getCurrentPage())
                },
            }, {
                type: 'actionButton',
                label: 'Strength',
                dataElement:'Strength',

                onClick: async () => {
                    const quads = documentViewer.getSelectedTextQuads(documentViewer.getCurrentPage());
                    await uploadHighlight(instance.Core.documentViewer.getSelectedText(), 'Strength', quads, documentViewer.getCurrentPage())
                },
            }, {
                type: 'actionButton',
                label: 'Weakness',
                dataElement:'Weakness',

                onClick: async () => {
                    const quads = documentViewer.getSelectedTextQuads(documentViewer.getCurrentPage());
                    await uploadHighlight(instance.Core.documentViewer.getSelectedText(), 'Weakness', quads, documentViewer.getCurrentPage())
                },
            }, {
                type: 'actionButton',
                label: 'Assumption',
                dataElement:'Assumption',

                onClick: async () => {
                    const quads = documentViewer.getSelectedTextQuads(documentViewer.getCurrentPage());
                    await uploadHighlight(instance.Core.documentViewer.getSelectedText(), 'Assumption', quads, documentViewer.getCurrentPage())
                },
            }, {
                type: 'actionButton',
                label: 'F-Goal',
                dataElement:'F-Goal',

                onClick: async () => {
                    const quads = documentViewer.getSelectedTextQuads(documentViewer.getCurrentPage());
                    await uploadHighlight(instance.Core.documentViewer.getSelectedText(), 'Goals', quads, documentViewer.getCurrentPage())
                },
            }, {
                type: 'actionButton',
                label: 'Copy',
                dataElement:'Copy',

                onClick: () => {
                    let selectedText = instance.Core.documentViewer.getSelectedText();
                    if (selectedText) {
                    dispatch(notesAction.copyText(selectedText))
                    copyPdfText(selectedText);
                    }


                },
            },
        ];
        if (oldButtons.length > 6) {
            instance.UI.textPopup.update(actionButtons)
        } else {
            instance.UI.textPopup.add(actionButtons, "actionButton");
        }

    }

    useEffect(() => {



        if (docInstance.current) {

            docInstance.current.UI.loadDocument(docUrl, {
                filename: `Document`,
                extension: 'pdf',
                customHeaders: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/pdf'
                }
            });
            docInstance.current.UI.setFitMode("FitWidth");
            let annotationManager = docInstance.current.Core.annotationManager;

            addTools();
        }
    }, [documentId]);

    const highlightText = (text, type) => {
        if (docInstance.current) {
            let documentViewer = docInstance.current.Core.documentViewer;
            let Search = docInstance.current.Core.Search;
            let Annotations = docInstance.current.Core.Annotations;

            const searchText = text.replace(/\n/g, " ");
            const mode = Search.Mode.PAGE_STOP | Search.Mode.HIGHLIGHT;
            const searchOptions = {
                // If true, a search of the entire document will be performed. Otherwise, a single search will be performed.
                fullSearch: false,
                // The callback function that is called when the search returns a result.
                onResult: result => {
                    // with 'PAGE_STOP' mode, the callback is invoked after each page has been searched.
                    if (result.resultCode === Search.ResultCode.FOUND) {
                        const textQuad = result.quads[0].getPoints(); // getPoints will return Quad objects
                        console.log(result)
                        documentViewer.displaySearchResult(result);
                        // now that we have the result Quads, it's possible to highlight text or create annotations on top of the text
                    } else {
                        console.log(result)
                    }
                },
                onError: (err) => {
                    console.log(err)
                }
            };

            documentViewer.textSearchInit(searchText, mode, searchOptions);
        }
    };

    let uploadHighlight = async (text, type, quads, page_no) => {
        try {
            if (text && type && quads) {
                let highlightData = {
                    description: text,
                    type: type,
                    location: JSON.stringify(quads),
                    page_no: page_no
                };
                let response = await postNotesHighlights(documentId, highlightData, token);
                if (response.data.success) {
                    if (type === "Highlight") {
                        reloadAnnotations();
                    } else if (type === "Comment") {
                        console.log("comment reload")
                    } else {
                        reloadHighlights();
                    }
                } else {
                    console.log("text not saved")
                }
            }
        } catch (e) {
            console.error(e)
        }
    };

    return (
        <>
            <div className="webviewer vh-100" ref={viewer}></div>
        </>
    );
});

export default PdfDocViewer;