import React, {useMemo, useEffect, useState, useRef} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentCompany} from "../../utills/Common";
import {
    createNewCheckList, deleteChecklistQuestion, deleteChecklistSections,
    getChecklistQuestions,
    getChecklists,
    saveChecklistQuestions, saveChecklistSections, updateCheckListQuestion
} from "../../actions/CheckList";
import checklistActions from "../../Redux/Action/checklist";
import YesNoSelect from "./component/YesNoComponent";
import ChecklistTextBox from "./component/ChecklistTextBox";
import ChecklistCheckbox from "./component/ChecklistCheckbox";
import ChecklistTextarea from "./component/ChecklistTextarea";
import ChecklistImages from "./component/ChecklistImages";
import {MdClose, MdOutlineDeleteOutline} from "react-icons/md";
import {BsThreeDotsVertical} from "react-icons/bs";
import {AiOutlineEdit} from "react-icons/ai";
import {GrClose} from "react-icons/gr";
import {generateUUID} from "three/src/math/MathUtils";
import {BsCheckCircleFill, BsUiChecksGrid} from "react-icons/bs";
import {toast} from "react-hot-toast";
import {getLocalToken, getLocalUser} from "../../utils/storage";
import {log} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";


let ChecklistView = () => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    let {id: topic_id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isReload, setReload] = React.useState(false);

    const currentUser = useSelector(state => state.currentUser);
    const rChecklist = useSelector(state => state.checklist);
    const [projectDetails, setProjectDetails] = React.useState({});
    const [checklists, setChecklist] = React.useState([]);
    const [checklistQuestions, setChecklistQuestions] = React.useState([]);
    const [checklistID, setChecklistID] = React.useState();

    // const alert = useAlert();
    const [checklistTabFormValue, setChecklistTabFormValue] = React.useState({});
    const [checklistQuestionFormValue, setChecklistQuestionFormValue] = React.useState({});

    const [sectionRef, setSectionRef] = useState([]);
    const [reloadQuestions, toggleReloadQuestions] = useState(false);
    const [reloadQuestionOptions, setReloadQuestionOptions] = useState(false);
    const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
    const [isOptionsVisible, toggleOptionsVisibility] = useState(false);
    const [newSectionName, setNewSectionName] = useState();
    const [editQuestion, setEditQuestion] = useState('');
    const [editSources, setEditSources] = useState('');
    const [editQuestionType, setEditQuestionType] = useState('text-box');
    const [sectionId, setSectionId] = useState();
    const [deleteQue, setDeleteQue] = useState({secID: '', queID: ''});
    const newSectionRef = useRef();
    const [activeTab, setActiveTab] = useState("default");


    const alert = toast;

    const indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB || window.shimIndexedDB;

    //const question = checklistQuestionFormValue.question;
    //const source = checklistQuestionFormValue.source;
    useEffect(() => {
        loadData();

    }, [isReload, topic_id]);


    useEffect(() => {
        if (checklistID) {
            fetchAllChecklistQuestion(checklistID);
        }
    }, [checklistID]);

    useEffect(() => {
        if (rChecklist.rCheckListSectionID !== null) {
            if (sectionRef.length > 0
                && rChecklist.rCheckListSectionID !== undefined
                && sectionRef[rChecklist.rCheckListSectionID]
                && sectionRef[rChecklist.rCheckListSectionID].current) {
                sectionRef[rChecklist.rCheckListSectionID].current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }
        }
    }, [rChecklist.rCheckListSectionID]);

    const loadData = async () => {
        let projectDetails = await getCurrentCompany();
        setProjectDetails(projectDetails);
        await fetchAllChecklistNames();
    };
    /**
     * Fetch Checklist Names
     * @returns {Promise<void>}
     */
    const fetchAllChecklistNames = async () => {
        let response = await getChecklists(topic_id, token);

        if (response.data.data && response.data.data.length > 0) {
            let checkListData = response.data.data.map((report, index) => {
                report.key = index + 1;
                report.action = null;
                return report;
            });
            setChecklist(checkListData);
            if (response.data.data[0]) {
                setChecklistID(response.data.data[0].id);
                setActiveTab(response.data.data[0].id);
                dispatch(checklistActions.rSetChecklistId(response.data.data[0].id));
                dispatch(checklistActions.rSetChecklistSectionId(undefined));
            }

        } else {
            setChecklist([]);
        }
    };

    /**
     * Store in Indexed DB
     * @param response
     */
    function storeInIndexedDB(response) {
        let open = indexedDB.open("MyDatabase", 2);
        open.onupgradeneeded = function () {
            let db = open.result;
            let store = db.createObjectStore("MyObjectStore", {keyPath: "key"});
            // var index = store.createIndex("NameIndex", ["name.last", "name.first"]);
        };
        open.onsuccess = function () {
            let db = open.result;
            let tx = db.transaction("MyObjectStore", "readwrite");
            let store = tx.objectStore("MyObjectStore");
            response.data.data.forEach((report, index) => {
                store.put(report);
            });

            tx.oncomplete = function () {
                db.close();
            };
        }
    }

    /**
     * Fetch All Checklist Questions
     * @param checklistId
     * @returns {Promise<void>}
     */
    const fetchAllChecklistQuestion = async (checklistId) => {
        let response = await getChecklistQuestions(checklistId, token);

        if (response.data.data && response.data.data.length > 0) {

            let checkListData = response.data.data.map((report, index) => {
                report.key = index + 1;
                report.action = null;
                return report;
            });

            const sectionReferences = [];
            checkListData.forEach((value, index) => {
                sectionReferences[index] = React.createRef();
            });
            setSectionRef(sectionReferences);
            setChecklistQuestions(checkListData);
            toggleReloadQuestions(!reloadQuestions);
            storeInIndexedDB(response);
        } else {
            setChecklistQuestions([]);
            toggleReloadQuestions(!reloadQuestions);
        }
    };

    /**
     * Truncate String
     * @param str
     * @param num
     * @returns {string|*}
     */
    function truncateString(str, num) {
        if (str.length > num) {
            return str.slice(0, num) + "...";
        } else {
            return str;
        }
    }

    /**
     * Handle Create Checklist Clicked
     * @returns {Promise<void>}
     */
    const onClickCreateCheckList = async () => {
        if (checklistTabFormValue.name) {
            let response = await createNewCheckList(topic_id, checklistTabFormValue, token);

            if (response.data.success && response.data.data) {

                await fetchAllChecklistNames(topic_id);
                document.getElementById('closeAddChecklistModal').click();
                alert.success(response.data.message);
            }
        } else {
            alert.error("Name is required");
        }
    };

    /**
     * Add New Question
     * @returns {Promise<void>}
     */
    const onClickAddQuestion = async () => {

        if (checklistQuestionFormValue.type !== ''
            && checklistQuestionFormValue.type
            && checklistQuestionFormValue.question) {

            let answers = {
                "name": checklistQuestionFormValue.type + Date.now() + "-0",
                "type": checklistQuestionFormValue.type,
            };
            if (checklistQuestionFormValue.values && checklistQuestionFormValue.values.length > 0) {

                answers.values = checklistQuestionFormValue.values;
            }
            checklistQuestions[currentSectionIndex].questions.push({
                "uuid": generateUUID(),
                "section": checklistQuestions[currentSectionIndex].section,
                "section_id": checklistQuestions[currentSectionIndex].section_id,
                "question": checklistQuestionFormValue.question,
                "sources": checklistQuestionFormValue.source,
                "answer": answers,
            });
            setChecklistQuestions(checklistQuestions);
            await saveQuestions();
            toggleReloadQuestions(!reloadQuestions);

            document.getElementById("closeAddQuestionModal").click();
        }
    };


    /**
     * Edit New Question
     * @returns {Promise<void>}
     */
    const onClickEditQuestion = async () => {
        if (checklistQuestionFormValue.type !== ''
            && checklistQuestionFormValue.type
            && checklistQuestionFormValue.question) {

            let answers = {
                "name": checklistQuestionFormValue.type + Date.now() + "-0",
                "type": checklistQuestionFormValue.type,
            };
            console.log(checklistQuestionFormValue, "FROM VALUE")
            if (checklistQuestionFormValue.values && checklistQuestionFormValue.values.length > 0) {

                answers.values = checklistQuestionFormValue.values;
            } else if (checklistQuestionFormValue.value ) {
                answers.value = checklistQuestionFormValue.value;
            }

            // checklistQuestions[currentSectionIndex].questions.push({
            //     "uuid": checklistQuestions[currentSectionIndex].uuid,
            //     "section": checklistQuestions[currentSectionIndex].section,
            //     "section_id": checklistQuestions[currentSectionIndex].section_id,
            //     "question": checklistQuestionFormValue.question,
            //     "sources": checklistQuestionFormValue.source,
            //     "answer": answers,
            // });
            let questionId = checklistQuestionFormValue.id;
            setChecklistQuestions(checklistQuestions);
            let body = {
                question: checklistQuestionFormValue.question,
                // uuid: checklistQuestionFormValue.question.uuid,
                section: checklistQuestions[currentSectionIndex].section,
                section_id: checklistQuestions[currentSectionIndex].section_id,
                sources: editSources,
                answer: answers
            }
            console.log(questionId, body)
            await updateQuestions(questionId, body);

            document.getElementById('closeEditQuestionModal').click();
            toggleReloadQuestions(!reloadQuestions);
        } else {
            console.log("something missing")
        }
    };


    const deleteQuestion = async (sectionIndex, questionIndex) => {
        const questions = checklistQuestions[sectionIndex].questions;
        console.log('before delete', sectionIndex, questionIndex, questions.length);
        if (sectionIndex > -1 && questionIndex > -1) {
            await deleteChecklistQuestion(questions[questionIndex].id, token);
            questions.splice(questionIndex, 1);
            setChecklistQuestions(checklistQuestions);
            toggleReloadQuestions(!reloadQuestions);
            document.getElementById('closeDeleteQue').click();
            alert.success("Question Delete Successfully !")
        }
    };

    const onChangeContentOfQuestion = async (questionIdx, sectionIdx, content) => {

        if (content.answer.type !== ''
            && content.answer.type) {
            checklistQuestions[sectionIdx].questions[questionIdx] = content;
            setChecklistQuestions(checklistQuestions);
            await saveQuestions();
            toggleReloadQuestions(!reloadQuestions);
        }
    };

    const saveQuestions = async () => {
        if (checklistQuestions) {
            let onlyQuestions = [];
            checklistQuestions.forEach((section, index) => {
                section.questions.forEach((question, idx) => {
                    onlyQuestions.push(question)
                });
            });
            let response = await saveChecklistQuestions(checklistID,
                onlyQuestions, token);

            if (response.data.success && response.data.data) {
                setChecklistQuestions(response.data.data);
            } else {
                alert.error("Some Error Occurred");
            }
        } else {
            console.error("Error", checklistQuestions);
            alert.error("Some Error Occurred");
        }
    };


    const updateQuestions = async (questionID, body) => {
        if (questionID, body) {
            // let response = await saveChecklistQuestions(checklistID,
            //     onlyQuestions, token);
            console.log(token)
            let response = await updateCheckListQuestion(questionID,
                 token, body);
            console.log(response,"response")


            if (response.data && response.data.success ) {
                location.reload()

            } else {
                alert.error("Some Error Occurred");
            }
        } else {
            console.error("Error", body);
            alert.error("Some Error Occurred");
        }
    };

    const saveSection = async () => {
        if (newSectionName) {
            let response = await saveChecklistSections(checklistID, {
                section: newSectionName
            }, token);

            if (response.data.success && response.data.data) {
                await fetchAllChecklistQuestion(checklistID);
                setNewSectionName(undefined);
                newSectionRef.current.value = "";
            } else {
                alert.error("Some Error Occurred");
            }
        }
    };

    const deleteSection = async (sectionId) => {

        if (sectionId) {
            console.log(sectionId);

            let response = await deleteChecklistSections(sectionId, token);
            if (response.data.success) {
                await fetchAllChecklistQuestion(checklistID);
                document.getElementById('closeDeleteModal').click();
            } else {
                console.log(response.data);
                alert.error("Some Error Occurred");
            }
        }
    };


    const checkListQuestionsList = useMemo(() => {
        return checklistQuestions.length > 0
            ? checklistQuestions.map((section, sectionIndex) => {
                return (

                    <div className='m-4 bg-white rounded-4 shadow' ref={sectionRef[sectionIndex]}
                         id={'section-' + sectionIndex} key={sectionIndex}>
                        <div className='p-1 hstack justify-content-between'>
                            <p className='fw-semibold ps-4 m-0'>{section.section ? section.section : ""}</p>
                            <div className=''>
                                <button
                                    data-bs-toggle="modal"
                                    data-bs-target="#addNewQuestion"
                                    className='btn bg-white ri-add-line text-primary me-2 '
                                    style={{
                                        borderBottom: "2px solid #D76D00",
                                        borderRadius: "12px",
                                        boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.13)"
                                    }}
                                    onClick={async () => {
                                        // updateQuestionData(section.key);
                                       await setCurrentSectionIndex(sectionIndex);
                                       await setChecklistQuestionFormValue({});
                                       await setReloadQuestionOptions(!reloadQuestionOptions);
                                    }}>
                                </button>
                                <MdClose className='m-1 me-4 cursor-pointer'
                                         data-bs-toggle='modal' data-bs-target='#deleteSection'
                                         onClick={() => {
                                             setSectionId(section.section_id);
                                         }}/>
                            </div>
                        </div>
                        <table className="table table-bordered m-0" style={{borderColor: '#e9e9e9'}}>
                            <thead>
                            <tr>
                                <th scope="col-5" className='ps-4 pt-2 pb-2'
                                    style={{
                                        borderLeft: 'none',
                                        fontWeight: '400',
                                        fontSize: '16px'
                                    }}>Questions
                                </th>

                                <th scope="col-7" className=' ps-4 pt-2 pb-2'
                                    style={{borderRight: 'none', fontWeight: '400', fontSize: '16px'}}>Answers
                                </th>
                            </tr>
                            </thead>

                            <tbody>
                            {
                                section.questions.length > 0
                                    ? section.questions.map((question, questionIndex) => (
                                        <tr key={questionIndex}>
                                            {/*{console.log(question,"tr")}*/}

                                            <th scope="row" className='ps-4 pt-2 pb-2 col-5'
                                                style={{
                                                    borderLeft: 'none',
                                                    backgroundColor: '#fffaf6',
                                                    fontWeight: '600', fontSize: '14px'
                                                }}> {question.question}
                                            </th>
                                            <td className='ps-4 pt-2 pb-2 col-7 '
                                                style={{borderRight: 'none', fontWeight: '400'}}>
                                                <div className='d-flex justify-content-between'>{(() => {
                                                    if (question.answer.type === "radio-group") {
                                                        return (
                                                            <YesNoSelect questionIdx={questionIndex}
                                                                         sectionIdx={sectionIndex}
                                                                         question={question}
                                                                         onChangeContent={onChangeContentOfQuestion}/>
                                                        )
                                                    } else if (question.answer.type === "yes-no") {
                                                        return (
                                                            <YesNoSelect questionIdx={questionIndex}
                                                                         sectionIdx={sectionIndex}
                                                                         question={question}
                                                                         onChangeContent={onChangeContentOfQuestion}/>
                                                        )
                                                    } else if (question.answer.type === "text-box") {
                                                        return (
                                                            <ChecklistTextBox questionIdx={questionIndex}
                                                                              sectionIdx={sectionIndex}
                                                                              question={question}
                                                                              onChangeContent={onChangeContentOfQuestion}/>
                                                        )
                                                    } else if (question.answer.type === "text") {
                                                        return (
                                                            <ChecklistTextBox questionIdx={questionIndex}
                                                                              sectionIdx={sectionIndex}
                                                                              question={question}
                                                                              onChangeContent={onChangeContentOfQuestion}/>
                                                        )
                                                    } else if (question.answer.type === "checkbox-group") {
                                                        return (
                                                            <ChecklistCheckbox key={questionIndex}
                                                                               questionIdx={questionIndex}
                                                                               sectionIdx={sectionIndex}
                                                                               question={question}
                                                                               onChangeContent={onChangeContentOfQuestion}/>
                                                        )
                                                    } else if (question.answer.type === "text-area") {
                                                        return (
                                                            <ChecklistTextarea questionIdx={questionIndex}
                                                                               sectionIdx={sectionIndex}
                                                                               question={question}
                                                                               onChangeContent={onChangeContentOfQuestion}/>
                                                        )
                                                    } else if (question.answer.type === "images") {
                                                        return (
                                                            <ChecklistImages questionIdx={questionIndex}
                                                                             sectionIdx={sectionIndex}
                                                                             question={question}
                                                                             onChangeContent={onChangeContentOfQuestion}
                                                                             project_id={projectDetails.id}/>
                                                        )
                                                    } else {
                                                        return (
                                                            <div>Unknown Datatype</div>
                                                        )
                                                    }
                                                })()}
                                                    <div className='justify-content-between dropstart'>
                                                    <span className='m-1 me-2 cursor-pointer' data-bs-toggle="dropdown"
                                                          aria-expanded="false">
                                                                <BsThreeDotsVertical/>
                                                    </span>
                                                        <ul className="dropdown-menu cursor-pointer">
                                                            <li className='dropdownButton'>
                                                                <span className="dropdown-item"
                                                                   data-bs-toggle="modal"
                                                                   data-bs-target="#editQuestion"
                                                                   onClick={() => {
                                                                       // updateQuestionData(section.key);
                                                                       setCurrentSectionIndex(sectionIndex);

                                                                       setEditQuestion(question.question)
                                                                       setEditQuestionType(question.answer.type);
                                                                       setEditSources(question.sources)


                                                                       if (question.answer.type === "checkbox-group") {
                                                                           console.log(question, question.uuid);
                                                                           setChecklistQuestionFormValue({
                                                                               id: question.id,
                                                                               question: question.question,
                                                                               type: question.answer.type,
                                                                               uuid: question.uuid,
                                                                               sources: question.sources,
                                                                               values: question.answer.values
                                                                           });
                                                                           toggleOptionsVisibility(true);
                                                                           let reload = !reloadQuestionOptions;
                                                                           setReloadQuestionOptions({reload});

                                                                       } else {
                                                                           setChecklistQuestionFormValue({
                                                                               id: question.id,
                                                                               question: question.question,
                                                                               uuid: question.uuid,
                                                                               type: question.answer.type,
                                                                               sources: question.sources,
                                                                               value: question.answer.value
                                                                           });
                                                                           toggleOptionsVisibility(false)
                                                                       }

                                                                   }}><AiOutlineEdit/>&nbsp;Edit
                                                                </span>
                                                            </li>
                                                            <li className='dropdownButton'>
                                                                <span className="dropdown-item"
                                                                   data-bs-toggle='modal'
                                                                   data-bs-target='#deleteQuestion'
                                                                   onClick={() => {
                                                                       setDeleteQue({
                                                                           secID: sectionIndex,
                                                                           queID: questionIndex
                                                                       });

                                                                   }}><MdOutlineDeleteOutline/>&nbsp;Delete
                                                                </span>
                                                            </li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>


                                        </tr>

                                    )) : <></>}
                            </tbody>

                        </table>
                        <div className='p-1 hstack justify-content-between m-0'>
                            <p
                                data-bs-toggle="modal"
                                data-bs-target="#addNewQuestion"
                                className='ps-4 pt-2 pb-2'
                                onClick={() => {
                                    // updateQuestionData(section.key);
                                    setCurrentSectionIndex(sectionIndex);
                                    setChecklistQuestionFormValue({});
                                    setReloadQuestionOptions(!reloadQuestionOptions);
                                }}
                                style={{color: '#afafaf', fontWeight: '500', cursor: 'pointer'}}>+ New Question
                            </p>

                        </div>
                    </div>
                )
            }) : <></>

    }, [reloadQuestions]);

    const checklistTabs = useMemo(() => {
        return (checklists.length > 0)
            ? checklists.map((item, index) => {
                return (
                    <li className="nav-item" key={index} style={{marginRight: '4px'}}>
                        <button
                            className={`btn  ${activeTab === item.id ? 'btn-primary' : 'btn-outline-primary'} fw-semibold`}
                            onClick={() => {
                                setChecklistID(item.id);
                                dispatch(checklistActions.rSetChecklistId(item.id));
                                dispatch(checklistActions.rSetChecklistSectionId(undefined));
                                setActiveTab(item.id);
                            }}>{item.name.substring(0, 10)}</button>
                    </li>
                )
            }) : <></>
    }, [checklists, activeTab])

    const questionCheckboxOptionsGroup = useMemo(() => {
        const values = checklistQuestionFormValue.values;
        console.log(values);
        return values && values.length > 0
            ? values.map((op, idx) => {
                return (
                    <div key={idx} className='bg-white p-1 ps-3 pe-3 rounded'>{op.label}&nbsp;
                        <input disabled defaultChecked={op.selected} type={"checkbox"}
                               style={{accentColor: '#009329'}}
                               value={op.value}/>
                    </div>
                )
            })
            : <></>
    }, [reloadQuestionOptions]);


    return (
        <>
            <div className='' style={{marginBottom: '70px'}}>

                {checkListQuestionsList}

                <div className='m-4 bg-white rounded-4 shadow p-1'>
                    <div className='p-1 hstack justify-content-between'>
                        <input className='fw-semibold ps-4 w-75 outline-none border-0'
                               style={{}} ref={newSectionRef}
                               type='text'
                               placeholder='# Enter new section name'
                               onChange={(e) => {
                                   setNewSectionName(e.target.value);
                               }}
                               onKeyDown={e => {
                                   if (e.key === 'Enter') {
                                       saveSection();
                                   }
                               }}/>
                        <div className=''>
                            <BsCheckCircleFill className='m-1 me-4' style={{color: 'green', fontSize: '20px'}}
                                               onClick={() => {
                                                   saveSection();
                                               }}/>
                        </div>
                    </div>
                </div>

                <div className='bg-white  shadow p-2 justify-between d-flex w-100'
                     style={{position: 'fixed', bottom: '0'}}>

                    <button className='btn btn-outline-primary me-1 fw-semibold'
                            data-bs-toggle='modal' data-bs-target='#addNewChecklist'
                    >+ Add Checklist
                    </button>

                    <ul className="nav nav-pills">
                        {checklistTabs}
                    </ul>
                </div>
            </div>


            {/*Add new Checklist Modal*/}
            <div className="modal fade" id="addNewChecklist" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <p className='w-100 border-0 fw-semibold outline-none' type='text'>Create New
                                    Checklist
                                </p>

                                <div className='outline none' data-bs-dismiss="modal" type='button'
                                     id='closeAddChecklistModal'
                                     onClick={async () => {
                                         await setChecklistTabFormValue({name: '', extra_details: ''})
                                         document.getElementById("addChecklistNameId").value = "";
                                         document.getElementById("addChecklistDescId").value = "";
                                     }}><GrClose/></div>
                            </div>

                            <div className='p-2'>


                                <input className=' w-100 outline-none rounded-2 mt-3 border-0' id='addChecklistNameId'
                                       placeholder='enter name' required
                                       style={{backgroundColor: '#fffaf3', height: '49px', color: '#d76d00'}}
                                       type="text"
                                       name="name"
                                       onChange={(e) => {
                                           let formData = checklistTabFormValue;
                                           formData.name = e.target.value;
                                           setChecklistTabFormValue(formData);
                                       }}/>


                                <input className=' w-100 outline-none rounded-2 mt-3' id='addChecklistDescId'
                                       placeholder='Enter Description (optional)'
                                       name="extra_details"
                                       onChange={(e) => {
                                           let formData = checklistTabFormValue;
                                           formData.extra_details = e.target.value;
                                           setChecklistTabFormValue(formData);
                                       }}
                                       style={{
                                           border: '0.5px solid #ebebeb',
                                           backgroundColor: '#fafafa',
                                           height: '49px'
                                       }}/>
                            </div>

                            <div className='w-100 d-flex justify-content-center p-2'>

                                <button className='btn btn-primary mt-4'
                                        style={{width: '8.5rem'}}
                                        onClick={() => onClickCreateCheckList()}
                                > Submit
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* Add new Question Modal*/}
            <div className="modal fade" id="addNewQuestion" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <span className='w-100 border-0 fw-semibold outline-none' type='text'
                                >Add New Question
                                </span>

                                <div className='outline none' data-bs-dismiss="modal" type='button'
                                     id='closeAddQuestionModal'
                                     onClick={async (e) => {
                                         let formdata ={}
                                         await setChecklistQuestionFormValue(formdata);
                                         toggleOptionsVisibility(false)
                                         e.preventDefault();
                                         document.getElementById("addQSelectInput").value="";
                                         document.getElementById("addQNameInput").value="";
                                         document.getElementById("addQDescInput").value="";
                                     }}><GrClose/></div>
                            </div>

                            <div className='p-2'>
                                <select
                                    className=' w-100 outline-none rounded-2 mt-3 p-2' id="addQSelectInput"
                                    style={{
                                        border: '0.5px solid #ebebeb',
                                        backgroundColor: '#fafafa',
                                    }}
                                    required={true} onChange={(e) => {
                                    let formData = checklistQuestionFormValue;
                                    formData.type = e.target.value;
                                    if (formData.type === 'checkbox-group') {
                                        toggleOptionsVisibility(true)
                                    } else {
                                        toggleOptionsVisibility(false);
                                    }
                                    setChecklistQuestionFormValue(formData);
                                }}>
                                    <option value={''}>Select Question Type</option>
                                    <option value={'text-box'}>One Line</option>
                                    <option value={'text-area'}>Paragraph</option>
                                    <option value={'yes-no'}>Yes No</option>
                                    <option value={'checkbox-group'}>Check Boxes</option>
                                    {/*<option value={'files'}>Files</option>*/}
                                    <option value={'images'}>Images</option>
                                    {/*<option value={'table'} >Table</option>*/}
                                </select>

                                <input className=' w-100 outline-none mt-3 p-2 bg white'
                                        id="addQNameInput"
                                       style={{border: 'none', borderBottom: '1px solid #dadada'}}
                                       required={true}
                                       placeholder='Question'
                                       name="question"
                                       onChange={(e) => {
                                           let formData = checklistQuestionFormValue;
                                           formData.question = e.target.value;
                                           setChecklistQuestionFormValue(formData);
                                       }}/>


                                <input className=' w-100 outline-none mt-3 p-2 bg white'
                                       id="addQDescInput"
                                       placeholder='Source'
                                       name="source"
                                       onChange={(e) => {
                                           let formData = checklistQuestionFormValue;
                                           formData.source = e.target.value;
                                           setChecklistQuestionFormValue(formData);
                                       }}
                                       style={{border: 'none', borderBottom: '1px solid #dadada'}}/>
                                {
                                    isOptionsVisible ?
                                        <div className='vstack'>
                                            <div className='vstack align-items-start'>
                                                {questionCheckboxOptionsGroup}
                                            </div>
                                            <div className='hstack'>
                                                <input
                                                    style={{backgroundColor: '#fffaf3', color: '#d76d00'}}
                                                    className=' w-100 outline-none rounded-2 mt-3 p-2 border-0'
                                                    placeholder={'Type Option lable and press enter'}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            let formData = checklistQuestionFormValue;
                                                            if (!formData.values) {
                                                                formData.values = [];
                                                            }
                                                            formData.values.push({
                                                                label: e.target.value,
                                                                value: e.target.value,
                                                                selected: false,
                                                            });
                                                            setChecklistQuestionFormValue(formData);
                                                            setReloadQuestionOptions(!reloadQuestionOptions);
                                                            e.target.value = "";
                                                        }
                                                    }}/>
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                            <div className='w-100 d-flex justify-content-center p-2'>

                                <button className='btn buttonOrangeFill'
                                        style={{width: '8.5rem'}}
                                        onClick={onClickAddQuestion}
                                > Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/*edit Question modal*/}
            <div className="modal fade" id="editQuestion" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <p className='w-100 border-0 fw-semibold outline-none' type='text'
                                >Edit Question
                                </p>

                                <div className='outline none' data-bs-dismiss="modal" type='button'
                                     id='closeEditQuestionModal'
                                     onClick={() => {
                                         setChecklistQuestionFormValue({});
                                     }}><GrClose/></div>
                            </div>

                            <div className='p-2'>
                                <select
                                    className='w-100 border-0 outline-none p-2 rounded-2'
                                    style={{backgroundColor: '#fafdff'}}

                                    defaultValue={editQuestionType  ? editQuestionType : ""}
                                    required={true}
                                    onChange={(e) => {

                                        let formData = checklistQuestionFormValue;
                                        formData.type = e.target.value;
                                        if (formData.type === 'checkbox-group') {
                                            toggleOptionsVisibility(true)
                                        } else {
                                            toggleOptionsVisibility(false)
                                        }
                                        setChecklistQuestionFormValue(formData);
                                        let ansType = e.target.value;
                                        setEditQuestionType(ansType)
                                    }}>
                                    <option value={''}>Select Question Type</option>
                                    <option value={'text-box'}>One Line</option>
                                    <option value={'text-area'}>Paragraph</option>
                                    <option value={'yes-no'}>Yes No</option>
                                    <option value={'checkbox-group'}>Check Boxes</option>
                                    {/*<option value={'files'}>Files</option>*/}
                                    <option value={'images'}>Images</option>
                                    {/*<option value={'table'} >Table</option>*/}
                                </select>
                                <input className=' w-100 outline-none rounded-2 mt-3 border-0 p-2'
                                       style={{backgroundColor: '#fffaf3', color: '#d76d00'}}
                                       required={true}
                                       contentEditable='true'
                                       name="question"
                                       value={editQuestion}
                                       onChange={(e) => {
                                           let que = e.target.value
                                           setEditQuestion(que)
                                           let formData = checklistQuestionFormValue;
                                           formData.question = e.target.value;
                                           setChecklistQuestionFormValue(formData);
                                       }}
                                />


                                <input className=' w-100 outline-none rounded-2 mt-3 p-2'
                                       contentEditable='true'
                                       placeholder='Source'
                                       name="source"
                                       value={editSources}
                                       onChange={(e) => {
                                           let formData = checklistQuestionFormValue;
                                           formData.source = e.target.value;
                                           let source = e.target.value

                                           setEditSources(source);
                                           setChecklistQuestionFormValue(formData);
                                       }}
                                       style={{
                                           border: '0.5px solid #ebebeb',
                                           backgroundColor: '#fafafa',
                                       }}/>

                                {
                                    isOptionsVisible ?
                                        <div className='vstack'>
                                            <div className='vstack align-items-start'>
                                                {questionCheckboxOptionsGroup}
                                            </div>
                                            <div className='hstack'>
                                                <input
                                                    style={{backgroundColor: '#fffaf3', color: '#d76d00'}}
                                                    className=' w-100 outline-none rounded-2 mt-3 p-2 border-0'
                                                    placeholder={'Type Option lable and press enter'}
                                                    onKeyDown={e => {
                                                        if (e.key === 'Enter') {
                                                            let formData = checklistQuestionFormValue;
                                                            if (!formData.values) {
                                                                formData.values = [];
                                                            }
                                                            formData.values.push({
                                                                label: e.target.value,
                                                                value: e.target.value,
                                                                selected: false,
                                                            });
                                                            setChecklistQuestionFormValue(formData);
                                                            setReloadQuestionOptions(!reloadQuestionOptions);

                                                        }
                                                    }}/>
                                            </div>
                                        </div>
                                        : <></>
                                }
                            </div>
                            <div className='w-100 d-flex justify-content-center p-2'>

                                <button className='btn buttonOrangeFill'
                                        style={{width: '8.5rem'}}
                                        onClick={
                                            () => {
                                                console.log("clicked")
                                                // let quetionID =
                                                onClickEditQuestion()


                                            }

                                        }
                                > Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/*Delete Section Sure Modal*/}
            <div className="modal fade" id="deleteSection" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div style={{borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}>
                                <div className='d-flex justify-content-center'>
                                    <img className='m-2' style={{width: "10rem"}}
                                         src='./assets/icons/84228-delete-animation.gif'/>
                                </div>
                                <p className='justify-content-center d-flex'
                                   style={{fontSize: "18px", fontWeight: '600'}}>Do you want to DELETE this
                                    Section?</p>
                                <p className='justify-content-center d-flex fw-semibold pb-4'
                                   style={{fontSize: '14px'}}>Are you sure ?&nbsp; <span className='fw-normal'>
                                    This section will be deleted permanently.</span>
                                </p>
                                <div className='d-flex'>
                                    <button className='w-50 border-0' data-bs-dismiss="modal" id='closeDeleteModal'
                                            style={{
                                                height: '56px',
                                                color: '#ff0000',
                                                backgroundColor: '#fff8f8',
                                                borderBottomLeftRadius: '6px'
                                            }}
                                            onClick={() => {
                                                setSectionId(null);
                                            }}>CANCEL
                                    </button>
                                    <button className='w-50 border-0'
                                            style={{
                                                height: '56px',
                                                color: '#ffffff',
                                                backgroundColor: '#d76d00',
                                                borderBottomRightRadius: '6px'
                                            }}
                                            onClick={async () => {

                                                await deleteSection(sectionId);

                                            }}>DELETE
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/*Delete Single Quetion Modal */}
            <div className="modal" id='deleteQuestion' tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Delete Question ?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close" id='closeDeleteQue'
                                    onClick={() => {
                                        setDeleteQue({secID: '', queID: ''});
                                    }}></button>
                        </div>
                        <div className="modal-body d-flex">
                            <img className='m-2' style={{width: "4rem"}}
                                 src='./assets/icons/84228-delete-animation.gif'/>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <p className='justify-content-center d-flex fw-semibold mb-0'
                                    >Are you sure ?&nbsp;</p>
                                    <span className='fw-normal'>
                                    This question will be deleted permanently.</span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"
                                    onClick={() => {
                                        setDeleteQue({secID: '', queID: ''});
                                    }}>Close
                            </button>
                            <button type="button" className="btn btn-primary"
                                    onClick={async () => {
                                        await deleteQuestion(deleteQue.secID, deleteQue.queID);
                                    }}
                            >Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ChecklistView;