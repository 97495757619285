import {types} from '../types';

const INITIAL_STATE = {
    rCheckListID: 0,
};

const reducer = (state = INITIAL_STATE, action) => {
    if (action.type === types.SET_CHECKLIST_ID) {
        return {
            ...state,
            rCheckListID: action.payload,
        };
    } else if (action.type === types.SET_CHECKLIST_SECTION_ID) {
        return {
            ...state,
            rCheckListSectionID: action.payload,
        };
    } else {
        return state;
    }
};

export default reducer;