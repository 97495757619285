import React, {useState, useEffect} from "react";
import 'remixicon/fonts/remixicon.css';
import {useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {getCurrentCompany} from "../../utills/Common";
import {
    createNewCheckListTopic,
    deleteCheckList,
    getChecklistTopics,
    updateChecklistName
} from "../../actions/CheckList";
import {MdOutlineDelete} from "react-icons/md";
import {RiEditLine} from "react-icons/ri";
import {TbTemplate} from "react-icons/tb";
import {createNewChecklistFromTemplate} from "../../actions/CheckList/templates";
import {GrClose} from "react-icons/gr";
import truncateString from "../../utills/reusableFunction";
import CheckListCreateModal from "./component/CheckListCreateModal";
import ChecklistPreviewModal from "./component/ChecklistPreviewModal";
import {toast} from "react-hot-toast";
import '../../../node_modules/bootstrap/js/src/dropdown.js'


let Checklist = () => {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const currentUser = useSelector(state => state.currentUser);
    const searchValue = useSelector(state => state.search);
    const [currentProject, setCurrentProject] = useState({});
    const [checkLists, setCheckLists] = useState([]);
    const [placeholder, setPlaceholder] = useState([]);
    const [projectId, setProjectId] = useState([]);
    const [checklistId, setChecklistId] = useState([]);
    const [formValue, setFormValue] = useState({});
    const [currentCompany, setCurrentCompany] = useState({});
    const [noteDocuments, setNoteDocuments] = useState([]);
    const [templateId, setTemplateId] = useState();
    const [idTempTab, setIdTempTab] = useState();

    const history = useHistory();
    const alert = toast;

    const getCurrentProjectDetails = async () => {
        let projectDetails = await getCurrentCompany();
        setCurrentProject(projectDetails);
        await getAllCheckLists(projectDetails.id);
    };

    const getAllCheckLists = async (projectId) => {

        let response = await getChecklistTopics(projectId, token, searchValue.value != '' ? searchValue.value : '');

        if (response.data.data && response.data.data.length > 0) {
            let checkListData = response.data.data.map((report, index) => {
                report.key = index + 1;
                report.action = null;
                return report;
            });

            setCheckLists(checkListData);
        } else {
            setCheckLists([]);
            // alert.error("No data found!");
        }
    };

    const clearState =()=>{
        console.log("clearState func");
    }

    const handleOnSubmit = async (formValue) => {
        console.log(formValue)

        let apiValue = {name: formValue.name, extra_details: formValue.extra_details}
        if (formValue && formValue.name && formValue.extra_details) {
            let response = await createNewCheckListTopic(currentProject.id, apiValue, token);
            console.log(apiValue);
            console.log(response);
            if (response.data.success && response.data.data) {
                await getAllCheckLists(currentProject.id);
                document.getElementById('closeChecklistCreateModal').click();
                alert.success(response.data.message);
                return(true);
            } else {
                alert.error(response.data.errors);
            }
        } else {
            if (!formValue.name) {
                alert.error("Name is required");
            } else if (!formValue.extra_details) {
                alert.error("Extra details is required");
            }
        }
    };

    const handleOnDelete = async () => {

        try {
            let response = await deleteCheckList(checklistId, token);

            console.log(response);
            if (response.data.success) {
                await getAllCheckLists(currentProject.id);

                document.getElementById("deleteSureModal").click();
                setChecklistId(null);
                alert.success(response.data.message);
            } else {
                alert.error(response.data.errors);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const changeChecklistName = async (name) => {
        let response = await updateChecklistName(checklistId, {
            name: name
        }, token);

        if (response.data.success) {
            await getAllCheckLists(currentProject.id);
            setChecklistId(null);
            document.getElementById('closeEditChecklistModal').click();
            alert.success(response.data.message);
        } else {
            alert.error(response.data.errors);
        }
    };

    const createChecklistToTemplate = async (templateId) => {
        let response = await createNewChecklistFromTemplate(currentProject.id, {
            template_id: templateId,
            is_template: true
        }, token);
        if (response.data.success) {
            await getAllCheckLists(currentProject.id);
            alert.success(response.data.message);
        } else {
            alert.error(response.data.errors);
        }
    };

    const onSelectPreviewTemplate = (templateId) => {
        setTemplateId(templateId);
        ///onOpenPreviewTemplate();
        document.getElementById("checkListPreviewBtn").click()
    };

    function onPreviewDismiss() {
        document.getElementById("closePreviewModal").click();
        document.getElementById("addIcon").click();
    }

    function reverseTemplateList(idName) {
        setIdTempTab(idName);
    }

    const onImportTemplate = async (templateId) => {
        let response = await createNewChecklistFromTemplate(currentProject.id, {template_id: templateId}, token);
        if (response.data.success) {
            //onCloseChecklistCreate();
            await getAllCheckLists(currentProject.id);
            onDismiss();
            alert.success(response.data.message);
        } else {
            alert.error(response.data.errors);
        }
    };

    function onDismiss() {
        let name = document.getElementById("nameTopic");
        let desc = document.getElementById("descTopic");
        if(name){
            name.value="";
        }
        if(desc){
            desc.value="";
        }

        document.getElementById('closeChecklistCreateModal').click();
        return true;
    }

    useEffect(() => {
        getCurrentProjectDetails();
    }, [searchValue]);


    const currentUrl = window.location.href;

    function cardChecklist(row, index) {
        return (
            <div className='col-3 p-2' key={index}>

                <div
                    className='d-flex align-items-center p-2 rounded-4 shadow-sm '
                    style={{backgroundColor: "#f6f6f6", border: "1px solid #eeeeee", cursor: 'pointer'}}>

                    <div className='d-flex flex-column col-11'
                         onClick={() => {
                             history.push(`/check-list/view/${row.id}`)
                         }}
                         style={{whiteSpace: 'nowrap', overflow: 'hidden'}}>
                        <div className='align-self-start text-dark d-flex ri-list-check-2 ps-2 '
                             style={{width: "25px"}}></div>
                        <span className='fs-7 text-capitalize ps-2 p-1 fw-semibold'
                              style={{fontSize: "12px"}}>{truncateString(row.name, 33)}</span>
                    </div>

                    <div className="btn-group dropdown col-1 mb-auto">
                                          <span data-bs-toggle="dropdown" aria-expanded="false" className=' ms-auto'>
                                        <img className='me-2' src="./assets/icons/threeDots.svg"/>
                                          </span>
                        <ul className="dropdown-menu">
                            <li className='dropdownButton' data-bs-toggle='modal' data-bs-target='#editChecklistName'>
                                <span className="dropdown-item"
                                      onClick={() => {
                                          setChecklistId(row.id);
                                          setPlaceholder(row.name);
                                      }}><RiEditLine/>&nbsp;Edit Name
                                </span>
                            </li>
                            <li className='dropdownButton' data-bs-toggle='modal' data-bs-target='#deleteChecklist'>
                                <span className="dropdown-item"
                                      onClick={() => {
                                          setChecklistId(row.id);
                                      }}><MdOutlineDelete/>&nbsp;Delete Checklist
                                </span>
                            </li>
                            <li className='dropdownButton'>
                                <span className="dropdown-item"
                                      onClick={() => {
                                          createChecklistToTemplate(row.id)
                                      }}><TbTemplate/>&nbsp;Make As Template
                                </span>
                            </li>
                        </ul>
                    </div>

                </div>

            </div>

        )
    };

    function gotoChecklist() {
        history.push('/project/check-list');
    }

    return (
        <>


            <div className='m-4 px-4 py-2 bg-white rounded-4 shadow-sm fontstyle '>

                <div className='d-flex pb-2'>

                        <button className='btn bg-white ri-add-line text-primary ' data-bs-toggle='modal'
                                data-bs-target='#createChecklist' id='addIcon'
                                style={{
                                    borderBottom: "2px solid #D76D00",
                                    borderRadius: "12px",
                                    boxShadow: "0px 4px 7px rgba(0, 0, 0, 0.13)"
                                }}>

                        </button>

                    <div className='w-100 d-flex flex-row justify-content-between '>
                        <span className='fw-semibold p-2 text-center ms-1'>Checklists</span>
                        {(currentUrl.includes('check-list')) ?
                            <></> : <span className="align-self-center">
                                <Link to='#' style={{textDecoration: 'none'}} onClick={gotoChecklist}>
                                <span className='text-primary' style={{ fontSize: '13px'}}>see more</span>
                            </Link></span>
                        }
                    </div>
                </div>


                <div className='row mt-0  gx-3'>


                    {
                        (checkLists && checkLists.length > 0) ?
                            (currentUrl.includes('check-list'))
                                ? (checkLists.map((row, index) => cardChecklist(row, index)
                                )) : (checkLists.slice(0, 8).map((row, index) => cardChecklist(row, index)
                                )) : <>no data found</>
                    }


                </div>


            </div>


            {/*Create new Checklist Modal*/}
            <div className="modal fade" id="createChecklist" style={{zIndex: '1400'}}
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <span className='fw-semibold p-2'>Create New Checklist</span>
                                <div className='outline none p-2' data-bs-dismiss="modal" id='closeChecklistCreateModal'
                                     type='button' onClick={() => {
                                    onDismiss();

                                }}><GrClose/></div>
                            </div>

                            {/*Tab Panel for create new checklist and by ready template*/}
                            <CheckListCreateModal onImportTemplate={onImportTemplate}
                                                  openTemplatePreview={onSelectPreviewTemplate}
                                                  onNewCreate={handleOnSubmit} onDismiss={onDismiss}
                                                  clearState={clearState}

                            />

                        </div>

                    </div>
                </div>
            </div>


            {/*Checklist Priview Modal*/}

            <span hidden={true} id="checkListPreviewBtn" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Launch static backdrop modal
            </span>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">Checklist Preview</h5>
                            <button type="button" id='closePreviewModal' className="btn-close"
                                    data-bs-dismiss="modal" aria-label="Close"
                                    onClick={() => {
                                        setTemplateId(null);
                                        onPreviewDismiss();
                                    }}
                            />
                        </div>
                        <div className="modal-body" style={{backgroundColor: '#f5f5f5'}}>
                            <ChecklistPreviewModal  templateId={templateId}  onDismiss={onPreviewDismiss}/>
                        </div>
                    </div>
                </div>
            </div>


            {/*Edit Checklist Name*/}
            <div className="modal fade" id="editChecklistName" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className='d-flex justify-content-between p-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <p className='fw-semibold'>Edit Check-list Name</p>
                                <div className='outline none' data-bs-dismiss="modal" type='button'
                                     id='closeEditChecklistModal'
                                     onClick={() => {
                                         setPlaceholder(null)
                                     }}><GrClose/></div>
                            </div>

                            <div className='p-2'>
                                <input type='text' className=' w-100 outline-none rounded-2'
                                       value={placeholder}
                                       onChange={(e) => {
                                           setPlaceholder(e.target.value)
                                       }}
                                       style={{
                                           border: '0.5px solid #ebebeb',
                                           backgroundColor: '#fafafa',
                                           height: "3rem"
                                       }}></input>
                            </div>
                            <div className='w-100 d-flex justify-content-center p-2'>

                                <button className='btn buttonOrangeFill'
                                        style={{width: '8.5rem'}}
                                        onClick={() => {
                                            changeChecklistName(placeholder);
                                        }}> Save
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* Delete File Sure Modal*/}
            <div className="modal fade" id="deleteChecklist" data-bs-backdrop="static"
                 data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div style={{borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}>
                                <div className='d-flex justify-content-center'>
                                    <img className='m-2' style={{width: "10rem"}}
                                         src='./assets/icons/84228-delete-animation.gif'/>
                                </div>
                                <p className='justify-content-center d-flex'
                                   style={{fontSize: "18px", fontWeight: '600'}}>Do you want to DELETE this
                                    file?</p>
                                <p className='justify-content-center d-flex fw-semibold pb-4'
                                   style={{fontSize: '14px'}}>Are you sure ?&nbsp; <span className='fw-normal'> your file will be deleted permanently.</span>
                                </p>
                                <div className='d-flex'>
                                    <button className='w-50 border-0' data-bs-dismiss="modal" id='deleteSureModal'
                                            style={{
                                                height: '56px',
                                                color: '#ff0000',
                                                backgroundColor: '#fff8f8',
                                                borderBottomLeftRadius: '6px'
                                            }}
                                            onClick={() => {

                                            }}>CANCEL
                                    </button>
                                    <button className='w-50 border-0'
                                            style={{
                                                height: '56px',
                                                color: '#ffffff',
                                                backgroundColor: '#d76d00',
                                                borderBottomRightRadius: '6px'
                                            }}
                                            onClick={() => {
                                                handleOnDelete();
                                            }}>DELETE
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Checklist;