import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getChecklistQuestions, getChecklists} from "../../../actions/CheckList";
import {BsThreeDotsVertical} from "react-icons/bs";
import {MdClose} from "react-icons/md";



let ChecklistPreviewModal = (props) => {
    const token = localStorage.getItem('token');
    const templateId = props.templateId;
    const [checklistQuestions, setChecklistQuestions] = useState([]);
    const [checklists, setChecklist] = useState([]);
    const [checklistID, setChecklistID] = useState();
    const [activeTab, setActiveTab] = useState("default");


    useEffect(() => {
        if (templateId) {
            fetchAllChecklistNames(templateId)
        }

    }, [templateId]);

    /**
     * Fetch All Checklist Questions
     * @param checklistId
     * @returns {Promise<void>}
     */
    const fetchAllChecklistQuestion = async (checklistId) => {
        let response = await getChecklistQuestions(checklistId, token);

        if (response.data.data && response.data.data.length > 0) {
            let checkListData = response.data.data.map((report, index) => {


                report.key = index + 1;
                report.action = null;
                return report;
            });
            setChecklistQuestions(checkListData);
        } else {
            setChecklistQuestions([]);
        }
    };

    const fetchAllChecklistNames = async (topic_id) => {
        let response = await getChecklists(topic_id, token);

        if (response.data.data && response.data.data.length > 0) {
            let checkListData = response.data.data.map((report, index) => {
                report.key = index + 1;
                report.action = null;
                return report;
            });
            setChecklist(checkListData);
            if (response.data.data[0]) {
                setChecklistID(response.data.data[0].id);
                setActiveTab(response.data.data[0].id);
                await fetchAllChecklistQuestion(response.data.data[0].id);
            }

        } else {
            setChecklist([]);
        }
    };


    /* const checkListQuestionsList = useMemo(() => , []);*/

    const checklistTabs = useMemo(() => {
        return (checklists.length > 0)
            ? checklists.map((item, index) => {
                return (
                    <li className="nav-item" key={item.key} style={{marginRight: '4px'}}>
                        <button
                            className={`btn  ${activeTab === item.id ? 'btn-primary' : 'btn-outline-primary'} fw-semibold`}
                            /*     style={{backgroundColor:`${activeTab===item.id?'#d76d00':'white'}`,Color:`${activeTab===item.id?'white':'#d76d00'}`}}*/
                            onClick={() => {
                                setChecklistID(item.id);
                                setActiveTab(item.id);
                                fetchAllChecklistQuestion(item.id)
                            }}>{item.name.substring(0, 10)}</button>
                    </li>
                )
            }) : <></>
    }, [checklists, activeTab])

    return (
        <>
            <div className='' style={{marginBottom: '70px', backgroundColor: '#f5f5f5'}}>
                {
                    checklistQuestions.length > 0
                        ? checklistQuestions.map((section, sectionIndex) => {
                            return (

                                <div className='m-4 bg-white rounded-4 shadow'
                                     id={'section-' + sectionIndex} key={section.key}>
                                    <div className='p-1 hstack justify-content-between'>
                                        <span className='fw-semibold ps-4'>{section.section ? section.section : ""}</span>
                                        <div className=''>
                                            <button
                                                className='btn rounded-4 shadow-sm m-1 px-2 py-1 me-3'
                                                style={{color: '#d76d00', borderBottom: 'solid 2px #d76d00'}}
                                            >+
                                            </button>
                                            <MdClose className='m-1 me-4'
                                            />
                                        </div>
                                    </div>
                                    <table className="table table-bordered m-0" style={{borderColor: '#e9e9e9'}}>
                                        <thead>
                                        <tr>
                                            <th scope="col" className='ps-4 pt-2 pb-2'
                                                style={{
                                                    borderLeft: 'none',
                                                    width: '45%',
                                                    fontWeight: '600',
                                                    fontSize: '16px'
                                                }}>Questions
                                            </th>

                                            <th scope="col" className=' ps-4 pt-2 pb-2'
                                                style={{borderRight: 'none', fontWeight: '600', fontSize: '16px'}}>Answers
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            section.questions.length > 0
                                                ? section.questions.map((question, questionIndex) => (
                                                    <tr key={questionIndex}>
                                                        <th scope="row" className='ps-4 pt-2 pb-2'
                                                            style={{
                                                                borderLeft: 'none',
                                                                width: '40%',
                                                                backgroundColor: '#fffaf6',
                                                                fontWeight: '600', fontSize: '14px'
                                                            }}> {question.question}
                                                        </th>
                                                        <td className='ps-4 pt-2 pb-2 '
                                                            style={{borderRight: 'none', fontWeight: '400'}}>
                                                            <div className='d-flex justify-content-between'>{(() => {
                                                                if (question.answer.type === "radio-group") {
                                                                    return (
                                                                        <span>YES / NO</span>
                                                                    )
                                                                } else if (question.answer.type === "yes-no") {
                                                                    return (
                                                                        <span>YES / NO</span>
                                                                    )
                                                                } else if (question.answer.type === "text-box") {
                                                                    return (
                                                                        <input className='border-0' placeholder={'Answer'}/>
                                                                    )
                                                                } else if (question.answer.type === "text") {
                                                                    return (
                                                                        <input className='border-0' placeholder={'Answer'}/>
                                                                    )
                                                                } else if (question.answer.type === "checkbox-group") {
                                                                    return (
                                                                        <div className='hstack'>
                                                                            {
                                                                                question.answer.values && question.answer.values.length > 0
                                                                                    ? (
                                                                                        question.answer.values.map((val, index) => {
                                                                                            return (
                                                                                                <div
                                                                                                    className='bg-white p-1 ps-3 pe-3 me-2 rounded '
                                                                                                    key={index} style={{
                                                                                                    color: '#d76d00',
                                                                                                    border: '1px solid #d76d00',
                                                                                                    fontSize: '14px'
                                                                                                }}>{val.label}&nbsp;&nbsp;&nbsp;
                                                                                                    <input
                                                                                                        style={{accentColor: '#d76d00'}}
                                                                                                        defaultChecked={val.selected}
                                                                                                        type={"checkbox"}
                                                                                                        value={val.value}/>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    )
                                                                                    : (<></>)
                                                                            }

                                                                        </div>
                                                                    )
                                                                } else if (question.answer.type === "text-area") {
                                                                    return (
                                                                        <textarea className='border-0'
                                                                                  placeholder={'Answer in Paragraph'}/>
                                                                    )
                                                                } else if (question.answer.type === "images") {
                                                                    return (
                                                                        <div className='bg-white rounded m-1'>
                                                                            <div
                                                                                className='vstack align-items-center justify-content-center d-flex border'
                                                                                style={{
                                                                                    cursor: 'pointer',
                                                                                    width: '80px',
                                                                                    height: '80px',
                                                                                    borderRadius: '12px'
                                                                                }}>

                                                                                <img src={'/assets/icons/imageIcon.svg'}/>
                                                                                <span style={{
                                                                                    fontSize: '12px',
                                                                                    fontWeight: '500'
                                                                                }}>Add
                                                                                    Images
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <div>Unknown Datatype</div>
                                                                    )
                                                                }
                                                            })()}
                                                                <div className='p-1 me-3 justify-content-between'>
                                                    <span data-bs-toggle="dropdown" aria-expanded="false">
                                                                <BsThreeDotsVertical/>
                                                    </span>

                                                                </div>
                                                            </div>
                                                        </td>


                                                    </tr>

                                                )) : <></>}
                                        </tbody>
                                    </table>
                                    <div className='p-1 hstack justify-content-between m-0'>
                                        <span
                                            data-bs-toggle="modal"
                                            data-bs-target="#addNewQuestion"
                                            className='ps-4 pt-2 pb-2'
                                            onClick={() => {

                                            }}
                                            style={{color: '#afafaf', fontWeight: '500', cursor: 'pointer'}}>+ New Question
                                        </span>

                                    </div>
                                </div>
                            )
                        }) : <></>

                }

                <div className='bg-white  fixed-bottom shadow p-2 justify-between d-flex w-100 '
                >

                    <button className='btn btn-outline-primary me-1 fw-semibold'
                    >+ Add Checklist
                    </button>

                    <ul className="nav nav-pills">
                        {checklistTabs}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default ChecklistPreviewModal;