import React, {useState} from "react";
import {Route, Switch} from 'react-router-dom';
//import {Routes} from "react-router";
import ProjectSidebar from "../component/sidebar/ProjectSidebar";
import ProjectRoutes from "../route-lists/ProjectRoutes";
import {useSelector} from "react-redux";
import ProjectHeader from "../component/header/ProjectHeader";
import Draggable from "react-draggable";
import Notebook from "./NoteBook";
import {GrClose} from "react-icons/gr";
import ProtectedRoute from "../route-lists/ProtectedRoute";


let Project = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const sidebarToggle = useSelector((state => state.changeTheSidebar));
    return (
        <>


            <div className="d-flex vh-100">
                <ProjectSidebar/>
                <div style={{
                    width: sidebarToggle ? "calc(100% - 230px)" : "calc(100% - 85px)",
                    transition: 'width 0.2s ease 0s'
                }}>
                    <ProjectHeader/>
                    <div className='w-100'
                         style={{overflow: "auto", height: "calc(100vh - 100px)", backgroundColor: '#f5f5f5'}}>

                        <Switch>
                            {ProjectRoutes.resource.map((item, index) => {
                                return <ProtectedRoute key={index} path={`${item.layout}${item.path}`}
                                              component={item.component}/>
                            })}
                        </Switch>

                        {/*<div className='position-absolute' style={{top: '200px', right: '0', zIndex: '100000'}}>*/}
                        {/*    <button className='btn btn-primary' onClick={() => {*/}
                        {/*        setIsModalOpen(!isModalOpen);*/}
                        {/*        //document.getElementById('dragg').classList.toggle('d-none')*/}
                        {/*    }}>NOTE*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>



            {isModalOpen == true && (


                <Draggable style={{position: "absolute",}} positionOffset={{x: '100%', y: '-50%'}}>

                    <div className='modal-content shadow draggable-tab w-50'
                         style={{zIndex: '1000000', resize: 'both'}}>

                        <div className="modal-body bg-light">
                            <div className='d-flex justify-content-between px-2'
                                 style={{borderBottom: "0.5px solid #f0eff2"}}>
                                <text className='w-100 border-0 fw-semibold outline-none p-2' type='text'>Notebook
                                </text>

                                <div className='outline none p-2' id='addFileClose' data-bs-dismiss="modal"
                                     type='button'
                                     onClick={() => {
                                         setIsModalOpen(!isModalOpen);
                                     }}>

                                 {/*   <img style={{width: "14px"}} src='/assets/icons/close.png'/>*/}<GrClose/>
                                </div>
                            </div>

                            <Notebook/>

                        </div>
                    </div>

                </Draggable>

            )}


        </>
    );
}

export default Project;