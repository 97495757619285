import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {getCurrentCompany} from "../../../utills/Common";
import {getNotesUserAnnotations} from "../../../actions/Notes/Notes";
import {useSelector} from "react-redux";


const AnnotationView = forwardRef((props, ref) => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const currentUser = useSelector(state => state.currentUser);
    const [currentCompany, setCurrentCompany] = useState({});
    const [highlights, setHighlights] = useState(null);
    const documentId = props.documentId;
    //const alert = useAlert();
    const onClickHighlight = props.onClickHighlight;
    const [reload, toggleReload] = useState(false);

    useImperativeHandle(ref, () => ({
        reload() {
            toggleReload(!reload);
        }
    }));

    const loadData = async () => {
        let projectDetails = await getCurrentCompany();
        setCurrentCompany(projectDetails);
        await loadHighlights(projectDetails.id);
    };

    const loadHighlights = async (projectId) => {
        let response = await getNotesUserAnnotations(documentId, token);

        if (response.data.data && response.data.data.length > 0) {
            let highlights = response.data.data.map((report, index) => {
                report.key = index + 1;
                report.action = null;
                return report;
            });
            setHighlights(highlights);
        } else {
            setHighlights([]);
        }
    }

    useEffect(() => {
        async function loadingData() {
            await loadData();
        }

        loadingData();
    }, [documentId, reload]);


    return (
        <>
            <div className='p-2' style={{backgroundColor: '#f5f5f5', fontSize: '20px'}}>
                Highlights
            </div>

            <div className='vstack mt-2'>
                {
                    highlights && highlights.length > 0 ? <>
                        {
                            highlights.map((item) => (

                                <div className=' p-2 m-2 rounded shadow'
                                     style={{borderTop: `3px solid #01b3ff`}}
                                     onClick={() => {
                                         onClickHighlight(item.description, item.type);
                                     }}>

                                    <div>
                                        <text className='mx-2' style={{fontSize: '12px'}}>{item.description}</text>
                                    </div>
                                </div>
                            ))
                        }

                    </> : ''
                }
            </div>
        </>
    )
});

export default AnnotationView;