import React from "react";
import {log} from "three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements";


const ChecklistCheckbox = React.forwardRef((props, ref) => {
    let question = props.question;
    const questionIdx = props.questionIdx;
    const sectionIdx = props.sectionIdx;
    const onChangeContent = props.onChangeContent;
    return (
        <div className='hstack gap-2 w-100 d-flex p-1' style={{display: "flex", flexWrap: "wrap"}}>
            {
                question.answer.values && question.answer.values.length > 0
                    ? (
                        question.answer.values.map((val, index) => {
                            return (
                                <div key={index} className='bg-white p-1 ps-3 pe-3 rounded d-flex '
                                     style={{
                                    color:  question.answer.values[index].selected==true?' #d76d00':'black',
                                    border: question.answer.values[index].selected==true?'1px solid #d76d00':'1px solid #dedede',
                                    fontSize: '14px'
                                }}>{val.label}&nbsp;&nbsp;&nbsp;
                                    <input
                                        style={{accentColor: '#d76d00'}}
                                        onChange={(e) => {
                                            question.answer.values[index] = {
                                                label: question.answer.values[index].label,
                                                value: question.answer.values[index].value,
                                                selected: !question.answer.values[index].selected,

                                            };
                                            onChangeContent(questionIdx, sectionIdx, question);
                                            console.log(question.answer.values[index].selected,"selected val");
                                        }}
                                        defaultChecked={val.selected}
                                        type={"checkbox"}
                                        value={val.value}
                                    />
                                </div>
                            )
                        })
                    )
                    : (<></>)
            }

        </div>
    )
});

export default ChecklistCheckbox;