
// Check Lists


import ChecklistView from "../views/checklist/ChecklistView";

const routes = [
    {
        name: "Check-List",
        path: "/view/:id",
        icon: '',
        authIcon: '',
        component: ChecklistView ,
        layout: "/check-list",
        collapse: false,
    },

];

export default routes;