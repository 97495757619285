import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getAnnualReports} from "../../actions/Reports";
import {getCurrentCompany} from "../../utills/Common";
import {uploadNotesDocumentLink} from "../../actions/Notes/Notes";
import {useHistory} from "react-router-dom";
import {toast} from "react-hot-toast";
import {getLocalToken, getLocalUser} from "../../utils/storage";

let AnnualReportModalView = (props) => {

    const token = localStorage.getItem('token');
    const user = localStorage.getItem('user');
    const tabClose = props.tabClose;
    const [annualReports, setAnnualReports] = useState([]);
    const [currentCompany, setCurrentCompany] = useState({});
    const currentUser = useSelector(state => state.currentUser);
    const [uploadingState, toggleUploadingState] = useState(false);
    let history = useHistory();
    let alert = toast;

    let currentUrl = window.location.href;


    const getAllAnnualReports = async (company_id) => {
        let response = await getAnnualReports(company_id);

        if (response.data.Table && response.data.Table.length > 0) {
            //console.log(response.data.Table[0]);
            setAnnualReports(response.data.Table);
        }
    };

    const getCurrentCompanyDetails = async () => {
        let companyDetails = await getCurrentCompany();
        setCurrentCompany(companyDetails);
        //console.log(companyDetails);
        await getAllAnnualReports(companyDetails.company.company_id);
    };

    const uploadDocument = async (link, name) => {
        if (link && name) {
            toggleUploadingState(true);
            let formData = new FormData();
            formData.append("link", link);
            formData.append("name", name);
            let response = await uploadNotesDocumentLink(currentCompany.id, formData, token);
            if (response.data.success) {
                toggleUploadingState(false);

                history.push("/notes/view/" + response.data.data.id);
                tabClose();
                alert.success(response.data.message);
            } else {
                toggleUploadingState(false);
                alert.error(response.data.message);
            }
        }

    }

    useEffect(() => {
        getCurrentCompanyDetails();

    }, []);
    return (
        <>
            <div className='row m-4 mt-0  gx-3 pb-4' style={{overflow: 'scroll', height: '70vh'}}>

                {(annualReports && annualReports.length > 0) ?
                    (annualReports.map((row, index) =>
                        <div className='col-6 p-2 ' key={index}
                             onClick={() => {
                                 uploadDocument(row.PDFDownload, `Annual Report (${row.Year})`)
                             }}>
                            <div className='shadow-sm rounded-2 p-0 d-flex'
                                 style={{border: "1px solid #eeeeee"}}>

                                <img src="./assets/images/ageisCompany.png" className="rounded-circle m-1"
                                     style={{width: "2.5rem"}}
                                     alt="Avatar"/>
                                <div className='d-flex justify-content-between w-100'>
                                    <div className='d-flex flex-column'
                                         style={{whiteSpace: 'nowrap', overflow: 'hidden', alignSelf: 'center'}}>
                                                <span className='fs-7 fw-semibold text-capitalize' style={{
                                                    textOverflow: 'ellipsis',
                                                    overflow: 'hidden',
                                                }}>Annual Report ({row.Year})</span>
                                        <span
                                            className='text-capitalize' style={{fontSize: '10px'}}>pdf- Uploaded 1 month ago</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    )) : <>no data found</>}
            </div>

        </>
    )
}

export default AnnualReportModalView;