import {deleteRequest, getFileRequest, getRequest, postRequest} from '../../services/httpService';

/**
 * @description Get My All Resource Files By Project Id
 * @param token
 * @param projectId
 * @param searchedValue
 */
export const getAllImageFiles = async(token, projectId, searchedValue = '') => {
    try {
        return await getRequest(`images/${projectId}?name=${searchedValue}`, token);
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param projectId
 * @param body
 * @param token
 * @returns {Promise<*|*>}
 */
export const uploadImageFiles = async(projectId, body, token) => {
    try {
        return await postRequest(`images/${projectId}`, body, token);
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param fileId
 * @param token
 * @returns {Promise<*|*>}
 */
export const downloadImageFileById = async(fileId, token) => {
    try {
        return await getFileRequest(`images/download/${fileId}`, token);
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param filename
 * @param token
 * @returns {Promise<*|*>}
 */
export const downloadImageFile = async (filename, token) => {
    try {
        return await getFileRequest(`images/download/file/${filename}`, token);
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param filename
 * @param token
 * @returns {Promise<*|*>}
 */
export const deleteImageFile = async (filename, token) => {
    try {
        return await deleteRequest(`images/file/${filename}`, token);
    } catch (error) {
        return error;
    }
};


