import {getRequest, postRequest, deleteRequest} from '../../services/httpService';

/**
 * @description Get My All Projects
 * @param projectId Project ID
 * @param token
 */
export const getAllImages = async(projectId, token) => {
    try {
        return await getRequest(`images/${projectId}`, token)
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param projectId
 * @param token
 * @returns {Promise<*>}
 */
export const getImage = async(projectId, token) => {
    try {
        return await getRequest(`images/${projectId}`, token)
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param projectId
 * @param token
 * @returns {Promise<*>}
 */
export const storeImage = async(projectId, token) => {
    try {
        return await getRequest(`images/${projectId}`, token)
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param imageId
 * @param token
 * @returns {Promise<*>}
 */
export const downloadImage = async(imageId, token) => {
    try {
        return await getRequest(`images/download/${imageId}`, token)
    } catch (error) {
        return error;
    }
};

/**
 *
 * @param imageId
 * @param token
 * @returns {Promise<*>}
 */
export const deleteImage = async(imageId, token) => {
    try {
        return await deleteRequest(`images/${imageId}`, token)
    } catch (error) {
        return error;
    }
};
